import React from 'react';
import { ReactComponent as MailIcon } from '../../../images/noto-v1_envelope.svg';

import './OTPButton.scss';

interface GoogleButtonProps {
  onClick: () => void,
  disabled?: boolean,
}

function OTPButton({ onClick, disabled = false }: GoogleButtonProps) {
  return (
    <button
      className="OTPButton"
      onClick={onClick}
      disabled={disabled}>
        <MailIcon className="OTPButton__icon" />
      
      <span className="OTPButton__text">
        {`One Time Password`}
      </span>
    </button>
  );
}

export default OTPButton;