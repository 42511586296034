import React from 'react';  
import './CourseLoader.scss';

interface CourseLoaderProps {
  customText?: string;
  subtext?: string;
}

const CourseLoader: React.FC<CourseLoaderProps> = ({ customText, subtext }) => {
  return (
    <div className="Loader-course">
      <h2 className="Loader-course__title">
        {customText || 'Loading'}
      </h2>
      <p className="Loader-course__text">
        {subtext || 'Please wait'}
      </p>
      <div className="course-loader"> 
        <div className="loader circle-loader-1"></div>
      </div>
     
    </div>
  )
}

export default CourseLoader;
