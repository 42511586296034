import React, { useState } from 'react';
import SaveButton from '../Buttons/SaveButton/SaveButton';
import SectionBlock from '../SectionBlock/SectionBlock';
import SummaryBlock from '../SummaryBlock/SummaryBlock';
import { RootState } from '../../redux/store'; 
import EducationCreateSectionInput from '../Inputs/EducationCreateSectionInput/EducationCreateSectionInput';
import SectionsButton from '../Buttons/SectionsButton/SectionsButton';
import HeaderImageBlock from '../HeaderImageBlock/HeaderImageBlock';
import SectionRename from '../Modals/SectionRename/SectionRename';
import CloseModal from '../Modals/CloseModal/CloseModal';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { setEducationalContent, removeEducationVideo } from '../../redux/slices/coursesSlice'; 
import { getCookie } from '../../utils/CookieHelper';
import './SectionContainer.scss';

const hardcodedInitialState = {
  sections: [
    {
      title: "Accepting an Invitation to Join the Superagency.ai Airtable Base",
      description: " to maintain vigi s to new users.",
      sub_sections: [
        {
          title: "Creating an Airtable Account",
          description: "Remember to verify your email address if prompted, to t and begin your collaborative journey.",
          image: "https://storage.googleapis.com/landdai-bucket/education-1707407081337.png"
        },
        {
          title: "Navigating and Editing Prompts",
          description: "ies to maximize your efficiency in editing and managing prompts.",
          image: "https://storage.googleapis.com/landdai-bucket/education-1707407089918.png"
        },
        {
          title: "Sharing and Managing Access",
          // description: "To expand your team of collaborators, use the 'Share' button located within the Airtable base. \nYou can send email invitations to specific individuals or create a link that can be shared more broadly. \nWhen sharing access, you have the option to set different levels of permissions, ensuring that collaborators have the appropriate level of access to the base. \nIt's important to consider the security of the information within the base when sharing, and to only grant access to trusted individuals. \nRegularly review who has access to the base and adjust permissions as necessary to maintain the integrity of your data.",
          description: "12341234123412341234",
          image: "https://storage.googleapis.com/landdai-bucket/education-1707407097168.png"
        }
      ]
    },
  ],
 };

 interface Section {
  title: string;
  description: string;
  sub_sections: SubSection[];
}

interface SubSection {
  title: string;
  description: string;
  image: string;
}

interface SectionContainerProps {
  saveSectionModalOpen: boolean;
  setSaveSectionModalOpen: (open: boolean) => void;
  changeMode: (mode: "edit" | "view") => void;

}

// Use the interface to type the component's props
const SectionContainer: React.FC<SectionContainerProps> = ({ changeMode, saveSectionModalOpen, setSaveSectionModalOpen }) => {
  const dispatch = useDispatch<AppDispatch>();
  // Use hardcodedInitialState.sections as the initial state
  const [editTitles, setEditTitles] = useState<{ [key: number]: boolean }>({});
  const activeSections = useSelector((state: RootState) => state?.courses?.activeCourse?.educationalContent?.sections);
  const [sections, setSections] = useState<Section[]>(activeSections || hardcodedInitialState.sections);
  const { educationVideo } = useSelector((state: RootState) => state?.courses?.activeCourse) || {};
  const { educationalContent } = useSelector((state: RootState) => state?.courses?.activeCourse) || {};
  const [mainImage, setMainImage] = useState<string | null>(educationalContent?.headerImage || null);
  const [ deleteVideoModal, setDeleteVideoModal ] = useState<boolean>(false);
  const [ deleteSectionModal, setDeleteSectionModal ] = useState<number | boolean>(false);
  const [ summary, setSummary ] = useState<string>(educationalContent?.summary || '');
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [ newSectionName, setNewSectionName ] = useState<string>(''); 

  const handleSubSectionTitleEditToggle = (index: number) => {
    setEditTitles(prevEditTitles => ({
      ...prevEditTitles,
      [index]: !prevEditTitles[index],
    }));
  };

  // Function to handle saving of sub-section titles after editing
  const handleSaveSubSectionTitle = (newTitle: string, index: number) => {
    handleSubSectionChange('title', newTitle, index);
  };

  const handleSubSectionChange = (field: keyof SubSection, value: any, index: number) => {
    // Make sure to specify that you're updating a subSection's field
    updateSection(index, field, value);
  };

  // This function has been modified to accept only the fields present in the Section interface.
  const updateSection = (index: number, field: keyof Section | keyof SubSection, value: any, subSectionIndex?: number) => {
    setSections((prevSections) => {
      // If there's a subSectionIndex, we're updating a sub-section
      if (typeof subSectionIndex === 'number') {
        const newSections = [...prevSections];
        // Clone the sub-sections array
        const newSubSections = [...newSections[index].sub_sections];
        // Update the specific sub-section
        const updatedSubSection: SubSection = {
          ...newSubSections[subSectionIndex],
          [field]: value
        };
        newSubSections[subSectionIndex] = updatedSubSection;
        // Update the main section with the new sub-sections array
        newSections[index] = {
          ...newSections[index],
          sub_sections: newSubSections
        };
        return newSections;
      } else {
        // Otherwise, we're updating the main section
        const newSections = [...prevSections];
        const updatedSection: Section = {
          ...newSections[index],
          [field]: value
        };
        newSections[index] = updatedSection;
        return newSections;
      }
    });
  };

  const updateSummary = (value: string) => { 
    setSummary(value);
  }

  const onSave = async () => {
    setSaveSectionModalOpen(false);
    dispatch(setEducationalContent({
      summary: summary,
      agenda: sections?.map(el => el.title),
      sections: sections,
      videoURL: educationVideo || '',
      id: educationalContent?.id,
      headerImage: mainImage,
    }));
    changeMode('view');
  }

  const onCancelSave = async () => {
    setSaveSectionModalOpen(false);
    changeMode('view');
  }

  const onDeleteVideo = async () => {
    setDeleteVideoModal(false);
    const token = getCookie('jwtToken');
    if (token && educationalContent?.id) {
        dispatch(
          removeEducationVideo({ token, educationalContentId: educationalContent?.id})
        );
    }
  }
  
  const handleDeleteSection = (index: number | string) => { 
    if(typeof index === 'number') { 
      setSections((prevSections) => {
        return prevSections.filter((_, i) => i !== index);
      });
      setDeleteSectionModal(false);
    }  
  }

  const checkSubsectionForPlaceholders = () => {
    let hasPlaceholders = false;
    sections.forEach(section => {
      if (section.description === '') {
        console.log('section', section);
        hasPlaceholders = true;
      }
      section.sub_sections.forEach(subSection => {
        if (subSection.title === 'Add a name of subsection' || subSection.description === '') {
          hasPlaceholders = true;
        }
      });
    });
    return hasPlaceholders ? 'Please ensure all fields in each section are filled out before saving.' : '';
  }



  const handleUpdateHeaderImage = (image: string | null) => { 
    setMainImage(image);
  }

  const handleAddSection = (title: string) => {
    setSections((prevSections) => {
      return [
        ...prevSections,
        {
          title: title,
          description: '',
          sub_sections: []
        }
      ];
    });
  }


  

  return (
    <div className="section-container">
       {
          saveSectionModalOpen && (

            checkSubsectionForPlaceholders() ? 
            
              <CloseModal
              type={'not-complete'} 
              submitText='OK'
              action={() => setSaveSectionModalOpen(false)}
              text={checkSubsectionForPlaceholders()}
              closeNoSave={()=> setSaveSectionModalOpen(false)}
              close={()=> setSaveSectionModalOpen(false)}
              singleButton={true}
              />
              :
              <CloseModal
              type={'save'}
              action={onSave}
              text={'Would you like to save your progress?' }
              closeNoSave={onCancelSave}
              close={()=> setSaveSectionModalOpen(false)}
              />
          )
        } 
         {
          typeof deleteSectionModal === 'number' && (
            <CloseModal
              type={'remove-section'}
              submitText='Yes'
              action={() => handleDeleteSection(deleteSectionModal as number)}
              text={'Do you want to permanently delete the section?'}
              closeNoSave={()=> setDeleteSectionModal(false)}
              close={()=> setDeleteSectionModal(false)}
              />
          )
        }
       {
          deleteVideoModal && (
            <CloseModal
              type={'delete'} // Ensure this is correct for your modal type 
              action={onDeleteVideo}
              text={'This action cannot be undone. Are you sure you want to proceed?'}
              submitText={'Delete'}
              closeNoSave={() => setDeleteVideoModal(false)} // Change to a function
              close={() => setDeleteVideoModal(false)} // Correctly passed as a function
              customButtonStyles={{backgroundColor: '#D61919'}}
            />
          )
        }
       <div className="section-container__btns">
          <SaveButton
              text="Save"
              action={() => {
                setSaveSectionModalOpen(true);
              }}
              />
        </div>
      
      {/* TODO dont remove */}
         {/* { (educationVideo) && (
             <div  className="section-container__video-block">
                <video 
                  className="section-container__video-block__video-player" 
                  controls 
                  src={educationVideo}
                >
                  Your browser does not support the video.
                </video>
             <div className="section-container__video-block__block">
              <CustomColorButton
                text="Delete"
                onClick={() => {
                  setDeleteVideoModal(true);
                }}
                />
               
              </div>

           </div>
              )
              
          } */}

          <div className="section-container__top">
            <div className="section-container__top__header">
              Introduction
            </div>
            <div className="section-container__top__titles">
              {
                sections.map((section, index) => (
                  <div key={index + section.title}>
                   <div className="section-container__top__titles-block" >
                      <div className="section-container__top__titles-block__title">
                        Section {index+1}:
                      </div>
                      <SectionRename
                        key={index}
                        initialValue={section.title} 
                        editing={editTitles[index]} 
                        setEditing={() => handleSubSectionTitleEditToggle(index)} 
                        onSave={(newTitle) => handleSaveSubSectionTitle(newTitle, index)}
                        onDelete={() => { 
                          setDeleteSectionModal(index);
                        }}
                        extraClass='italic'
                      />

                    
                  </div>
                  {
                    (index === sections.length - 1) && !createMode && (
                      <div className="section-container__top__titles-block__create-block">
                        <SectionsButton 
                        icon 
                        text='Add section'
                        styles={{width: '126px', height: '24px'}}
                        action={() => setCreateMode(true)}
                        />
                      </div>
                    )
                  }
                  {
                    index === sections.length - 1 && createMode && (
                      <div className="section-container__top__titles-block__create-block">
                        <EducationCreateSectionInput
                          placeholder={`Section ${sections.length + 1}`}
                          text={newSectionName}
                          setText={setNewSectionName}
                          customStyles={{width: '100%'}}
                          />
                        <SaveButton
                          text="Create"
                          styles={{width: '103px', height: '48px'}}
                          action={() => {
                            handleAddSection(newSectionName);
                            setCreateMode(false);
                            setNewSectionName('');
                          }}
                          />
                      </div>
                    )
                  }
                  
                 
                  </div>
                   
                  ))
              }
            </div>
          
          </div>

          <HeaderImageBlock
            image={mainImage || null}
            updateHeaderImage={handleUpdateHeaderImage}
          />
         
          {sections.map((section, index) => (
            <SectionBlock
              key={index} // Now using id as it is part of the Section interface
              index={index}
              section={section}
              updateSection={(index, field, value, subSectionIndex) => updateSection(index, field, value, subSectionIndex)}
            />
          ))}
           <div className="section-container__summary">
              <div className="section-container__summary__header">
                Summary
              </div>
              <SummaryBlock
                summary={summary}
                updateSummary={updateSummary} 
              />
            </div>
            <div className="ModulesEducation__container__right__body__bottom-btns">
                    <SaveButton
                        text="Save"
                        action={() => {
                          setSaveSectionModalOpen(true);
                      }}
                        />
            </div>
       </div>
  );
};

export default SectionContainer;