import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import backIcon from '../../../../images/arrow-left.svg';
import trash from '../../../../images/trash.svg';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import edit from '../../../../images/edit.svg';
import rightArrow from '../../../../images/chevron-right.svg';
import leftArrowDisable from '../../../../images/chevron-left.svg';
import Loader from '../../../Loader/Loader';
import './DocArchive.scss';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {  changeSidebarStatus, endLoading, setObjAzure, setObjGoogle, setObjPDF } from '../../../../redux/slices/slidesSlice';
import { getCookie, deleteCookie } from '../../../../utils/CookieHelper'
import ExportModal from '../../../Modals/ExportModal/ExportModal';
import { deleteDoc, getAllDocs, saveDoc, setExportError, updateScriptList } from '../../../../redux/slices/scriptsSlice';
import { sendDoc } from '../../../../utils/sendDocs';
import DropdownExport from '../../../DropdownExport/DropdownExport';
import  GoogleLoginModal from '../../../Modals/GoogleLoginModal/GoogleLoginModal';

export default function DocArchive() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [hovered, setHovered] = useState('');
  const [openInput, setOpenInput] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [exportModal, setExportModal] = useState("");
  const [loginModal, setLoginModal] = useState(false);
  const [page, setPage] = useState(0);
  const token = getCookie('jwtToken');
  const rediePath = getCookie('path');
  const azureToken = getCookie('azureRefreshToken');
  const googleToken = getCookie('googleToken');

  const {
    scriptsList,
    loading,
    scriptsLength
  } = useSelector((state: RootState) => state.scripts);
  const { objAzure, loading: loader, objGoogle, objPDF } = useSelector((state: RootState) => state.slides);

  useEffect(() => {   
    const func = async() => {
      if(!googleToken) {
        setObjGoogle({});
        setObjAzure({});
        setObjPDF({});
        return;
      }
      if(!azureToken) { 
        window.location.href = `${process.env.REACT_APP_API_URL}api/auth/azure`;
        return;
      }
      if(objAzure?.presName) {
        const res = await sendDoc(objAzure?.presName, 'sendAzure', objAzure?.presStyle);
        dispatch(setObjAzure({}));
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          dispatch(setObjAzure({}));
          setExportModal('send');
        }
        document.cookie = "path=";
      }
      if(objGoogle?.presName) {
        const res = await  sendDoc(objGoogle.presName , 'send', objGoogle.presStyle );
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          dispatch(setObjGoogle({}));
          setExportModal('send');
        }
        document.cookie = "path=";
      }
      if(objPDF?.presName) {
        const res = await sendDoc(objPDF.presName, 'Pdf', objPDF.presStyle); 
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          dispatch(setObjPDF({}));
          setExportModal('send');
        }
        document.cookie = "path=";
      }

    }
    if (rediePath) {
      func();
    } 
    dispatch(changeSidebarStatus(false));
  }, []);

  useEffect(() => {
    console.log(page);
    token && dispatch(getAllDocs({
      body: {
        take: '10',
        skip: `${page * 10}`
      },
      token
    }));
  }, [token, page]);

  useEffect(() => {
    token && dispatch(getAllDocs({
      body: {
        take: '10',
        skip: '0'
      },
      token
    }));
  }, [scriptsList?.length === 0]);

  const closeGoogleLogin = () => {
    dispatch(setObjAzure({}));
    dispatch(setObjGoogle({}));
    dispatch(setObjPDF({}));
    dispatch(endLoading());
    deleteCookie('path');
    setLoginModal(false);
  };

  const remove = (id: number) => {
    if (token) {
      const obj = {
        body: {
          docId: id
        },
        token
      }
      dispatch(deleteDoc(obj));
    }
  };

  const updateNamePres = (id: number, text: string) => {
    setOpenInput('');
    const obj = {
      title: inputValue || text,
      docId: id
    }

    dispatch(saveDoc({
      body: obj,
      token
    }));

    dispatch(updateScriptList({
      id,
      title: inputValue || text
    }));
  };

  return (
    <div className="DocArchive">
      {
        loginModal && 
          <GoogleLoginModal 
            // path={`path=${process.env.REACT_APP_API_URL}scriptsArchive`} 
            close={closeGoogleLogin} />
      }
      <div
        className="DocArchive__header"
      >
        <img
          className="DocArchive__back-btn"
          src={backIcon}
          alt="back"
          onClick={() => navigate('/scripts')}
        />
        <p className="DocArchive__back-text">My Saved Scripts </p>
      </div>
      {(loading || loader) ? <Loader /> : (
        <div className="DocArchive__table">
          <div className="DocArchive__table-header">
            <p className="DocArchive__pres-name">
              Name of Script
            </p>
            <p className="DocArchive__temp-name">
              Template Name
            </p>
            <p className="DocArchive__data">
              Date Saved
            </p>
          </div>
          {scriptsList?.length > 0 ? (
            <div className="DocArchive__table-main">
              {scriptsList?.map((el: any) => (
                <div
                  key={el?.id}
                  className="DocArchive__table-row"
                  onMouseEnter={() => setHovered(el?.id)}
                >
                  <div className="DocArchive__pres-name">
                    {hovered === el?.id && (
                      <div
                        className="DocArchive__hover-edit"
                        onClick={() => setOpenInput(el?.id)}
                      >
                        <img src={edit} alt="edit" />
                      </div>
                    )}
                    {openInput === el?.id ? (
                      <input
                        className="DocArchive__edit-input"
                        type="text"
                        value={inputValue}
                        autoFocus={true}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)}
                        onBlur={() => updateNamePres(el?.id, el?.title)}
                      />
                    ) : el?.title}
                  </div>
                  <p className="DocArchive__temp-name">
                    {el?.template_name}
                  </p>
                  <p className="DocArchive__data">
                    {el?.created_at ? new Date(el?.created_at).toISOString().split('T')[0] : ''}
                  </p>
                  {hovered === el?.id && (
                    <div className="DocArchive__hover-btns">
                      <div
                        className="SlideArchive__hover-btn"
                        onClick={() => navigate(`/script?edit=${el?.id}`)}
                      >
                        <ModeEditIcon />
                        <span className="tooltiptext">Edit</span>
                      </div>
                      <div
                        className="DocArchive__hover-btn"
                        onClick={() => remove(el?.id)}
                      >
                        <img src={trash} alt="delete" />
                        <span className="tooltiptext">Delete</span>
                      </div>
                      <DropdownExport 
                        presName={el?.title}
                        presStyle={el}
                        setExportModal={setExportModal}     
                        setLoginModal={setLoginModal}                    
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="SlideArchive__empty">
              <p className="SlideArchive__empty-text">You have no scripts</p>
            </div>
          )}
          
          {scriptsLength > 10 && (
            <div className="SlideArchive__pagination">
              <div className="SlideArchive__pagination-text">
                {page * 10}-{page * 10 + scriptsList?.length} of {scriptsLength}
              </div>
              <div className="SlideArchive__pagination-btns">
                <div
                  onClick={() => page > 0 && setPage(page - 1)}
                >
                  <img
                    src={page > 0 ? rightArrow : leftArrowDisable}
                    style={{
                      transform: page > 0 ? 'rotate(180deg)' : 'rotate(0)'
                    }}
                    alt="prev"
                  />
                </div>
                <div
                  onClick={() => (((page + 1) * 10) < (Math.ceil(scriptsLength / 10) * 10)) && setPage(page + 1)}
                >
                  <img
                    src={(((page + 1) * 10) < (Math.ceil(scriptsLength / 10) * 10)) ? rightArrow : leftArrowDisable}
                    style={{
                      transform: (((page + 1) * 10) < (Math.ceil(scriptsLength / 10) * 10)) ?  'rotate(0)' : 'rotate(180deg)'
                    }}
                    alt="next"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {exportModal?.length > 0 && <ExportModal text={exportModal} close={() => setExportModal("")} />}
    </div>
  )
}
