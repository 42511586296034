import ChatBotChatIcon from '../../../../../images/chatBotChatIcon.svg';
import trash from '../../../../../images/trash.svg';
import './ChatListItem.scss';
import { RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { removeChat, getHistory, setChatTitle, setChatId } from '../../../../../redux/slices/trainerBotSlice';
import { changeSidebarStatus } from '../../../../../redux/slices/slidesSlice';
import { getCookie } from '../../../../../utils/CookieHelper'; 

interface Props {
    id: number;
    text: string;
}
interface DataProps {
    chat_id: number;
    token: string | undefined;
}
export default function ChatListItem({id, text}: Props) {
    const token = getCookie('jwtToken');
    const dispatch = useDispatch<any>();
    const {email} = useSelector((state: RootState) => state.scripts.user);
    const {loading, chat_id} = useSelector((state: RootState) => state.trainerBot);
    const data: DataProps = {
        chat_id: id ,
        token: token,
    } 

    const openChat = () => {
      dispatch(changeSidebarStatus(false));
      dispatch(setChatTitle(text))
      dispatch(getHistory({chat_id: id , token: token}))
      dispatch(setChatId(id))
    }
    const deleteChat = async  () => {
        dispatch(removeChat({
          chat_id: id,
          token: token,
          email: email
      }));
    }



  return (
    
    <div className={`ChatListItem ${chat_id === id  ? 'ChatListItem__active' : '' }`}  key={id}  >
            <div className="ChatListItem__info" onClick={openChat}>
                <div className="ChatListItem__icon">
                    <img src={ChatBotChatIcon} alt="chatBotChatIcon" />
                </div>
                <div className="ChatListItem__title">{text}</div>
            </div>
            
          
            <div className="ChatListItem__remove" onClick={deleteChat}>
                <img src={trash} alt="trashIcon" />
            </div>
        
    </div>
    
  )
}
