import { useState } from 'react'
import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import CloseIcon from '@mui/icons-material/Close'; 
import SectionsLoader from '../../Loader/SectionsLoader/SectionsLoader';
import './SectionGenerateModal.scss';

type Props = {
  loading: boolean,
  action: (val: string) => void,
  header: string,
  subHeader: string,
  text: string, 
  close: () => void
};

export default function SectionGenerateModal({
  loading,
  action,
  header,
  subHeader,
  text, 
  close
}: Props) {
    const [value, setValue] = useState('');

    
  return (
    <div className="SectionGenerateModal">
      <div className="SectionGenerateModal__wrapper">
        <div className="SectionGenerateModal__close">
            <CloseIcon
            onClick={close}
            sx={{
                visibility: loading ? 'hidden' : 'visible',
                color: '#5A6861',
                cursor: 'pointer',
                height: '16px',
                width: '16px'
            }}
            />
        </div>
       <div className="SectionGenerateModal__header">
            {header}
       </div>
        <div className="SectionGenerateModal__sub-header">
            {subHeader}
        </div>
        <div className="SectionGenerateModal__text-block">
            <div className="SectionGenerateModal__text-block__text">
                {text}
            </div>
            <textarea
                value={value}
                className='SectionGenerateModal__text-block__input'
                onChange={e => setValue(e.target.value)} 
                />
        </div>

        <div className="SectionGenerateModal__btns">
            { loading && 
            <SectionsLoader  />
            }
          <ConfirmButton
            disable={loading || !value.trim()}
            text={"Generate"}
            action={() => action(value)}
          />
        </div>
      </div>
    </div>
  )
}
