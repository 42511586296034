import { ChangeEvent, useEffect, useState } from 'react';
import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { regText, startLoading, updText } from '../../../redux/slices/scriptsSlice';
import { useSocket } from '../../../App';
import { createSlide, regenerateSlide, updateImage, updateText } from '../../../redux/slices/slidesSlice';
import { getCookie } from '../../../utils/CookieHelper';
import './AiTextareaModal.scss';

type Props = {
  setModal: (arg: string) => any,
  path: string,
  selectSlide?: any,
  genImage?: boolean
}

export default function AiTextareaModal({ setModal, path, selectSlide, genImage }: Props) {
  const dispatch = useDispatch<any>();
  const token = getCookie('jwtToken');
  const { presentationId } = useSelector((state: RootState) => state.slides);
  const { script } = useSelector((state: RootState) => state.scripts);
  const [finalText, setFinalText] = useState('');
  const [text, setText] = useState('');
  const newSocket = useSocket();

  useEffect(() => {
    switch (path) {
      case "updateSlide":
        setText("Update Slide With New Ai Generated Content");
        break;
      case "img":
        setText("Update Image With New Ai Generated Content");
        break;
      case "text":
        setText("Update Text With New Ai Generated Content");
        break;
      case "createSlide":
        setText("Create Slide With New Ai Generated Content");
        break;
      case "moreText":
        setText("Update Script With More AI Generated Content");
        break;
      case "newText":
        setText("Update Script With New AI Generated Content");
        break;
    }
  }, [path]);

  const handleAction = () => {
    const data = {
      token,
      body: {
        prompt: finalText,
        slide_id: selectSlide?.id?.toString()
      }
    };

    const textData = {
      token,
      body: {
        prompt: finalText,
        doc_id: script?.id
      }
    };

    switch (path) {
      case "updateSlide":
        dispatch(regenerateSlide({...data, genImage}))
        break;
      case "img":
        dispatch(updateImage(data))
        break;
      case "text":
        dispatch(updateText(data))
        break;
      case "createSlide":
        presentationId && dispatch(createSlide({
          token,
          body: {
            prompt: finalText,
            text: finalText,
            presentation_id: presentationId?.toString()
          }
        }))
        break;
      case "moreText":
        generateMore(textData);
        break;
      case "newText":
        dispatch(regText(textData))
        break;
    }

    setModal("")
  };

  const generateMore = (textData: any) => {
    dispatch(startLoading());
    newSocket?.emit('generate-more-content', textData?.body);

    newSocket?.on('generate-more-content:end', (data: any) => {
      dispatch(updText(data.result));
    });
  }

  return (
    <div className="AiTextareaModal">
      <div className="AiTextareaModal__container">
        <div
          className="AiTextareaModal__close"
          onClick={() => {
            setModal("");
          }}
        >
          <CloseIcon
            sx={{
              color: '#5A6861'
            }}
          />
        </div>
        <h2>{text}</h2>
        <textarea
          className="AiTextareaModal__textarea"
          placeholder={path === "createSlide" ? "Please, type here what the new slide is about"
            : (path === "moreText" ? `Feel free to use these prompts and add more details to enhance your content conveniently:\n\n   • To complete the sentence, type "Finish the sentence."\n   • To include additional thoughts, type "Add up to 3 sentences."\n   • For additional structured content, type "Add up to 5 bullet points related to the paragraph."\n\nYou can also use your own prompts, keep them clear and sharp for the best results.`
              : "Type a new prompt here to update the content ONLY on this specific slide.")}
          value={finalText}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setFinalText(e.currentTarget.value)}
        />
        <div className="AiTextareaModal__btn-wrapper">
          <ConfirmButton action={handleAction} text={"Generate"} />
        </div>
      </div>
    </div>
  )
}