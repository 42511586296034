import  {  useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'; 
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AccountSection from '../../components/Sections/AccountSection/AccountSection';
import DocArchive from '../../components/Sections/DocsSection/DocArchive/DocArchive';
import DocSection from '../../components/Sections/DocsSection/DocSection/DocSection';
import DocsSection from '../../components/Sections/DocsSection/DocsSection';
import ModulesSection from '../../components/Sections/ModulesSection/ModulesSection';
import EmptySection from '../../components/Sections/EmptySection/EmptySection';
import SlideArchive from '../../components/Sections/SlidesSection/SlideArcive/SlideArchive';
import SlideSection from '../../components/Sections/SlidesSection/SlideSection/SlideSection';
import SlidesSection from '../../components/Sections/SlidesSection/SlidesSection';
import ChatBotSection from '../../components/Sections/TrainerBotSection/ChatBotSection';
import SideBar from '../../components/SideBar/SideBar';
import { AppDispatch, RootState } from '../../redux/store';
import { setExportError } from '../../redux/slices/scriptsSlice';
import { useLocation } from 'react-router';
import { isMobile } from 'react-device-detect'
import './Home.scss';
 
export default function Home() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { exportError } = useSelector((state: RootState) => state.scripts);
  const { currentPage } = useSelector((state: RootState) => state.courses);
  const sidebarViews = ['module-general', 'module-checkpoints'];
  const hideSidebar = 
    location.pathname.includes('courses') &&
    !sidebarViews.includes(currentPage)  ;
 
  useEffect(() => {
    if (exportError) {
      setTimeout(() => {
        dispatch(setExportError(''));
      }, 4000);
    }
  }, [exportError])

  return (
    <div className="Home">
      {exportError && <div className="Home__alert">
        <Alert severity="error">{exportError}</Alert>
      </div>}
      {!hideSidebar && <SideBar />}
        <main className={`${hideSidebar ? "Home__main-no-sidebar" : "Home__main"}`}>
          <Routes>
            <Route path="*" element={!isMobile ? <Navigate to="/courses" /> : <Navigate to="/wall"/>} />
            <Route path="chat" element={<ChatBotSection />} />
            <Route path="analytics" element={<EmptySection />} />
            <Route path="learn" element={<EmptySection />} />
            <Route path="settings" element={<AccountSection />} />
            <Route path="script" element={<DocSection />} />
            <Route path="scripts" element={<DocsSection />} />
            <Route path="slides" element={<SlidesSection />} />
            <Route path="slide" element={<SlideSection />} />
            <Route path="slidesArchive" element={<SlideArchive />} />
            <Route path="scriptsArchive" element={<DocArchive />} />
            <Route path="courses" element={<ModulesSection />} />
          </Routes>
        </main> 
    </div>
  )
}
