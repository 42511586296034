import React, { useEffect, useState, useRef, useCallback } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../../../../utils/CookieHelper';
import { setLoadingPage } from '../../../../../redux/slices/coursesSlice';
import { ReactComponent as UploadIcon } from '../../../../../images/upload-icon-course.svg';
import { RootState } from '../../../../../redux/store';
import { setActiveCourse, setActiveCourseNew, setCurrentPage, fetchCourses,  clearData} from '../../../../../redux/slices/coursesSlice';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader'; 
import SaveButton from '../../../../Buttons/SaveButton/SaveButton'; 
import { useSocket } from '../../../../../App';
import './ModulesCreate.scss';

export default function ModulesCreate() {
  const { loadingPage, courses } = useSelector((state: RootState) => state.courses);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const token = getCookie('jwtToken');
  const newSocket = useSocket();
  const [loaderText, setLoaderText] = useState<string>('');
  const dispatch = useDispatch<any>();
  const [prompt, setPrompt] = useState<string>('');
  const [page, setPage] = useState(0);
  const observer = useRef<IntersectionObserver | null>(null);
  const [fetchingCourses, setFetchingCourses] = useState<boolean>(true);

  const getCoursesList = async () => {
    await dispatch(fetchCourses({token, page})); 
    setFetchingCourses(false);
  };


  useEffect(() => {
    getCoursesList();
  }, [dispatch, token, page]);

  const lastElementRef = useCallback((node: HTMLDivElement) => {
    if (loadingPage) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loadingPage]);
 
   

  const handleGenerateByPrompt = () => {
    if (newSocket) {
      dispatch(setLoadingPage(true));
      setLoaderText('Course is forming...')
      newSocket.emit('education:generate_prompt', {
        instructions: prompt
      });

      newSocket?.on('education:generate:end', (data: any) => {
        newSocket.off('education:generate:end');
        dispatch(clearData());
        dispatch(setActiveCourseNew(data));
        dispatch(setCurrentPage('module-checkpoints'));
        setLoaderText('')
        dispatch(setLoadingPage(false));
      });
    }
  }
  
  const handleDivClick = () => {
    fileInputRef.current?.click(); // Step 3: Use the ref to trigger the click event of the input
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      dispatch(setLoadingPage(true));
      setLoaderText('Course is forming...')
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/education/upload`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();

          if (data.success) {
          
            if (newSocket) {

              newSocket.emit('education:generate', {
                filePath: data.filePath,
                fileType: data.fileType
              });

              newSocket?.on('education:generate:end', (data: any) => {
                newSocket.off('education:generate:end');
                dispatch(clearData());
                // add menu selector here if new
                dispatch(setActiveCourseNew(data));
                setLoaderText('')
                dispatch(setCurrentPage('module-checkpoints'));
                dispatch(setLoadingPage(false));

              });

            }

          } else {
            dispatch(setLoadingPage(false));
            setLoaderText('')
             
          }
        } else {
          dispatch(setLoadingPage(false));
          setLoaderText('')
          
        }
      } catch (error) {
        dispatch(setLoadingPage(false));
        setLoaderText('')       
      }
    }
};



  return (
    <>
      {loadingPage ? (
        <div className="ModulesCreate">
          <div className="ModulesCreate__container-loader">
          <CourseLoader 
            customText={loaderText}/>
          </div>
        </div>
      ) : (
        <div className="ModulesCreate">
          <div className="ModulesCreate__container-top">

             
              <div className="ModulesCreate__container-top__header">
              Create an eLearning Course
              </div>
              <div className="ModulesCreate__container-top__content">
                <p>Choose between either uploading a file that you want to be converted into an eLearning course OR
                provide an extremely detailed prompt that will be used to build your course. </p>
                <p><span>Reminder:</span> If you upload a file, remember that the larger that file the longer
                it will take for your course to be created. (For every 1 page of content in the
                uploaded file it typically takes 30-60 seconds to create the course)</p>
              </div>
 

            <div className="ModulesCreate__container-top__upload-block">
              <div className="ModulesCreate__container-top__upload-block__item">
                <div className="ModulesCreate__container-top__upload-block__item__header">
                  Use a file
                </div>
                <input
                  type="file"
                  accept=".pdf, audio/*, video/*"
                  id="file-upload"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <div 
                  className="ModulesCreate__container-top__upload-block__item__content" 
                >
                  <UploadIcon
                    style={{marginTop: '28px'}}
                    />
                  <SaveButton 
                      styles={{marginTop: '16px'}}
                      text='Add your file'
                     
                      action={handleDivClick}
                    />  
                  <div className="ModulesCreate__container-top__upload-block__item__content__list">
                    <div className="ModulesCreate__container-top__upload-block__item__content__list__header">
                      You can form your course using:
                    </div>
                    <div className="ModulesCreate__container-top__upload-block__item__content__list__item">
                      video in any format
                    </div>
                    <div className="ModulesCreate__container-top__upload-block__item__content__list__item">
                      audio in any format
                    </div>
                    <div className="ModulesCreate__container-top__upload-block__item__content__list__item">
                      text files in pdf
                    </div>
                  </div>
                  
                </div>
              </div>


              <div className="ModulesCreate__container-top__upload-block__item">
                <div className="ModulesCreate__container-top__upload-block__item__header">
                Use a prompt
                </div>
                  <textarea 
                    className="ModulesCreate__container-top__upload-block__item__textarea"
                    placeholder='Please, add a topic for your future course so that the AI knows what to focus on'
                    value={prompt} 
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                  <div  className="ModulesCreate__container-top__upload-block__item__btn">
                    <SaveButton 
                      text='Generate'
                      disabled={!prompt}
                      action={() => {
                        handleGenerateByPrompt()
                      }}
                    />  
                  </div>
              </div>
            </div>

          </div>
          <div className="ModulesCreate__container-bottom">
          <div className="ModulesCreate__container-bottom__header">
            Saved Courses
          </div>
         
          {!fetchingCourses && courses ? (
              <div className="ModulesCreate__container-bottom__list">
               {
                  courses.map((item, index) => {
                    if (index === courses.length - 1) {
                      return (
                        <div 
                          className="ModulesCreate__container-bottom__list__item"
                          key={item.id}
                          ref={lastElementRef}
                          onClick={() => {  
                            dispatch(setActiveCourse(item));
                            dispatch(setCurrentPage('module-checkpoints'));
                          }}
                        >
                          { item.title }
                        </div>
                      );
                    } else {
                      return (
                        <div 
                          className="ModulesCreate__container-bottom__list__item"
                          key={item.id}
                          onClick={() => { 
                            console.log(item);
                            dispatch(setActiveCourse(item));
                            dispatch(setCurrentPage('module-checkpoints'));
                          }}
                        >
                          { item.title }
                        </div>
                      );
                    }
                  })
                }
              </div>
            ) : (
              <div className="ModulesCreate__container-bottom__list">
                {fetchingCourses && <div className="fetchingCourses loader circle-loader-1"/>}
              </div>
            )}

          </div>

        </div>
        )
      }
    </>
  )
}
