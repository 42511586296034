import React, { ChangeEvent, useState, useEffect } from 'react'; 
import  CustomColorButton  from '../Buttons/CustomColorButton/CustomColorButton';
import SectionGenerateModal from '../Modals/SectionGenerateModal/SectionGenerateModal';
import axios from 'axios'; 
import { getCookie } from '../../utils/CookieHelper';
import { useSocket } from '../../App';
import { isVideo } from '../../utils/helpers';
import './HeaderImageBlock.scss';


interface HeaderImageBlockProps {
  image: string | null;
  updateHeaderImage: (value: string | null) => void;
}

const HeaderImageBlock: React.FC<HeaderImageBlockProps> = ({ image, updateHeaderImage }) => {

  const API = `${process.env.REACT_APP_API_URL}api`;
  const newSocket = useSocket();
  const [loading, setLoading] = useState(false);  
  const [ showModal, setShowModal] = useState<'image' | null>(null); 
  const [fileType, setFileType] = useState<'image' | 'video'>('image');


  useEffect(() => { 
    if(!showModal) return;
    const handleImageGenerationEnd = (data: string) => {
    updateHeaderImage(data);
      setLoading(false);
      closeModal();
    };
  
    newSocket?.on('education:generate-image:end', handleImageGenerationEnd); 
  
    return () => {
      newSocket?.off('education:generate-image:end', handleImageGenerationEnd); 
    };
  }, [newSocket, updateHeaderImage, showModal]);
  
  const handleDeleteImage = () => {
    updateHeaderImage(null);
  }

  const uploadImage = async (file: File, token: string) => {
    const formData = new FormData();
    formData.append("file", file);
  
    return axios.post(
      `${API}/user/upload-file`, // Make sure this is your correct endpoint
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`
          // Content-Type is not needed, it's set automatically by the browser
        }
      }
    )
    .then((response) => response.data);
  };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setLoading(true); // Indicate loading
      try {
        const token = getCookie('jwtToken');
        if (token) {
          const response = await uploadImage(file, token); 
          updateHeaderImage(response); 
        }
      } catch (error) {
        console.error('Error during image upload', error); 
      }
      setLoading(false); // Reset loading indicator
      event.target.value = ''; // Reset the file input
    }
  };
  
  const handleModalConfirm = (modalInput: string) => {
    newSocket?.emit('education:generate-image', { 
      instructions: modalInput, // Instructions from modal input
    });
    setLoading(true);
  };
  

  const handleImageGenerate = () => { 
    setShowModal('image'); 
  }

  const closeModal = () => {
    setShowModal(null); 
  };

  return (
    <div className="header-image-block">
       { showModal && 
            <SectionGenerateModal
                header={ 'Generate image with AI'}
                subHeader={ 'The picture will be generated according to the prompt you add here'}
                text={ 'Tell Landdai what exactly you want to see in the picture for this section'}
                loading={loading}
                action={handleModalConfirm}
                close={closeModal}
            />
        }

         <div  className={`header-image-block__sub-section`}>
                <div className="header-image-block__image-container">
                {
                    image && image !== 'template' ? (
                      isVideo(image) ? (
                        <video controls className="header-image-block__image" key={image}>
                          <source src={image} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img src={image} alt="" className="header-image-block__image" />
                      )
                    ) : (
                      <div className="image-to-replace"/>
                    )
                  }
                  { (<div className="image-to-replace__btn-cont">
                    <CustomColorButton
                      text="Delete"
                      onClick={() => handleDeleteImage()}
                    />
                    <CustomColorButton
                      text="Generate picture"
                      textColor='#00539C'
                      backgroundColor='#FCFCFC'
                      onClick={() => handleImageGenerate()}
                    />
                    <CustomColorButton
                      text="Upload picture"
                      textColor='#00539C'
                      backgroundColor='#FCFCFC'
                      onClick={() => {
                        setFileType('image');
                        setTimeout(() => {
                          document?.getElementById(`file-input-headerImage`)?.click();
                        }, 0);
                       
                      }}
                    />
                    <CustomColorButton
                      text="Upload video"
                      textColor='#00539C'
                      backgroundColor='#FCFCFC'
                      onClick={() => {
                        setFileType('video');
                        setTimeout(() => {
                          document?.getElementById(`file-input-headerImage`)?.click();
                        }, 0);
                        
                      }}
                    />

                     {loading && <div className="loadingImage loader circle-loader-1"/>}
                 
                    <input
                      className="ImageUploader__file-input"
                      id={`file-input-headerImage`}
                      accept={fileType === 'image' ? 
                        "image/jpeg, image/jpg, image/png" :
                         "video/mp4, video/webm, video/quicktime"
                        }
                      type="file" 
                      onChange={(e: any) => {
                        handleImageChange(e) 
                      }}
                    />
                  </div>)}
              </div> 
             
          </div>
 
       
    </div>
  );
};

export default HeaderImageBlock;
