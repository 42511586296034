import React from 'react'; 
import './Steps.scss';

interface StepThreeProps {
  question?: string;
  options?: string[];
  answer: number | null; // Changed to possibly a single number or null if no selection
  setAnswer: (answer: number) => void; // Updated to accept a single number
}

const StepThree = ({
  question,
  options,
  answer,
  setAnswer
}: StepThreeProps) => {
  const getLetter = (index: number) => {
    return String.fromCharCode(65 + index);
  };

  const handleOptionClick = (optionIndex: number) => {
    // Since it's a single-choice question, simply update the answer
    // No need to check if it's already included or not
    setAnswer(optionIndex);
  };

  return (
    <div className="Step">
      <div className="Step__container">
        <div className="Step__container__header">
          <div className="Step__container__header__question">
            {question}
          </div>
          <div className="Step__container__header__instruction">
            Please fill in the blanks
          </div>
        </div>
        {options?.map((option, index) => (
          <div 
            key={index}
            className={`Step__container__body__three ${answer === index ? 'selected' : ''}`} 
            onClick={() => handleOptionClick(index)} // Use index for comparison and updating
          >
            <div className="Step__container__body__three__option">
              <div className="Step__container__body__three__option__letter">
                {getLetter(index)}
              </div>
              <div className="Step__container__body__three__option__text">
                {option}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepThree;
