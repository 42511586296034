
import { useDispatch } from 'react-redux';  
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg'; 
import { setCurrentPage } from '../../../../../redux/slices/sharedSlice';
import shareUnavaliable from '../../../../../images/shareUnavaliable.png';

import './SharedUnavaliable.scss';

export default function SharedUnavaliable() {
  
  const dispatch = useDispatch<any>();
   
  return (
    <>
    
        <div className="SharedUnavaliable">
          
          <div className="SharedUnavaliable__container">
            <div className="SharedUnavaliable__container__left" onClick={() => dispatch(setCurrentPage('shared-login'))}>
              <ArrowLeft/>
            </div>
            <div className="SharedUnavaliable__container__right">
                <div className="SharedUnavaliable__container__right__content-block">
                        <div className="SharedUnavaliable__container__right__text">
                            Sorry, the course is unavailable!
                        </div> 
                        <img src={shareUnavaliable} alt='' className="SharedUnavaliable__container__right__img">
                            
                        </img>
                </div>
            </div>

          </div>

        </div>
        
      
    </>
  )
}
