import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../../redux/store';
import backIcon from '../../../../images/arrow-left.svg';
import { 
  cleanPresentation, 
  endLoading, 
  getAllPrompts, 
  getPresentationFromDoc,
  setObjAzure,
  setObjGoogle,
  setObjPDF,
} from '../../../../redux/slices/slidesSlice';
import AiGenerateModal from '../../../Modals/AiGenerateModal/AiGenerateModal';
import TitleInput from '../../SlidesSection/SlideSection/TitleInput/TitleInput';
import CloseModal from '../../../Modals/CloseModal/CloseModal';
import SaveButton from '../../../Buttons/SaveButton/SaveButton';
import RegenerateButton from '../../../Buttons/RegenerateButton/RegenerateButton';
import ExportModal from '../../../Modals/ExportModal/ExportModal';
import AiTextareaModal from '../../../Modals/AiTextareaModal/AiTextareaModal';
import LowBalanseModal from '../../../Modals/LowBalanseModal/LowBalanseModal';
import { getCookie, deleteCookie } from '../../../../utils/CookieHelper';
import Loader from '../../../Loader/Loader';
import DocsTextEditor from './DocEditor/DocEditor';
import { 
  changeScriptStatus, 
  getScriptForEdit, 
  scriptSaveStatus, 
  sendScriptToAI, 
  setExportError, 
  startLoading, 
  updateCurrentSelection, 
  updateScript, 
} from '../../../../redux/slices/scriptsSlice';
import { sendDoc } from '../../../../utils/sendDocs';
import DropdownExport from '../../../DropdownExport/DropdownExport';
import WhiteButton from '../../../Buttons/WhiteButton/WhiteButton';
import { useSocket } from '../../../../App';
import './DocSection.scss';
import GoogleLoginModal from '../../../Modals/GoogleLoginModal/GoogleLoginModal';

export default function DocSection() {
  const [searchParams] = useSearchParams() || '';
  const getTemplate = searchParams.get('template') || '';
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const token = getCookie('jwtToken');
  const rediePath = getCookie('path');
  const newSocket = useSocket();
  const editId = searchParams.get('edit') || '';
  const highlighted = window?.getSelection()?.toString();
  const azureToken = getCookie('azureRefreshToken');
  const googleToken = getCookie('googleToken');

  const { script, saveScript, loading, loadingPage, scriptStatus, user } = useSelector((state: RootState) => state.scripts);
  const { prompts, loading: loadingPropmpts, objAzure, objGoogle, objPDF } = useSelector((state: RootState) => state.slides);

  const [modal, setModal] = useState(true);
  const [ loginModal, setLoginModal ] = useState(false);
  const [change, setChange] = useState<any>();
  const [closeModal, setCloseModal] = useState("");
  const [exportModal, setExportModal] = useState("");
  const [textareaModal, setTextareaModal] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);

  const scrollToBottom = () => {
    const domNode = document.querySelector('.doc-editor');
    if (domNode) {
      setTimeout(() => domNode.scrollTop = domNode.scrollHeight, 500);
    }
  };

  useEffect(() => {
    const func = async() => {
      if(!googleToken) {
        setObjGoogle({});
        setObjAzure({});
        setObjPDF({});
        return;
      }
      if(!azureToken) { 
        window.location.href = `${process.env.REACT_APP_API_URL}api/auth/azure`;
         
        return;
      }
      if(objAzure?.presName) {
        const res = await sendDoc(objAzure.presName  || script?.title || 'go', 'azure', objAzure.presStyle || script);
        dispatch(setObjAzure({}));
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          dispatch(setObjAzure({}));
          setExportModal('send');
        }
        document.cookie = "path=";
      }
      if(objGoogle?.presName) {
        const res = await  sendDoc(objGoogle.presName || script?.title || 'go', 'send', objGoogle.presStyle || script);
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          dispatch(setObjGoogle({}));
          setExportModal('send');
        }
        document.cookie = "path=";
      }
      if(objPDF?.presName) {
        const res = await sendDoc(objPDF.presName || script?.title || 'go', 'Pdf', objPDF.presStyle || script);
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          dispatch(setObjPDF({}));
          setExportModal('send');
        }
        document.cookie = "path=";
      }

    }
    if (rediePath) {
      func();
    }

    return () => {
      dispatch(updateCurrentSelection(null));
    }
  }, []);


  useEffect(() => {
    if (editId && !script?.text?.length) {
      dispatch(startLoading());
      newSocket?.emit('get-doc-by-id', {
        doc_id: editId
      });

      newSocket?.on('get-doc-by-id:end', (data: any) => {
        dispatch(getScriptForEdit(data.doc));
      });
    }
  }, [editId]);

  useEffect(() => {
    if (scriptStatus === 'add' && !loading) {
      scrollToBottom();
      dispatch(changeScriptStatus(''))
    };
  }, [scriptStatus, loading]);

    useEffect(() => {
    if (token && getTemplate) {
      const obj = {
        body: {
          isScript: true,
          id: getTemplate,
        },
        token,
      };
      dispatch(getAllPrompts(obj));
    };
  }, [token, getTemplate]);

  useEffect(() => {
    saveScript?.length && setCloseModal("save");
  }, [saveScript]);

  useEffect(() => {
    if (change) {
      dispatch(updateScript({
        ...script,
        style: change
      }))
    }
  }, [change]);

  useEffect(() => {
    if (highlighted) {
      setHighlightedText(highlighted);
    }
  }, [highlighted]);

  const closeGoogleLogin = () => {
    dispatch(setObjAzure({}));
    dispatch(setObjGoogle({}));
    dispatch(setObjPDF({}));
    dispatch(endLoading());
    deleteCookie('path');
    setLoginModal(false);
  };

  const savePres = () => {
    setExportModal("save");

    const obj = {
      text: script?.text,
      title: script?.title,
      id: script?.id,
      style: script.style
    };

    newSocket?.emit('update-doc', obj);
  };

  const sendTextToCreate = () => {
    dispatch(cleanPresentation());
    if (token && script?.style) {
      const names = prompts?.map((a: any) => {
        if (window.location.pathname === '/slide') {
          if (a?.second_title) {
            if (!a?.isScript) {
              return a?.second_title;
            }
          } else {
            return "";
          }
        } else {
          if (a?.second_title) {
            if (a?.isScript) {
              return a?.second_title;
            }
          } else {
            return "";
          }
        }
      })?.filter((b: any) => b?.length > 0);

      const bodyText = {
        template_name: names[0] || 'custom presentation',
        slides: []
      } as any;

      let obj = {
        title: '',
        text: '',
      } as any;

      JSON.parse(script?.style)?.blocks.forEach((item: any, i: number) => {
        if (item.type === 'header-one') {
          bodyText.title = item.text.replaceAll('\n', '');
          if (JSON.parse(script?.style)?.blocks[i + 1]?.type === 'header-two' || JSON.parse(script?.style)?.blocks[i + 1]?.type === 'header-three') {
            bodyText.text = JSON.parse(script?.style)?.blocks[i + 2].text.replaceAll('\n', '');
          } else {
            bodyText.text = JSON.parse(script?.style)?.blocks[i + 1].text.replaceAll('\n', '');
          }
        } else if (item.type === 'header-two' || item.type === 'header-three') {
          if (obj?.title?.length && obj?.text?.length) {
            bodyText.slides.push(obj);
            obj = {
              title: '',
              text: '',
            };
          }
          obj.title = item.text.replaceAll('\n', '');
        } else {
            if (item.type === 'unordered-list-item') {
              obj.text += ' \t ' + item.text.replaceAll('\n', '');
            } else {
              if (item?.text?.includes('Action Items:')) {
                obj.text += item.text.replaceAll('\n', '');
              }

              if (obj?.title?.includes('Conclusion')) {
                obj.text += item.text.replaceAll('\n', '');
              }
            };
        } 

        if (i === JSON.parse(script?.style)?.blocks.length - 1) {
          bodyText.slides.push(obj);
        }
      });

      dispatch(getPresentationFromDoc({
        token,
        body: bodyText,
      }));
      
      navigate(`/slide`);
    };
  };

  const generateMoreWithAi = () => {
    dispatch(startLoading());

    newSocket?.emit('generate-more-content-doc', {
      prompt: highlightedText,
      doc_id: script?.id
    });
    setHighlightedText('');
  }

  return (
    <>
      {(loading || loadingPropmpts) && (
        <Loader page={loadingPage} />
      )}
      {
        loginModal
         && 
         <GoogleLoginModal 
            // path={`path=${process.env.REACT_APP_API_URL}script`}
            close={closeGoogleLogin} />
      }
      { paymentModal &&
        <LowBalanseModal 
          onClose={() => setPaymentModal(false)}
        />
      }
        <div 
          className="DocSection"
          style={{
            opacity: loading ? 0 : 1
          }}
        >
          <div className="DocSection__header">
            <div className="DocSection__back-section">
              <img
                className="DocSection__back-btn"
                src={backIcon}
                alt="back"
                onClick={() => setCloseModal('save')}
              />
              <TitleInput />
            </div>
            
            <div className="DocSection__window-btns">
              <div className="DocSection__window-btn">
                <WhiteButton
                  text={"Convert to Slide Deck"}
                  action={() => setCloseModal('saveBeforeCreate')}
                />
              </div>

              <DropdownExport
                presName={script?.title}
                presStyle={script}
                setExportModal={setExportModal}
                setLoginModal={setLoginModal}
              />

              <SaveButton
                text={"Save"}
                action={savePres}
              />
            </div>  
          </div>  
          <div className="DocSection__window">
            <div className="DocSection__window-text">
              <DocsTextEditor
                text={script?.style && JSON.parse(script?.style).blocks.map((item: any) => item.text).join('\n') || script?.text}
                change={script?.style}
                onChange={setChange}
                styled={script?.style?.length > 0}
              />
            </div>
              
            </div>
            <footer className="DocSection__footer-btns">
              <RegenerateButton
                text={"Change with AI"}
                disabled={!highlightedText}
                action={() => highlightedText && generateMoreWithAi()}
              />

              <RegenerateButton
                text={"Generate More Content"}
                action={() => setTextareaModal('moreText')}
              />

              <RegenerateButton
                text={"Regenerate New Doc"}
                action={() => {
                  const regObj = {
                    title_second: script?.title_second,
                    title: script?.title,
                    prompt: "",
                    isCustom: "",
                    promptId: script?.promptId,
                  };
                  dispatch(sendScriptToAI({
                    token,
                    body: regObj,
                  }));
                }}
              />
            </footer>
          {(modal && !script?.text?.length) && <AiGenerateModal />}
          {textareaModal?.length > 0 && <AiTextareaModal
              path={textareaModal}
              setModal={setTextareaModal}
            />}
          {!!closeModal.length && <CloseModal
              action={() => {
                if (closeModal === 'save') {
                  savePres();
                  saveScript?.length ? navigate(saveScript) : navigate('/scripts');
                  dispatch(scriptSaveStatus(''));
                } else {
                  if(user?.quantity === 0) {
                    setPaymentModal(true);
                    setCloseModal("");
                  } else {
                    savePres();
                    dispatch(scriptSaveStatus(''));
                    setTimeout(() => {
                      sendTextToCreate();
                    }, 3000)
                  }
                }
              }}
              closeNoSave={() => {
                if (closeModal === 'save') {
                  saveScript?.length ? navigate(saveScript) : navigate('/scripts');
                  dispatch(scriptSaveStatus(''));
                } else {
                  if(user?.quantity === 0) {
                    setPaymentModal(true);
                    setCloseModal("");
                  } else {
                    saveScript?.length && dispatch(scriptSaveStatus(''));
                    sendTextToCreate();
                    setPaymentModal(true);
                    setCloseModal("");
                  }
                }
              }}
              close={() => {
                setCloseModal("");
                saveScript?.length && dispatch(scriptSaveStatus(''));
              }}
              text={"Would you like to save your changes before you leave?"}
            />
          }
          {exportModal?.length > 0 && <ExportModal text={exportModal} close={() => setExportModal("")} />}
        </div> 
    </>
  )
}
