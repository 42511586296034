import {ReactComponent as AlertTriangle} from '../../../../../images/alert-triangle.svg';
import { checkSharedCourse} from '../../../../../api/shareApi';
import { useEffect, useState } from 'react';
import { RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux'; 
import { setCurrentPage, setLoadingPage } from '../../../../../redux/slices/sharedSlice';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton'; 
import Loader from '../../../../Loader/Loader'; 
import './MobileSharedLogin.scss';

export default function MobileSharedLogin() {
    const dispatch = useDispatch<any>();
    const { loadingPage } = useSelector((state: RootState) => state.shared);
    const activeCourseUid= window.location.pathname.split('/')[2]; 
    const [email,
        setEmail] = useState('');
    const [name,
        setName] = useState(''); 
    const [emailError,
        setEmailError] = useState(false);
    const [nameError,
        setNameError] = useState(false);

    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailError(false);
    }

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        setNameError(false);
    }

    const checkEmail = (email: string) => { 
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const onSubmit = async () => {
        if(!name){
            setNameError(true);
        }
        if(!email || !checkEmail(email)){
            setEmailError(true);
        }
        if(name && email && checkEmail(email)){
           dispatch(setCurrentPage('shared-thanks')); 
        } else {
            return;
        }
       
    }

    const checkShared = async (activeCourseUid: string) => {
        if(activeCourseUid){
            dispatch(setLoadingPage(true));
            const course = await checkSharedCourse(activeCourseUid);
            dispatch(setLoadingPage(false));
            if(!course.id){
                dispatch(setCurrentPage('shared-unavaliable'));
            }  
        }
    }

    useEffect(() => {
        checkShared(activeCourseUid);
    }
    , [activeCourseUid]);

    return (

        <>
        {loadingPage ? (
            <Loader/>
        ) : (
        <div className='MobileSharedLogin'>
            <div className='MobileSharedLogin__title'>
                Welcome!
            </div>
        
            <div className='MobileSharedLogin__form-block'>
               
                <div className='MobileSharedLogin__form-block__sub-header'>
                    <div className='MobileSharedLogin__form-block__sub-header__text'>
                        Please, fill the fields to access your course.
                    </div>
                    <div className='MobileSharedLogin__form-block__sub-header__text'>
                        You will the copy with your answers by the email you've provided
                    </div>
                </div>
                <div className='MobileSharedLogin__form-block__form-container'>
                    <div className='MobileSharedLogin__form-block__form-container__input-block'>
                        
                        <input
                            type='text'
                            value={name}
                            onChange={onNameChange}
                            className={'MobileSharedLogin__form-block__form-container__input-block__input' + (nameError ? ' MobileSharedLogin__form-block__form-container__input-block__input--error' : '')}
                            placeholder='Name'/>
                            {nameError && (
                                <div
                                    className='MobileSharedLogin__form-block__form-container__input-block__error-block'>
                                    <AlertTriangle/>
                                    <span
                                        className='MobileSharedLogin__form-block__form-container__input-block__error-block__text'>
                                        This field is required
                                    </span>
                                </div>
                            )}
                    </div>
                    <div className='MobileSharedLogin__form-block__form-container__input-block'>
                        
                        <input
                            type='text'
                            value={email}
                            onChange={onEmailChange}
                            className={'MobileSharedLogin__form-block__form-container__input-block__input' + (emailError ? ' MobileSharedLogin__form-block__form-container__input-block__input--error' : '')}
                            placeholder='Email'/> {emailError && (
                            <div
                                className='MobileSharedLogin__form-block__form-container__input-block__error-block'>
                                <AlertTriangle/>
                                <span
                                    className='MobileSharedLogin__form-block__form-container__input-block__error-block__text'>
                                    This field is required
                                </span>
                            </div>
                        )}
                    </div>
                    <div className='MobileSharedLogin__form-block__form-container__button-block'>
                        <SaveButton
                            text='Go to Course'
                            styles={{
                                padding: '16px 10px',
                            }}
                            action={onSubmit}
                            />

                    </div>
                </div>
            </div>
            

            </div>

        
    )
        }
        </>
        )
}
