import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { RootState } from '../../../../redux/store';
import PresentationSelectItem from '../PresentationSelectItem/PresentationSelectItem';

import rightArrow from '../../../../images/CaretRight.svg';
import leftArrow from '../../../../images/CaretLeft.svg';

import './PresentationCarousel.scss';

type Props = {
  setResultObj: (arg: any) => void,
}

export default function PresentationCarousel({ setResultObj }: Props) {
  const [promptList, setPromptList] = useState<any[]>([]);
  const [resArray, setResArray] = useState<any[]>([]);
  const [position, setPosition] = useState(0);
  const [dot, setDot] = useState(0);
  const ref = useRef<any>(null);

  const { prompts } = useSelector((state: RootState) => state.slides);

  useEffect(() => {
    if (prompts) {
      if (window.location.pathname === '/slide') {
        const list = prompts.filter((prompt: any) => !prompt?.isScript && prompt?.prompt?.id);

        setPromptList(list);
        setResArray(list.map(a => {return {}}));
      } else {
        const list = prompts.filter((prompt: any) => prompt?.isScript && prompt?.prompt?.id);

        setPromptList(list);
        setResArray(list.map(a => {return {}}));
      }
    }
  }, [prompts]);

  useEffect(() => {
    setPosition(dot * ref?.current?.offsetWidth);
    if (resArray[dot]) {
      setResultObj({
        ...resArray[dot],
        category_id: promptList[dot]?.prompt?.id,
        title: promptList[dot]?.second_title
      });
    };
  }, [ref, resArray, dot]);

  const handleArrowClick = (side: 'prev' | 'next') => {
    if (side === 'prev') {
      setDot(prevIndex => prevIndex === 0 ? promptList?.length - 1 : prevIndex - 1);
    } else {
      setDot(prevIndex => prevIndex === promptList?.length - 1 ? 0 : prevIndex + 1)
    }
  };

  return (
    <>
      <div 
        className="PresentationCarousel"
        ref={ref}
      > 
        <div 
          className="PresentationCarousel__container"
          style={{
            transform: `translateX(-${position}px)`
          }}
        >
          {promptList?.map((prompt: any, index: number) => (
            <PresentationSelectItem
              resArray={resArray}
              setResArray={setResArray}
              key={prompt?.prompt?.id} 
              prompt={prompt}
              index={index}
            />
          ))}
        </div>
      </div>
      {promptList.length > 1 && (
        <div className="PresentationCarousel__arrows">
          <div
            className="PresentationCarousel__arrows-btn"
            onClick={() => handleArrowClick('prev')}
          >
            <img
              className="PresentationCarousel__arrows-btn-img"
              src={leftArrow}
              alt="left"
            />
          </div>
          <div className="PresentationCarousel__dots">
            {promptList?.map((el: any, i: number) => (
              <div
                className={classNames('PresentationCarousel__dot', {
                  'PresentationCarousel__dot--active': dot === i
                })}
                key={i}
              >
              </div>
            ))}
          </div>
          <div
            className="PresentationCarousel__arrows-btn"
            onClick={() => handleArrowClick('next')}
          >
            <img
              className="PresentationCarousel__arrows-btn-img"
              src={rightArrow}
              alt="right" />
          </div>
        </div>
      )}
    </>
  )
}
