import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { verifyOTP, sendOTP } from '../../api/otpApi';
import GoogleButton from '../../components/Buttons/GoogleButton/GoogleButton';
import CustomColorButton from '../../components/Buttons/CustomColorButton/CustomColorButton';
import AzureButton from '../../components/Buttons/AzureButton/AzureButton';
import OTPButton from '../../components/Buttons/OTPButton/OTPButton';
import Loader from '../../components/Loader/Loader';
import bigLogo from '../../images/LanndaiBigLogo.png'; 
import smallLogo from '../../images/whiteLogo.png';
import CustomInput from '../../components/Inputs/EmailInput/CustomInput';
import SaveButton from '../../components/Buttons/SaveButton/SaveButton';
import './LoginPage.scss'; 
import { setCookie } from '../../utils/CookieHelper';

type LoginPageType = 'main' | 'otp' | 'code';

export default function LoginPage() {
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState<LoginPageType>('main');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(''); 
  const [codeError, setCodeError] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailSend = async () => {
    setLoader(true);
    let res = await sendOTP(email);
    if(res.success){
      navigateToCode();
    } else{
      setEmailError(res);
    }
    setLoader(false);
    
  }
   
  const handleCodeSend = async () => {
    setLoader(true);
    let res = await verifyOTP(email, code);
    
    if(res.jwtToken){
        if(isMobile){
          window.location.href = `${process.env.REACT_APP_API_URL}/wall`;
        } else{
          window.location.href = `${process.env.REACT_APP_API_URL}`;
        }
      

    } else{
      setCodeError(res);
    }
    setLoader(false);
  }

  const validateEmail = () => {
    if (!email) {
      setEmailError('Please enter your email');
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Please enter a valid email');
      return false;
    }
    setEmailError('');
    return true;
  }

  const validateCode = () => {
    if (!code) {
      setCodeError('Please enter the code');
      return false;
    }
    setCodeError('');
    return true;
  }

  const navigateToCode = () => {
    setPage('code');
  };

  const navigateToOtp = () => {
    setPage('otp');
  };


  const handleGoogle = () => {
    setLoader(true);
    window.location.href = `${process.env.REACT_APP_API_URL}api/auth`;
  };

  const handleAzure = () => {
    setLoader(true);
    window.location.href = `${process.env.REACT_APP_API_URL}api/auth/azure`;
  };

  return (
    <div className={`LoginAccount ${isMobile ? 'LoginAccount_mob' : ''}`}>
      {loader ? (
        <Loader 
        />
      ) : (
        <>
          {page === 'code' && (
            <>
              {!isMobile && (
                <img
                  src={smallLogo}
                  alt="small-logo"
                  className="LoginAccount__white-logo"
                />
              )}
              <div className="LoginAccount__block LoginAccount__block--left LoginAccount__block__otp">
                <div className="LoginAccount__otp-title">One Time Password</div>
                <div className="LoginAccount__otp-input">
                  <CustomInput
                    type='code'
                    text={code}
                    error={codeError}
                    setText={setCode}
                    customStyles={{width: isMobile ? '312px' : '447px'}}
                    isMobile={isMobile}
                  />
                </div>
                <div className="LoginAccount__otp-button">
                  <SaveButton
                    action={()=>{
                      
                      if(validateCode()){
                        handleCodeSend();
                      } 
                    }}
                    text="Continue"
                    styles={isMobile ? {fontSize: '16px', padding: "16px 10px", lineHeight: "20px", height: "52px" } : {fontSize: '18px'}}

                  /> 
 
                  <CustomColorButton
                    text="Resend Code"
                    onClick={()=>{
                      if(validateEmail()){
                        handleEmailSend();
                      } 
                    }} 
                    backgroundColor ='transparent' 
                    textColor = '#00539C' 
                    customStyle={{width: '100%', marginTop: '12px', boxShadow: 'none', fontSize: '18px', fontWeight: '500'}}
                  /> 

                  <CustomColorButton
                    text="Other Email"
                    onClick={()=>{
                      setEmail('');
                      setPage('otp');
                    }} 
                    backgroundColor ='transparent' 
                    textColor = '#00539C' 
                    customStyle={{width: '100%', marginTop: '12px', boxShadow: 'none', fontSize: '18px', fontWeight: '500'}}
                  /> 
               
                </div>
              </div>
              {isMobile && (
                  <h1 className="LoginAccount__title"><span>Landdai</span></h1>
                )}
                 <div className="LoginAccount__block">
                <img src={bigLogo} alt="logo" />
              </div>
            </>
          )}
          {page === 'otp' && (
            <>
              {!isMobile && (
                <img
                  src={smallLogo}
                  alt="small-logo"
                  className="LoginAccount__white-logo"
                />
              )}
              <div className="LoginAccount__block LoginAccount__block--left LoginAccount__block__otp">
                <div className="LoginAccount__otp-title">One Time Password</div>
                <div className="LoginAccount__otp-input">
                  <CustomInput
                    type='email'
                    text={email}
                    error={emailError}
                    setText={setEmail}
                    customStyles={{width: isMobile ? '312px' : '447px'}}
                    isMobile={isMobile}
                  />
                </div>
                <div className="LoginAccount__otp-button">
                  <SaveButton
                    action={()=>{
                      if(validateEmail()){
                        handleEmailSend();
                      } 
                    }}
                    text="Send Code"
                    styles={isMobile ? {fontSize: '16px', padding: "16px 10px", lineHeight: "20px", height: "52px" } : {fontSize: '18px'}}
                  /> 
                </div>
                
              </div>
             
              {isMobile && (
                  <h1 className="LoginAccount__title"><span>Landdai</span></h1>
                )}
                 <div className="LoginAccount__block">
                <img src={bigLogo} alt="logo" />
              </div>
            </>
          )}
          {page === 'main' && (
            <>
              {!isMobile && (
                <img
                  src={smallLogo}
                  alt="small-logo"
                  className="LoginAccount__white-logo"
                />
              )}
              <div className="LoginAccount__block LoginAccount__block--left">
                {!isMobile ? (
                  <h1 className="LoginAccount__title">Welcome to <span>Landdai</span></h1>
                ) : (
                  <h1 className="LoginAccount__title"><span>Landdai</span></h1>
                )}
                {!isMobile && (
                  <p className="LoginAccount__text">
                    The Future of Corporate Learning & Development.
                  </p>
                )}
                <div className="LoginAccount__google-btn">
                  <GoogleButton onClick={handleGoogle} />
                </div>
                <div className="LoginAccount__azure-btn">
                  <AzureButton onClick={handleAzure} />
                </div>
                <div className="LoginAccount__otp-btn">
                  <OTPButton onClick={navigateToOtp} />
                </div>
              </div>
              <div className="LoginAccount__block">
                <img src={bigLogo} alt="logo" />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
