import './WhiteButton.scss';
import classNames from 'classnames';

type Props = {
  text: string,
  action: any,
  img?: any
  className?: string,
}

export default function WhiteButton({text, img, action, className}: Props) {
  return (
    <div
      className={text === "Exported to One Drive" 
        ? "WhiteButton WhiteButton--active" 
        : `WhiteButton classNames ${className}`}
      onClick={action}
    >
      {img && <img src={img} alt="folder" />}
      {text}
    </div>
  )
}
