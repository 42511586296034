import React from 'react';
import './SmallLoader.scss';

const SmallLoader = () => {
  return (
    <div className="loader">
  <svg className="loader-path" width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.875 24.5009C42.8748 28.3813 41.6462 32.162 39.3652 35.3012C37.0843 38.4404 33.8681 40.7769 30.1776 41.9758C26.4871 43.1748 22.5118 43.1747 18.8214 41.9755C15.131 40.7763 11.9149 38.4396 9.63414 35.3003C7.35337 32.161 6.12497 28.3802 6.125 24.4999C6.12503 20.6195 7.35348 16.8387 9.6343 13.6995C11.9151 10.5602 15.1312 8.22352 18.8216 7.02439C22.5121 5.82526 26.4874 5.8252 30.1779 7.02424" />
  </svg>
</div>

  );
};

export default SmallLoader;
