import React from 'react';
import { ReactComponent as PlusIcon } from '../../../images/plus-white.svg';
import './SectionsButton.scss'

type Props = {
  action: (arg: any) => void,
  text: string,
  disable?: boolean,
  icon?: boolean,
  onHover?: (arg: any) => void,
  onLeave?: (arg: any) => void,
  styles?: React.CSSProperties
}

export default function SectionsButton({action, onHover, text, disable, icon, styles, onLeave}: Props) {
  return (
    <div
      className={`SectionsButton ${disable && 'SectionsButton--disable'}`}
      onClick={action}
      onMouseEnter={onHover} 
      onMouseLeave={onLeave}
      style={styles}
    >   
      {icon && <PlusIcon />}
      {text}
    </div>
  )
}
