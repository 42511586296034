import React, { useEffect, useRef, useState } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import classNames from 'classnames';
import './TextSlider.scss';

const content = [
  {
    title: 'Tip: Edit Text',
    text: 'To edit any text simply right click and the edit bar will appear. You will then be able to edit.',
  },
  {
    title: 'Tip: Go Deeper On A Topic',
    text: 'If you like the content that Landdai creates, but you want more on a specific aspect press on the “Generate More Content” and tell Landdai what type of content you want more of and it will be added to the bottom of the document.',
  },
  {
    title: 'Tip: Understanding Generative Ai & Faces',
    text: 'Landdai is still developing its ability to consistently construct realistic looking faces - so if a face in your photo looks deformed you can always regenerate a new photo.',
  },
  {
    title: 'Tip: Update entire pages, slides, or documents',
    text: 'If you want to either create a brand new page for your presentation OR redo a page both of those options are available on the bottom right hand side of the page.',
  },
];


export default function TextSlider() {
  const [activeDot, setActiveDot] = useState(0);

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    //@ts-ignore
    timeoutRef.current = setTimeout(
      () =>
        setActiveDot((prevIndex) =>
          prevIndex === content.length - 1 ? 0 : prevIndex + 1
        ),
      10000
    ); 
    return () => {
      resetTimeout();
    };
  }, [activeDot]);

  const handleArrowClick = (side: 'prev' | 'next') => {
    if (side === 'prev') {
      setActiveDot(prevIndex => prevIndex === 0 ? content.length - 1 : prevIndex - 1);
    } else {
      setActiveDot(prevIndex => prevIndex === content.length - 1 ? 0 : prevIndex + 1)
    }
  };

  return (
    <div
      className="TextSlider"
    >
      <div className="TextSlider__content">
        <div className="TextSlider__pseudo-content">
          {content?.map((el: any, i: number) => (
            <div
              key={i}
              className={classNames('TextSlider__element', {
                "TextSlider__element--active": activeDot === i
              })}
            >
              <h2>{el?.title}</h2>
              <p>{el?.text}</p>
            </div>
          ))}
        </div>       
      </div>
      <div
        className="TextSlider__arrows"
      >
        <div
          className="TextSlider__arrow"
          onClick={() => handleArrowClick('prev')}
        >
          <NavigateBeforeIcon
            style={{
              color: '#4F4F4F'
            }}
          />
        </div>
        <div
          className="TextSlider__arrow"
          onClick={() => handleArrowClick('next')}
        >
          <NavigateNextIcon
            style={{
              color: '#4F4F4F'
            }}
          />
        </div>
      </div>
    </div>
  );
};
