import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg';
import { ReactComponent as Key} from '../../../../../images/key-round.svg';
import { setCurrentPage } from '../../../../../redux/slices/coursesSlice';
import { useModules } from '../../../../../hooks/useModules';
import WhiteButton from '../../../../Buttons/WhiteButton/WhiteButton';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import './ModulesKeyTerms.scss';

export default function ModulesKeyTerms() {

  const dispatch = useDispatch<any>();
  const { loading } = useSelector((state: RootState) => state.courses);
  const { keyTerms} = useSelector((state: RootState) => state?.courses?.activeCourse) || {};
  const { enableStep, hideEnableNextStep } = useModules();
  const [hideModal, setHideModal] = useState<boolean>(false);
  const onCancel = () => {
    setHideModal(false); 
  }
  const onHide = () => {
    hideEnableNextStep('module-key-terms', 'module-education'); 
    setHideModal(false);
    dispatch(setCurrentPage('module-view'));
  }

  useEffect(() => {
    enableStep('module-education');
  }, []);
 
  return (
    <>
      {loading ? (
        <div className="ModulesKeyTerms">
          <div className="ModulesKeyTerms__container-loader">
          <CourseLoader />
        </div>
        </div>
      ) : (
        <div className="ModulesKeyTerms">
            {
              hideModal && (
                <CloseModal
                  type={'hide'}
                  submitText={'Yes'}
                  action={onHide}
                  text={'Would you like to hide selected tabs, making them no longer visible?'}
                  closeNoSave={onCancel}
                  close={()=> setHideModal(false)}
                  />
              )
            } 
          <div className="ModulesKeyTerms__container">
            <div className="ModulesKeyTerms__container__left" onClick={() => { 
                dispatch(setCurrentPage('module-view'))
              }}>
              <ArrowLeft/>
            </div>
            <div className="ModulesKeyTerms__container__right">
              <div className="ModulesKeyTerms__container__right__header">
                {/* <div className="ModulesKeyTerms__container__right__header__cont"> */}
                  <div className="ModulesKeyTerms__container__right__header__title">
                    There are {keyTerms?.length} key terms you should know and pay attention to:
                    </div>
                    <div className="ModulesKeyTerms__container__right__header__btns" >
                      <WhiteButton
                            text="Hide"
                            className='WhiteButton--p15-24'
                            action={() => {
                              setHideModal(true);
                            }}
                            />
                    </div>
                {/* </div> */}
              </div>
              <div className="ModulesKeyTerms__container__right__body">
                {
                  keyTerms?.map((term, index) => (
                    <div key={index}>
                      <div className="ModulesKeyTerms__container__right__body__term" >
                        <div className="ModulesKeyTerms__container__right__body__term__icon">
                          <Key/>
                        </div>
                        <div className="ModulesKeyTerms__container__right__body__term__block">
                          <div className="ModulesKeyTerms__container__right__body__term__block__title">
                            {term.title}
                          </div>
                          <div className="ModulesKeyTerms__container__right__body__term__block__description">
                            {term.description}
                          </div>
                        </div>
                      </div>
                      { 
                        index !== keyTerms.length -1 &&  
                          <div className="ModulesKeyTerms__container__right__body__line"/>
                      }
                         
                  </div>

                  ))
                }
                <div className="ModulesKeyTerms__container__right__body__btn-cont">
                  <SaveButton 
                      text='Finish'
                      action={() => {
                        dispatch(setCurrentPage('module-view'));
                      }}
                    />
                  </div>
                
              </div>

              
            
             
            </div>
            
            

          </div>

        </div>
        )
      }
    </>
  )
}
