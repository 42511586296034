export const plansLimited = [
  {
    title: "Free Trial",
    sub: "3 Presentations",
    priceText: "$0 for new users",
    priceYear: "$0 for new users",
    texts: [
      "Test Drive All Site Features",
      "Ai Generated Presentations",
      "Up To 5,000 Words",
      "Intuitive & User Friendly",
      "Create Presentations 10x Faster",
      "Great Customer Service",
      "100% Secure"
    ],
    btnText: "GET STARTED FREE",
    quantity: 1,
    priceId: 'price_1NfkPOGsLlGiOrc8ESV3aAuq',
    priceIdYearly: 'price_1NfkPOGsLlGiOrc8ESV3aAuq',
  },
  {
    title: "Professional Plan",
    sub: "50 presentations",
    priceYear: "$59/ month",
    priceText: "$79 / month",
    texts: [
      "Everything in the Individual plan Package Plus+",
      "50 Landai Generated Presentations a Month",
      "Unlimited Number of Words & Characters",
      "Unlimited Number of Ai Generated Photos",
      "Priority Beta Access to New Features",
      "LanddAiBot: On-Demand CorporateTrainerBot (coming soon)",
      "Employee Analytics App (coming soon)"
    ],
    btnText: "PROFESSIONAL PLAN",
    quantity: 1,
    priceId: 'price_1NWGItGsLlGiOrc8y4WGLbDM',
    priceIdYearly: 'price_1NDRKJGsLlGiOrc8LCNYHPle',
  },
  {
    title: "Individual Plan",
    sub: "10 presentations a month",
    priceText: "$39 / month",
    priceYear: "$29 / month",
    texts: [
      "10 Custom Generated Presentations ",
      "Dozens of Ai Templates for HR and L&D Functions",
      "Landdai Editor (Google Docs like editor)",
      "Ai Content Writer",
      "Ai Training Presentation Generator",
      "Ai Slide Deck Generator",
      "1-Click GoogleSuite Export"
    ],
    btnText: "INDIVIDUAL PLAN",
    quantity: 1,
    priceId: 'price_1NWGIqGsLlGiOrc8AZ9WBm9x',
    priceIdYearly: 'price_1NWGIbGsLlGiOrc8JuzLtgcv',
  }
]

export const plans = [
  {
    title: "Free Trial",
    sub: "3 Presentations",
    priceText: "$0 for new users",
    priceYear: "$0 for new users",
    texts: [
      "Test Drive All Site Features",
      "Ai Generated Presentations",
      "Up To 5,000 Words",
      "Intuitive & User Friendly",
      "Create Presentations 10x Faster",
      "Great Customer Service",
      "100% Secure"
    ],
    btnText: "GET STARTED FREE",
    quantity: 1,
    priceId: 'price_1NfkPOGsLlGiOrc8ESV3aAuq',
    priceIdYearly: 'price_1NfkPOGsLlGiOrc8ESV3aAuq',
    priceMob: 0,
    priceMobYear: 0
  },
  {
    title: "Professional Plan",
    sub: "50 presentations a month",
    priceYear: "$149 / month",
    priceText: "$199 / month",
    texts: [
      "Everything in the Individual plan Package Plus+",
      "50 Landai Generated Presentations a Month",
      "Unlimited Number of Words & Characters",
      "Unlimited Number of Ai Generated Photos",
      "Priority Beta Access to New Features",
      "LanddAiBot: On-Demand CorporateTrainerBot (coming soon)",
      "Employee Analytics App (coming soon)"
    ],
    btnText: "PROFESSIONAL PLAN",
    quantity: 1,
    priceId: 'price_1NWGItGsLlGiOrc8KIJ8L4Fn',
    priceIdYearly: 'price_1NfkVlGsLlGiOrc8gbRKGZEz',
    priceMob: 199,
    priceMobYear: 149
  },
  {
    title: "Individual Plan",
    sub: "10 presentations a month",
    priceText: "$99 / month",
    priceYear: "$74 / month",
    texts: [
      "10 Custom Generated Presentations ",
      "Dozens of Ai Templates for HR and L&D Functions",
      "Landdai Editor (Google Docs like editor)",
      "Ai Content Writer",
      "Ai Training Presentation Generator",
      "Ai Slide Deck Generator",
      "1-Click GoogleSuite Export"
    ],
    btnText: "INDIVIDUAL PLAN",
    quantity: 1,
    priceId: 'price_1NWGIqGsLlGiOrc8NvN5NJHK',
    priceIdYearly: 'price_1NfkY1GsLlGiOrc8eZFkZi4j',
    priceMob: 99,
    priceMobYear: 74
  }
]

