import React, { useState } from 'react';
import SectionsButton from '../../Buttons/SectionsButton/SectionsButton';
import './EducationSubSectionRename.scss'; 

interface EducationSubSectionRenameProps { 
    initialValue : string;
    onSave: (value: string) => void;
}

const EducationSubSectionRename: React.FC<EducationSubSectionRenameProps> = ({
    initialValue,
    onSave
  }) => {    
    const [value, setValue] = useState(initialValue);

  return (
    <div className={`EducationSubSectionRename`}>
        <textarea
           value={value}
           className='EducationSubSectionRename__input'
           onChange={e => { 
            setValue(e.target.value)
          }}
        />
        <div className='EducationSubSectionRename__btn-container'>
            <SectionsButton 
              disable={!value} 
              text='Save'
              action={ () => onSave(value)} />
        </div>
    </div>
  );
};

export default EducationSubSectionRename;
