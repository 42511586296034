import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg';
import { ReactComponent as CloseIcon } from '../../../../../images/x-circle.svg';
import { FlashCards, setCurrentPage } from '../../../../../redux/slices/coursesSlice';
import WhiteButton from '../../../../Buttons/WhiteButton/WhiteButton';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import { useModules } from '../../../../../hooks/useModules'; 
import './ModulesFlashCards.scss';

export default function ModulesFlashCards() {
  const timeoutRefs = useRef<Record<number, NodeJS.Timeout | null>>({});
  const { flashCards } = useSelector((state: RootState) => state?.courses?.activeCourse) || {};
  const [openCards, setOpenCards] = useState<Record<number, boolean>>({}); // State to keep track of open cards
  const [shouldDisplayNewContent, setShouldDisplayNewContent] = useState<Record<number, boolean>>({});
  const [shouldDisplayQuestion, setShouldDisplayQuestion] = useState<Record<number, boolean>>({});
  const { enableStep, hideEnableNextStep } = useModules();
  const [hideModal, setHideModal] = useState<boolean>(false);
  const onCancel = () => {
    setHideModal(false); 
  }
  const onHide = () => {
    hideEnableNextStep('module-flashcards', 'module-essay'); 
    setHideModal(false);
    dispatch(setCurrentPage('module-view'));
  }
  const dispatch = useDispatch<any>();
  const toggleCard = (index: number) => {
    const isOpening = !openCards[index];
  
    // Clear existing timeouts if they exist
    if (timeoutRefs.current[index]) {
      clearTimeout(timeoutRefs.current[index] as NodeJS.Timeout);
      timeoutRefs.current[index] = null; // Reset the timeout reference for the index
    }
  
    // Define behavior for opening and closing cards
    if (isOpening) {
      setOpenCards((prevOpenCards) => ({
        ...prevOpenCards,
        [index]: true,
      }));
      timeoutRefs.current[index] = setTimeout(() => {
        setShouldDisplayNewContent((prevShouldDisplayNewContent) => ({
          ...prevShouldDisplayNewContent,
          [index]: true,
        }));
      }, 300);
    } else {
      setOpenCards((prevOpenCards) => ({
        ...prevOpenCards,
        [index]: false,
      }));
      setShouldDisplayNewContent((prevShouldDisplayNewContent) => ({
        ...prevShouldDisplayNewContent,
        [index]: false,
      }));
      setShouldDisplayQuestion((prevShouldDisplayQuestion) => ({
        ...prevShouldDisplayQuestion,
        [index]: false,
      }));
  
      // Set a timeout to display the question again
      timeoutRefs.current[index] = setTimeout(() => {
        setShouldDisplayQuestion((prevShouldDisplayQuestion) => ({
          ...prevShouldDisplayQuestion,
          [index]: true,
        }));
      }, 300);
    }
  };

  useEffect(() => {
    enableStep('module-essay');
  }, []);

  const { loading } = useSelector((state: RootState) => state.courses);
  
  return (
    <>
      {loading ? (
        <div className="ModulesFlashCards">
          <div className="ModulesFlashCards__container-loader">
          <CourseLoader />
        </div>
        </div>
      ) : (
        <div className="ModulesFlashCards">
           {
              hideModal && (
                <CloseModal
                  type={'hide'}
                  submitText={'Yes'}
                  action={onHide}
                  text={'Would you like to hide selected tabs, making them no longer visible?'}
                  closeNoSave={onCancel}
                  close={()=> setHideModal(false)}
                  />
              )
            } 
          <div className="ModulesFlashCards__container">
            <div className="ModulesFlashCards__container__left"
              onClick={()=>{
                dispatch(setCurrentPage('module-view'));
              }}
              >
              <ArrowLeft/>
            </div>
            <div className="ModulesFlashCards__container__right">
              <div className="ModulesFlashCards__container__right__header">
                <div className="ModulesFlashCards__container__right__header__title">
                  Pick a flashcard and attempt to answer the question.
                </div>
               
              </div>
              <div className="ModulesFlashCards__container__right__header__btns" >
                  <WhiteButton
                        text="Hide"
                        className='WhiteButton--p15-24'
                        action={async () => {
                          setHideModal(true); 
                        }}
                        />
                </div>
              <div className="ModulesFlashCards__container__right__body">
              {
                  flashCards?.map((term: FlashCards, index: number) => {
                    if(index < 8){
                      const isOpen = openCards[index];
                      const displayNewContent = shouldDisplayNewContent[index];
                      const displayQuestion = shouldDisplayQuestion[index] !== false;  // Default to true if not set
                      return (
                        <div className='ModulesFlashCards__container__right__body__container' key={index}>
                          <div 
                          className={`ModulesFlashCards__container__right__body__card ${isOpen ? 'ModulesFlashCards__container__right__body__card__opened' : 'ModulesFlashCards__container__right__body__card__closed'}`}
                          key={index}
                          >
                            {!isOpen && displayQuestion && (
                              <div className="ModulesFlashCards__container__right__body__card__closed__question">
                                {term.question}
                              </div>
                            )}
                            {displayNewContent && (
                              <div className="ModulesFlashCards__container__right__body__card__closed__answer">
                                {term.answer}
                              </div>
                            )}
                            <div className="ModulesFlashCards__container__right__body__card__closed__btn">
                              {
                                isOpen ? (
                                  displayNewContent && <div className="ModulesFlashCards__container__right__body__card__closed__btn__icon">
                                    <CloseIcon
                                    onClick={() => toggleCard(index)} />
                                  </div>
                                ) : (
                                  displayQuestion && <WhiteButton
                                    className='WhiteButton__flashcard'
                                    text={isOpen ? 'Hide answer' : 'Get answer'}
                                    action={() => toggleCard(index)}
                                  />
                                )
                              }
                            </div>
                        </div>
                       
                        </div>
                      );
                    }
                  })
              }
            
              </div>
                <SaveButton 
                    text='Finish'
                    styles={{marginTop: '40px',marginBottom: '40px', alignSelf: 'center', width: '94px'}}
                    action={() => {
                      dispatch(setCurrentPage('module-view'));
                    }}
                  />
            </div>

          </div>

        </div>
        )
      }
    </>
  )
}

