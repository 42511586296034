import GoogleButton from '../../Buttons/GoogleButton/GoogleButton'; 
import { setCookie } from '../../../utils/CookieHelper';
import CloseIcon from '@mui/icons-material/Close';
import './GoogleLoginModal.scss';

type Props = { 
  close: any
};

export default function GoogleLoginModal({ 
  close 
  }: Props) { 


    const handleGoogle = () => {  
        setCookie('path', `${process.env.REACT_APP_API_URL}${window.location.pathname.slice(1)}`, {});
        window.location.href =  `${process.env.REACT_APP_API_URL}api/auth`; 
    };

  return (
    <div className="GoogleLoginModal">
      <div className="GoogleLoginModal__wrapper">
        <div
          className="GoogleLoginModal__close"
          onClick={close}
        >
          <CloseIcon
            sx={{
              color: '#5A6861'
            }}
          />
        </div>
        <h2 className="GoogleLoginModal__title">Authorization Required</h2>
        <p>To proceed, please authorize with Google so that we can export your files. 
          If you skip this step, your files will not be exported.
          </p>
        <div className="GoogleLoginModal__btn">
        <GoogleButton onClick={() => handleGoogle()} />
           
        </div>
      </div>
    </div>
  )
}
