import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as DeleteIcon } from '../../../images/sections-x.svg';
import './SectionRename.scss';

interface SectionRenameProps {
    initialValue: string;
    onSave: (value: string) => void;
    editing: boolean;
    setEditing: () => void;
    onDelete?: () => void;
    extraClass?: string;
}

const SectionRename: React.FC<SectionRenameProps> = ({ 
  initialValue, editing, setEditing, onSave, onDelete, extraClass
 }) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null); 

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]); 

  const handleSave = () => { 
    onSave(value);
    setEditing();
  };

  return (
    <div className={`SectionRename ${extraClass ? extraClass : ''}`}>
      {editing ? (
        <input
          ref={inputRef} 
          className="SectionRename__input"
          type="text"
          value={value}
          onBlur={handleSave}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      ) : (
        <>
        <div
          onClick={() => {
            setEditing();
          }}
        >
          {value}
          <div
            className="SectionRename__icon"
          >
          </div>
        </div>
        <DeleteIcon style={{marginBottom: "4px"}} onClick={onDelete} />
         
        </>
      )}

    </div>
  );
}
export default SectionRename;
