import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton'; 
import './ShareSaveAssesment.scss';

type Props = {
  close: (arg: boolean) => void
};

export default function ShareSaveAssesment({
  
  close }: Props) {

  return (
    <div className="ShareSaveAssesment">
      <div className="ShareSaveAssesment__wrapper">
        <p>
            On your <span>Assessment Report</span>, please scroll down and click
             the <span>'Submit'</span> button to ensure your results are recorded 
             and sent. Otherwise, your results will not be saved or sent.
        </p>
        <div className="ShareSaveAssesment__btns">
          <ConfirmButton
            text={'OK'}
            action={close}            
          />
        </div>
      </div>
    </div>
  )
}

