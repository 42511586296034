import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
   removeSimulationId,
   removeSimulation
  } from '../../api/shareApi';
import { RootState } from '../store';
import { menu } from '../../data/modules';
 
type QuizAnswer =  
{
  
  type: "right_options" | "true_false" | "fill_blank" | "matching";
  content: Assessment_RightOptions | Assessment_TrueFalse | Assessment_FillBlank | Assessment_Matching[] | any;

}[]

type Evaluation = string[];

type QuizDataAnswer = {
  quiz: QuizAnswer | null;
  evaluate: Evaluation | null;
}

type Quiz = {
      type: "right_options" | "true_false" | "fill_blank" | "matching";
      content: Assessment_RightOptions | Assessment_TrueFalse | Assessment_FillBlank | Assessment_Matching[] | any;
  }[] 

type EvaluationAnswers = Record<number, string>;

type Assessment_RightOptions = {
  question: string;
  options: string[];
  answer: number[];
  userAnswer: number[];
}

type Assessment_TrueFalse = {
  question: string;
  answer: boolean;
  userAnswer: boolean;
}

type Assessment_FillBlank = {
  question: string;
  options: string[];
  answer: number;
  userAnswer: number;
}

type Assessment_Matching = {
  statement: string;
  match: string;
  userAnswer: string;
}[]
export interface FlashCards {
  question: string;
  answer: string;
}
export interface KeyTerms {
  title: string;
  description: string;
}

interface AssessmentReport {
  quiz: string;
  statements_correct: string;
  strong: string[];
  improve: string[];
}

export interface EducationContent {
  id?: number;
  agenda?: string[];
  sections?: Section[];
  summary?: string;
  videoURL?: string | null | undefined;
  headerImage?: string | null;
}

interface Section {
  title: string;
  description: string;
  sub_sections: Block[];
}

interface Block {
  title: string;
  description: string;
  image: string;
}

interface EssayData{
  questions: string[];
  answers?: {
      question: string;
      answer: string;
  }[];
  result?: string;
}

interface AiSimulationRoom {
  mentoring: {
    type: string;
    id: number;
    created_at: string;
    audioURL?: string;
  },
  story: {
    type: string;
    id: number;
    created_at: string;
  },
  student: {
    type: string;
    id: number;
    created_at: string;
  }
}

interface MenuItem {
  name: string;
  page: string;
  disabled: boolean;
  hidden: boolean;
}

export interface Course {
  id?: number;
  title: string;
  created_at?: string;
  sections: string[];
  steps: Step[];
  evaluationAnswers?: EvaluationAnswers; // Optional property for evaluation
  keyTerms?: KeyTerms[]; // Optional property for key terms
  quiz?: Quiz; // Optional property for quiz
  evaluation?: string[]; // Optional property for evaluation
  flashCards?: FlashCards[]; // Define a suitable type if possible
  essay?: EssayData; // Define a suitable type if possible
  educationalContent?: EducationContent; // Define a suitable type if possible
  aiSumulationRoom?: AiSimulationRoom;
  assessmentReport?: AssessmentReport; // Define a suitable type if possible
  summarize?: string; // Define a suitable type if possible
  answers?: QuizDataAnswer; // Define a suitable type if possible
  indexKey?: string; // Define a suitable type if possible
  educationVideo?: string | null | undefined;
  menu?: MenuItem[];
  
}
 


export interface Step {
  type: string;
  [key: string]: any;  
}


export type CurrentSharedPageType = 
    'shared-login' |
    'shared-unavaliable' |
    'shared-success' |
    'shared-general' |
    'shared-checkpoints' |
    'shared-view' |
    'shared-evaluation' |
    'shared-key-terms' |
    'shared-flashcards' |
    'shared-education' |
    'shared-essay' |
    'shared-ai-sim' |
    'shared-quiz'  |
    'shared-report' |
    'shared-thanks' |
    'shared-ai-simulation' |'module-general' | 'module-checkpoints' | 'module-view' | 'module-evaluation' | 'module-key-terms' | 'module-flashcards' | 'module-education' | 'module-essay' | 'module-ai-sim' | 'module-quiz' | 'module-report' | 'module-ai-simulation' | 'module-quiz-edit' | 'module-thanks';

  export interface CoursesState {
    courses: Course[];
    activeCourse: Course | null;
    currentPage: CurrentSharedPageType;
    currentTab: string;
    loading: boolean;
    educationLoading: boolean;
    loadingPage: boolean;
    shareUserData: ShareUserData;
    error: string;
    courseAnswers: QuizDataAnswer;  
  }

  export interface ShareUserData {
    shareId: number;
    email: string;
    name: string;
  };

  const initialState: CoursesState = {
    courses: [],
    currentPage: 'shared-login',
    currentTab: 'main',
    activeCourse: null,
    loading: false,
    educationLoading: false,
    loadingPage: false,
    shareUserData: {
        shareId: 0,
        email: '',
        name: ''
    },
    courseAnswers: {
      quiz:  null,
      evaluate:  null
    },
    error: ''
 
  };


  function prepareCourse(rawCourse: any): Course {
    const parsedData = JSON.parse(rawCourse.data);
 
    return {
      id: rawCourse.id,
      title: parsedData.title,
      created_at: rawCourse.created_at,
      sections: parsedData.sections,
      steps: parsedData.steps,
      keyTerms: parsedData.keyTerms,
      quiz: cleanUpQuiz(parsedData.quiz),
      essay: cleanupEssay(parsedData.essay),
      evaluation: parsedData.evaluation,
      educationalContent: parsedData.educationalContent,
      flashCards: parsedData.flashCards,
      summarize: rawCourse.summarize,
      answers: parsedData.answers,
      indexKey: parsedData.indexKey,
      aiSumulationRoom: parsedData.aiSumulationRoom,
      educationVideo: processVideoURL(parsedData),
      menu: processMenu(parsedData)
    };
  }
  function processVideoURL(data : any ) {
    if(data?.educationalContent && data?.educationalContent?.videoURL) {
      return data?.educationalContent?.videoURL;
    } 
    if(data?.educationVideo) {
      return data?.educationVideo;
    }
    if(data?.educationalContent?.videoURL  === null) { 
      return null;
    }
    return ''
     
  }

  function cleanupEssay (essay: EssayData) {
    return {
      questions: essay.questions,
    };
  }

  function cleanUpQuiz(quiz: Quiz) {
    return quiz.map((item) => {
      if (item.type === 'right_options') {
        return {
          type: item.type,
          content: {
            question: item.content.question,
            options: item.content.options,
            answer: item.content.answer
          }
        };
      }
  
      if (item.type === 'true_false') {
        return {
          type: item.type,
          content: {
            question: item.content.question,
            answer: item.content.answer
          }
        };
      }
  
      if (item.type === 'fill_blank') {
        return {
          type: item.type,
          content: {
            question: item.content.question,
            options: item.content.options,
            answer: item.content.answer
          }
        };
      }
  
      if (item.type === 'matching') {
        return {
          type: item.type,
          content: item.content.map((match: any) => {
            return {
              statement: match.statement,
              match: match.match
            };
          })
        };
      }
  
      return item;
    });
  }

  const convertModuleToShared = (menuItems: MenuItem[]) => {
    return menuItems.map(item => {
      const updatedItem = item.page.startsWith('module') ? 
        { ...item, page: item.page.replace('module', 'shared') } : 
        { ...item };

      //TODO - Enable this module after the implementation of the AI Simulation Room new version
      if(updatedItem.page === 'shared-ai-simulation') {
        updatedItem.hidden = true;
      }
  
      // Set disabled to true for all except Evaluation
      if (updatedItem.name !== 'Evaluation') {
        updatedItem.disabled = true;
      }
  
      return updatedItem;
    });
  };
 
  function processMenu(course: Course): MenuItem[] {
      const newMenu = course?.menu?.map((item) => {
        if(item.name === 'Ai Simulation Room') {
          return {
            ...item,
            name: 'Ai Simulation'
          }
        }
        return item;
      }); 

      return convertModuleToShared(newMenu || menu);
      
  }

   
  export const removeSimulationAll = createAsyncThunk(
    'shared/removeSimulationAll', // Be sure to provide a unique action string
    async (_, thunkAPI) => {
      try {
        const state = thunkAPI.getState() as RootState; // Assuming you have a RootState type that represents your entire Redux state
        const { activeCourse } = state.shared; // Assuming your course state is stored under `courses` and contains `activeCourse`
  
        if (!activeCourse || !activeCourse.aiSumulationRoom) {
          // Handle the case where there is no active course or aiSimulationRoom is not defined
          return thunkAPI.rejectWithValue("No active simulation room data available");
        }
  
        const { mentoring, story, student } = activeCourse.aiSumulationRoom;
  
        const simulationIds = {
          mentoring: mentoring.id,
          story: story.id,
          student: student.id,
        };
  
        const response = await removeSimulation(simulationIds);
  
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue([]);
      }
    }
  );
  

  export const removeSimulationById = createAsyncThunk(
    'shared/removeSimulationById', // Be sure to provide a unique action string
    async ({id} : { id: number | undefined}, thunkAPI) => {
      try {           
        const response = await removeSimulationId( id);
        
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue([]);
      }
    });


  
  export const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
      setCurrentPage: (state, action: PayloadAction<CurrentSharedPageType>) => {
        state.currentPage = action.payload;
      },
      setCurrentTab: (state, action: PayloadAction<string>) => {
        state.currentTab = action.payload;
      },
      setLoading: (state, action: PayloadAction<boolean>) => {
        state.loading = action.payload;
      },
      setLoadingPage: (state, action: PayloadAction<boolean>) => {
        state.loadingPage = action.payload;
      },
      setEducationLoading : (state, action: PayloadAction<boolean>) => {
        state.educationLoading = action.payload;
      },
      setActiveCourse: (state, action: PayloadAction<any>) => { 
        state.activeCourse = {
            ...action.payload,
            ...prepareCourse(action.payload.education),
            // educationVideo: processVideoURL(action.payload.education),
        };
      },
      updateShareUserData: (state, action: PayloadAction<ShareUserData>) => {
        console.log(action.payload)
        state.shareUserData = action.payload;
      },
      
      setEducationalContent: (state, action: PayloadAction<EducationContent>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            educationalContent: action.payload
          };
        }
      },
      setQuiz: (state, action: PayloadAction<Quiz>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            quiz: action.payload
          };
        }
      },
      setMenuState: (state, action: PayloadAction<MenuItem[]>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            menu: action.payload
          };
        }
      }, 
      setSetEducationVideo: (state, action: PayloadAction<string | null>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            educationVideo: action.payload
          };
        }
      },
      clearEducationVideo: (state, action: PayloadAction<string | null>) => {
        if(state.activeCourse) { 
          state.activeCourse.educationalContent = {
            ...state.activeCourse.educationalContent,
            videoURL: null
          };
        }
      },
      updateAssessmentReport: (state, action: PayloadAction<AssessmentReport>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            assessmentReport: {
              ...state.activeCourse.assessmentReport,
              ...action.payload
            }
          };
        }
      },
      clearData: (state) => {
        state.activeCourse = null;
        state.courseAnswers = {
          quiz: null,
          evaluate: null
        };
      },
      updateMentoringURL: (state, action: PayloadAction<string>) => {
        if (state.activeCourse && state.activeCourse.aiSumulationRoom) {
          state.activeCourse.aiSumulationRoom = {
            ...state.activeCourse.aiSumulationRoom,
            mentoring: {
              ...state.activeCourse.aiSumulationRoom?.mentoring,
              audioURL: action.payload
            }
          };
        }
      },
      updateEvaluationAnswers: (state, action: PayloadAction<EvaluationAnswers>) => {
        if (state.activeCourse) {
          state.activeCourse.evaluationAnswers = action.payload;
        }
      },  
      updateEssayAnswers: (state, action: PayloadAction<Partial<EssayData>>) => {
        if (state.activeCourse && state.activeCourse.essay) {
           
          state.activeCourse.essay.answers = action.payload.answers || [];
          if(action.payload.result){
            state.activeCourse.essay.result = action.payload.result;
          }
          
        }
      },         
      updateCourseAnswers: (state, action: PayloadAction<Partial<QuizDataAnswer>>) => {
        if (action.payload.quiz) {
          state.courseAnswers = {
            ...state.courseAnswers,
            quiz: [ ...action.payload.quiz ],
          };
        }
      
        if (action.payload.evaluate) {
          state.courseAnswers = {
            ...state.courseAnswers,
            evaluate: [...action.payload.evaluate],
          };
        }
      }, 
      resetState: () => initialState

      
      
    } 
  });
  
  export const { 
    setCurrentPage,
    setCurrentTab,
    setQuiz,
    setActiveCourse,
    setMenuState,
    setEducationalContent,
    setLoading, 
    setEducationLoading,
    setSetEducationVideo, 
    setLoadingPage,  
    updateCourseAnswers,
    updateShareUserData,
    updateAssessmentReport,
    updateEvaluationAnswers,
    updateMentoringURL,
    updateEssayAnswers,
    clearData,
    resetState
  } = sharedSlice.actions;
 
  export default sharedSlice.reducer;