import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../redux/store'; 
import ModulesCreate from './ModulesContent/ModulesCreate/ModulesCreate';
import ModulesEvaluate from './ModulesContent/ModulesEvaluate/ModulesEvaluate';
import ModulesFlashCards from './ModulesContent/ModulesFlashCards/ModulesFlashCards';
import ModulesKeyTerms from './ModulesContent/ModulesKeyTerms/ModulesKeyTerms'; 
import ModulesQuiz from './ModulesContent/ModulesQuiz/ModulesQuiz';
import ModulesView from './ModulesContent/ModulesView/ModulesView';
import ModulesReport from './ModulesContent/ModulesReport/ModulesReport';
import ModulesCheckPoints from './ModulesContent/ModulesCheckpoints/ModulesCheckPoints';
import ModulesEducation from './ModulesContent/ModulesEducation/ModulesEducation';
import ModulesEssay from './ModulesContent/ModulesEssay/ModulesEssay';
import ModulesAiSimulation from './ModulesContent/ModulesAiSimulation/ModulesAiSimulation';
import ModulesQuizEdit from './ModulesContent/ModulesQuizEdit/ModulesQuizEdit';
import Loader from '../../Loader/Loader';
import { useSocket } from '../../../App';
import { getCookie } from '../../../utils/CookieHelper';
import { getUserInfo } from '../../../redux/slices/scriptsSlice';
import {  setEducationalContent, setSetEducationVideo } from '../../../redux/slices/coursesSlice';

import './ModulesSection.scss';

export default function ModulesSection() {
  const { loading, currentPage, activeCourse } = useSelector((state: RootState) => state.courses);
  const newSocket = useSocket();
  const dispatch = useDispatch<AppDispatch>();
  const token = getCookie('jwtToken');
  useEffect(() => {
    token && dispatch(getUserInfo(token));
  }, [token]);

  useEffect(() => { 
    if (newSocket && 
        activeCourse?.educationalContent?.id &&
        (!activeCourse?.educationalContent?.sections   )
          // || activeCourse?.educationVideo === '' )
        ) {
      newSocket?.emit('education:content', {
        id: activeCourse?.educationalContent?.id,
      });

      newSocket?.on("education:content:end", (data: any) => {
        if( data.id === activeCourse?.educationalContent?.id)
          dispatch(setEducationalContent(data));
        if( data.id === activeCourse?.educationalContent?.id && data.videoURL)
          dispatch(setSetEducationVideo(data.videoURL));
      });
      newSocket?.on('education:content:end:content', (data: any) => {
        if( data.id === activeCourse?.educationalContent?.id && !activeCourse?.educationalContent?.sections)
          dispatch(setEducationalContent(data));
        if( data.id === activeCourse?.educationalContent?.id && data.videoURL)
          dispatch(setSetEducationVideo(data.videoURL));
      });
      // newSocket?.on('education:content:end:video', (data: any) => {
      //   if( data.id === activeCourse?.educationalContent?.id)
      //     dispatch(setSetEducationVideo(data.videoURL));
      // });
    }

    return () => {
      newSocket?.off('education:content:end');
      newSocket?.off('education:content:end:content');
      // newSocket?.off('education:content:end:video');
    }
 
  }, [newSocket, activeCourse?.educationalContent?.id]);



  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="ModulesSection">
          {
            currentPage === 'module-general' && (
              <ModulesCreate />
            )
          }
           {
            currentPage === 'module-checkpoints' && (
              <ModulesCheckPoints />
            )
          }
          {
            currentPage === 'module-view' && (
              <ModulesView />
            )
          } 
          {
            currentPage === 'module-evaluation' && (
              <ModulesEvaluate />
            )
          } 
          {
            currentPage === 'module-key-terms' && (
              <ModulesKeyTerms />
            )
          } 
          {
            currentPage === 'module-flashcards' && (
              <ModulesFlashCards />
            )
          }
          {
            currentPage === 'module-thanks' && (
              <ModulesEvaluate />
            )
          }
          {
            currentPage === 'module-education' && (
              <ModulesEducation />
            )
          }
          {
            currentPage === 'module-quiz' && (
              <ModulesQuiz />
            )
          }
          {
            currentPage === 'module-report' && (
              <ModulesReport />
            )
          }
          {
            currentPage === 'module-essay' && (
              <ModulesEssay />
            )
          }
          {
            currentPage === 'module-ai-simulation' && (
              <ModulesAiSimulation />
            )
          }
          {
            currentPage === 'module-quiz-edit' && (
              <ModulesQuizEdit />
            )
          }

        </div>
        )
      }
    </>
  )
}

