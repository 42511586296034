
import './EducationCreateSectionInput.scss'

interface QuizEditInputProps {
    placeholder?: string;
    text: string;
    setText: (text: string) => void;
    customStyles?: React.CSSProperties;
}




export default function EducationCreateSectionInput ({placeholder, text, setText, customStyles} : QuizEditInputProps){

    return (
        <>
            <input
             type="text"
             placeholder={placeholder}
             className="education-create-section-input" 
             value={text} 
             onChange={(e) => setText(e.target.value)}
             style={customStyles}
             />
        </>
    )

}