import { ReactComponent as CoursesLamp } from '../../../images/courses-lamp.svg';
import CloseIcon from '@mui/icons-material/Close';
import './CoursesAlert.scss';

export default function CoursesAlert({ page, close }: { page: string, close: () => void }) {
  return (
    <div className="CoursesAlert">
      <div className="CoursesAlert__wrapper">
      <div
          className="CoursesAlert__close"
          onClick={close}
        >
          <CloseIcon
            sx={{
              color: '#5A6861'
            }}
          />
        </div>
        <div className="CoursesAlert__image">
          <CoursesLamp />
        </div> 
        <div className="CoursesAlert__text-bottom">
          { page === 'quiz' ? 'Please ensure you complete the Evaluation to view your results.' :
           'Please ensure you complete the Evaluation and Quiz to view your results. Your assessment will be available once you finish.' }
        
        </div>

      </div>
    </div>
  )
}
