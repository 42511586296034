import { useEffect, useState, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../../../../../utils/CookieHelper'; 
import { RootState } from '../../../../../../redux/store';
import { setCurrentTab, removeSimulationById, setCurrentPage, updateMentoringURL } from '../../../../../../redux/slices/coursesSlice';
import { useSocket } from '../../../../../../App';
import AiSimModal from '../../../../../Modals/AiSimModal/AiSimModal';
import CourseLoader from '../../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as StopIcon } from '../../../../../../images/mentoring-play.svg';
import { ReactComponent as MicActive } from '../../../../../../images/mentoring-mic-active.svg';
import { ReactComponent as MicDisable } from '../../../../../../images/mentoring-mic-disable.svg'; 
import { ReactComponent as MicListening } from '../../../../../../images/mentoring-mic-listening.svg';
import WhiteButton from '../../../../../Buttons/WhiteButton/WhiteButton';
import { useModules } from '../../../../../../hooks/useModules';
import CloseModal from '../../../../../Modals/CloseModal/CloseModal';
import SaveButton from '../../../../../Buttons/SaveButton/SaveButton';
import './ModulesAiSimulationMentoring.scss';

export default function ModulesAiSimulationMentoring() {
  const [loadingResponse, setLoadingResponse] = useState(true);
  const [audioLink, setAudioLink] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const token = getCookie('jwtToken');
  const dispatch = useDispatch<any>();
  const { transcript, resetTranscript, browserSupportsSpeechRecognition, listening } = useSpeechRecognition();
  const [micState, setMicState] = useState('loading'); // 'ready', 'listening', 'loading', 'playing'
  const audioRef = useRef(new Audio(audioLink)); // Create a ref for the audio element
  const { summarize, indexKey, aiSumulationRoom  } = useSelector((state: RootState) => state?.courses?.activeCourse) || {}; 
  const {  currentTab  } = useSelector((state: RootState) => state.courses);
  const { enableStep, hideEnableNextStep } = useModules();
  const [hideModal, setHideModal] = useState<boolean>(false);
  const newSocket = useSocket();

  const startListening = () => {
    resetTranscript();
    setMicState('listening');
    SpeechRecognition.startListening({ continuous: true });
  };

  useEffect(() => {
    if (listening) {
      setMicState('listening');
    // } 
    // else if (micState === 'listening' && !listening && transcript) {
    //   stopListeningAndLoad()
    } else if (micState === 'listening' && !listening && !transcript) {
      startListening()
    }
  }, [listening]);

  useEffect(() => {
    if (micState === 'listening') {
      startListening();
    }
  }, [micState]);

  useEffect(() => {
    // Function to stop the audio and reset the state
    const stopAudio = () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setMicState('ready');
    };
    // Run the function when the 'currentTab' changes to 'main'
    if (currentTab === 'main') {
      stopAudio();
    }
    // Cleanup function that runs when the component is unmounted
    return () => {
      stopAudio();
    };
  }, [currentTab]);
  

  const initialPrompt = () => {
    if(!newSocket) return;
    if(aiSumulationRoom?.mentoring?.audioURL) {
      setAudioLink(aiSumulationRoom?.mentoring?.audioURL);
      setLoadingResponse(false);
      setMicState('playing');
      return;
    } 
    setTimeout(() => {
      setLoadingResponse(true);
      newSocket?.emit("simulation:query", {
        chatId: aiSumulationRoom?.mentoring?.id,
        type: 'MENTORING',
        query: 'Give me a situation which should be resolved. Situation should be based on summary. ',
        indexKey: indexKey,
        summarize: summarize
      });

      newSocket?.on("simulation:query:end", (data: any) => {
          setLoadingResponse(false);
          newSocket?.removeListener("simulation:query:end");  
          audioRef.current.src = '';
          setAudioLink(data?.answer);
          dispatch(updateMentoringURL(data?.answer || ''));
          // Transition to 'playing' state after the 'audioLink' state is updated
          setMicState('playing');
           
        });
      
    }, 500);
  }

  useEffect(() => {
   
    initialPrompt();

  }, []);

  const stopListeningAndLoad = () => {
    SpeechRecognition.stopListening();
    setMicState('loading');
    if(!newSocket) return;
    // if(
    //   transcript === "let's finish thank you" ||
    //   transcript === "let's finish, thank you" ||
    //   transcript === "let's finish thanks" 
    //   ) {
    //   setModalOpen(true);
    //   setMicState('loading');
    // } else{
      newSocket?.emit("simulation:query", {
        chatId: aiSumulationRoom?.mentoring?.id,
        type: 'MENTORING',
        query: transcript,
        indexKey: indexKey,
        summarize: summarize
      });
  
  
      newSocket?.on("simulation:query:end", (data: any) => {
        newSocket?.removeListener("simulation:query:end");  
        audioRef.current.src = '';
        setAudioLink(data?.answer);
        setMicState('playing');
         
      });

    // }
 
  };

  // Inside the useEffect hook that has the audio play logic
  useEffect(() => {
    // Only update src if it's a new source
    if (audioRef.current.src !== audioLink) {
      audioRef.current.src = audioLink;
      audioRef.current.load();
      if (micState === 'playing') {
        audioRef.current.play().catch(error => {
          setMicState('ready');
        });
      }
    }
  }, [audioLink, micState]); 
  
  // Dependency on the audioLink state

  const handleMicClick = () => {
    switch (micState) {
      case 'ready':
        startListening();
        break;
      case 'listening':
        stopListeningAndLoad();
        break;
      case 'loading':
        // Handle loading state click if needed
        break;
      case 'playing':
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setMicState('ready');
        break;
    }
  }

  const generateText = (text: string) => {
    switch (text) {
      case 'ready':
        return 'Click on the microphone to speak';
      case 'listening':
        return ( <>
        <div>Click once again to send the responce to Landdai</div>
        {/* <div>You can finish your dialog anytime with stop phrase: <span>"Let's finish, thank you"</span></div> */}
        </>);
      case 'loading':
        return 'Wait';
      case 'playing':
        return 'Listen';
    }
  }


  // This effect handles changes in micState
  useEffect(() => {
    const handleAudioEnd = () => {
      setMicState('ready');
    };
    // Get the current audio element and add an event listener
    const audio = audioRef.current;
    audio.addEventListener('ended', handleAudioEnd);
    // Cleanup function to remove event listener
    return () => {
      audio.removeEventListener('ended', handleAudioEnd);
    };
  }, [micState]); // Empty array because we only need to set and clean this up once
 
 
  const clearHistory = async () => {  
    resetTranscript();
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setMicState('ready');
    await dispatch(removeSimulationById({ token: token, id: aiSumulationRoom?.mentoring?.id}));
    initialPrompt();
  };
 
  const onSave = () => {
    dispatch(setCurrentPage('module-view'));
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  const onHide = () => {
    hideEnableNextStep('module-ai-simulation', 'module-quiz'); 
    setHideModal(false);
    dispatch(setCurrentPage('module-view'));
  }

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div className='ModulesAiSimulationMentoring'>
      {
            hideModal && (
              <CloseModal
                type={'hide'}
                submitText={'Yes'}
                action={onHide}
                text={'Would you like to hide selected tabs, making them no longer visible?'}
                closeNoSave={()=> setHideModal(false)}
                close={()=> setHideModal(false)}
                />
            )
          } 
      <div className='ModulesAiSimulationMentoring__container'>
        {
          loadingResponse ? (
            <div className="ModulesAiSimulation__container-loader">
              <CourseLoader 
                customText='Question is loading...'
                />
            </div>
          ) : (
            <>
                <div className='ModulesAiSimulationMentoring__container__header'>
                <div className='ModulesAiSimulationMentoring__container__header__text'>
                  Mentoring
                </div>
                <div className='ModulesAiSimulationMentoring__container__header__button'>
                <WhiteButton
                    text="Hide"
                    className='WhiteButton--p15-24'
                    action={() => {
                      setHideModal(true);
                    }}
                    />
                     
                        <SaveButton
                         
                        text="Start over"
                        action={() => {
                          clearHistory();
                        }
                      }
                      />
                    
                  
                </div>
                
              
              </div>
              <div className='ModulesAiSimulationMentoring__container__body'>
                
              {
                  modalOpen && (
                    <AiSimModal
                      action={onSave}
                      submitText={"Yes"}
                      text={"Are you sure you want to exit?"}
                      closeNoSave={onCancel}
                      close={() => setModalOpen(false)}

                      />
                  )

                }
                
                <div className='ModulesAiSimulationMentoring__container__body__text-info'>
                  {generateText(micState)} 
                </div>
                <div className='ModulesAiSimulationMentoring__container__body__icon-wrapper'>
                  <div className={`ModulesAiSimulationMentoring__container__body__icon-wrapper__inner ${micState === 'listening' && 'bigger-inner'} `} onClick={handleMicClick}>
                    {micState === 'ready' &&  <MicActive />  }
                    {micState === 'listening' &&  <MicListening />}
                    {micState === 'loading' && <MicDisable /> }
                    {micState === 'playing' && <StopIcon /> }
                  </div>   
                </div> 
                {/* <div className='ModulesAiSimulationMentoring__container__body__text'>
                  You can finish your dialog anytime with stop phrase <span>"Let's finish, thank you"</span>
                </div> */}
              </div>
              <div className="ModulesAiSimulationMentoring__container__body__bottom-btns">
                    <SaveButton
                        text="Finish"
                        action={() => { 
                          setModalOpen(true)
                      }}
                        />
                  </div>
              </>
          )
        }
      </div>
    </div>
  )
}
