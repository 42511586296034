import React from 'react'; 
import './CustomColorButton.scss'; // Ensure that the file name is correct

interface CustomColorButtonProps {
  text: string,
  onClick: () => void,
  disabled?: boolean,
  backgroundColor?: string,
  textColor?: string,
  customStyle?: React.CSSProperties,
}

function CustomColorButton({ 
    text,
    onClick, 
    disabled = false,
    backgroundColor = '#D7C8C8', // provide a default color if not passed
    textColor = '#D61919' , // provide a default text color if not passed
    customStyle
}: CustomColorButtonProps) {
  return (
    <button
      className="CustomColorButton"
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        ...customStyle
      }}
      onClick={onClick}
      disabled={disabled}>
       
      <span className="CustomColorButton__text">
        {text}
      </span>
    </button>
  );
}

export default CustomColorButton;
