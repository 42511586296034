import React from 'react';

interface CirclePercentageProps {
  percentage: string;
}

const CirclePercentage: React.FC<CirclePercentageProps> = ({ percentage: percentageString }) => {
  const percentageValue = parseInt(percentageString, 10); // Convert the string to an integer

  const cx = 134.499;
  const cy = 107.531;
  const r = 99;
  const startAngle = -90;
  const endAngle = startAngle + (percentageValue / 100) * 360;

  // Convert angles to radians
  const rad = (angle: number) => (angle * Math.PI) / 180;
  
  // Calculate arc's start and end points
  const startX = cx + r * Math.cos(rad(startAngle));
  const startY = cy + r * Math.sin(rad(startAngle));
  const endX = cx + r * Math.cos(rad(endAngle));
  const endY = cy + r * Math.sin(rad(endAngle));

  // Large arc flag
  const largeArcFlag = percentageValue >= 50 ? 1 : 0;

  // Path data for the arc
  const d = percentageValue === 100
    ? `M ${cx} ${cy} L ${startX} ${startY} A ${r} ${r} 0 1 1 ${startX - 0.01} ${startY} L ${cx} ${cy}`
    : `M ${cx} ${cy} L ${startX} ${startY} A ${r} ${r} 0 ${largeArcFlag} 1 ${endX} ${endY} L ${cx} ${cy}`;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="269" height="240" viewBox="0 0 269 215" fill="none">
      <circle cx={cx} cy={cy} r={r} fill="#F8EBED"/>
      <path d={d} fill="#FF425E"/>
      <circle cx={cx} cy={cy} r="71" fill="#FCFCFC"/>
      <text x={cx} y={cy} fontSize="30" fontFamily="Arial" textAnchor="middle" alignmentBaseline="middle" fill="#0A0A0A">{percentageString}</text>
    </svg>
  );
};

export default CirclePercentage;
