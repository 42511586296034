import { useEffect } from 'react'
import checkImg from '../../../images/check-circle.svg';
import './ExportModal.scss';

type Props = {
  text: string,
  close: () => void
};

export default function ExportModal({
  text,
  close
}: Props) {
  const path = window.location.pathname;

  useEffect(() => {
    setTimeout(() => close(), 3000)
  }, []);

  return (
    <div className="ExportModal">
      <div className="ExportModal__wrapper">
        <img src={checkImg} alt="success" />
        {text === "save" ? (
          <div>
            <h2 className="ExportModal__title">Success!</h2>
            <p className="ExportModal__text">Your {path === '/slide' ? 'presentation' : 'script'} is saved!</p>
          </div>
        ) : (
          <div>
            <h2 className="ExportModal__title">Success!</h2>
            <h3 className="ExportModal__text">Your {(path === '/slide' || path === '/slidesArchive') ? 'presentation' : 'script'} has been exported!</h3>
          </div>
        )}
      </div>
    </div>
  )
}
