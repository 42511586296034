import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import CloseIcon from '@mui/icons-material/Close';
import './ShareCourseModal.scss';

type Props = {
  action: any,
  text: string,
  copied?: boolean,
  close: (arg: boolean) => void
};

export default function ShareCourseModal({
  action,
  copied,
  text,
  close }: Props) {

  return (
    <div className="ShareCourseModal">
      <div className="ShareCourseModal__wrapper">
        <div
          className="ShareCourseModal__close"
          onClick={() => close(false)}
        >
          <CloseIcon
            sx={{
              color: '#5A6861'
            }}
          />
        </div>
        <h2>Share this course</h2> 
        <input type="text"  className="ShareCourseModal__input" defaultValue={text}/>
        <div className="ShareCourseModal__btns">
          <ConfirmButton
            disable={copied}
            text={copied ? "Copied " :"Copy link"}
            action={action}
            customStyles={{
              backgroundColor: copied ? "#00539C" : "#2C74B3",
            }}
          />
          <div
            className="ShareCourseModal__btn"
            onClick={() => close(false)} 
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  )
}
