import React from 'react';
import { ReactComponent as AzureIcon } from '../../../images/azureIcon.svg';
import './AzureButton.scss';

interface AzureButtonProps {
  onClick: () => void,
  disabled?: boolean,
}

function AzureButton({ onClick, disabled = false }: AzureButtonProps) {
  return (
    <button
      className="AzureButton"
      onClick={onClick}
      disabled={disabled}>
      <AzureIcon className="AzureButton__icon" />
      <span className="AzureButton__text">
        {`Log In with Microsoft`}
      </span>
    </button>
  );
}

export default AzureButton;