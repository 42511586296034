import React, { useState, useEffect, useCallback } from 'react'; 
import TextAreaSection from '../TextAreaSection/TextAreaSection'; 
import { useSocket } from '../../App';
import './SummaryBlock.scss';



interface SectionBlockProps {
    summary: string;
    updateSummary: (value: string) => void;
}

interface SumaryReferenceProps { 
  selectionStart: number;
  selectionEnd: number;
}

interface UpdateTextInSummaryParams {
  summaryReference: SumaryReferenceProps | null;
  data: string; 
  resetSummaryReference: () => void;
}

const SectionBlock: React.FC<SectionBlockProps> = ({ summary, updateSummary }) => {
  const newSocket = useSocket();
  const [loading, setLoading] = useState(false); 
  const [summaryReference, setSummaryReference] = useState<SumaryReferenceProps | null>(null);  
  
  const updateTextInSummary = useCallback(({
    summaryReference,
    data, 
    resetSummaryReference
  }: UpdateTextInSummaryParams) => {
    if (!summaryReference) return;

    const targetSection = summary;
    const beforeText = targetSection.substring(0, summaryReference.selectionStart);
    const afterText = targetSection.substring(summaryReference.selectionEnd);
    const newText = `${beforeText}${data}${afterText}`;
    updateSummary(newText);
    resetSummaryReference();
  }, [summary, updateSummary]);

 

  useEffect(() => {
    const handleTextGenerationEnd = (data: string) => {
        updateTextInSummary({
            summaryReference,
            data,
            resetSummaryReference: () => setSummaryReference(null)
        });
      setLoading(false);
    };  
    newSocket?.on('education:regenerate-text:end', handleTextGenerationEnd);
    newSocket?.on('education:generate-text:end', handleTextGenerationEnd);
  
    return () => {
      newSocket?.off('education:regenerate-text:end', handleTextGenerationEnd);
      newSocket?.off('education:generate-text:end', handleTextGenerationEnd);
    };
  }, [summaryReference, newSocket, updateTextInSummary]);
  
  const handleImproveSubsection = (textToChange: string, instructions: string, value: string) => { 
    setLoading(true);
    
    newSocket?.emit('education:regenerate-text', {
      fullText: value, // Full text of the section
      textToChange, // The selected text to improve
      instructions, // Instructions from modal input
    });
  };
  
  // Implement the callback for the 'generate' action
  const handleGenerateSubsection = (instructions: string) => {
    setLoading(true);
  
    newSocket?.emit('education:generate-text', {
      title: 'Summary', // Title of the section
      instructions, // Instructions from modal input
    });
  };
  
  return (
    <div className="section-block">
      <TextAreaSection
        loading={loading} 
        imageRequired={false}
        value={summary}
        onChange={updateSummary}
        onGenerate={handleGenerateSubsection} // Adapt as needed
        onImprove={handleImproveSubsection} 
        setSectionReference={(selectionStart, selectionEnd) => setSummaryReference({ selectionStart, selectionEnd })}       
      />
    </div>
  );
};

export default SectionBlock;
