

import { ReactComponent as CloseTooltip } from '../../../images/closeTooltip.svg';
import './ButtonTooltip.scss';

interface ButtonTooltipProps {
  position: string;
  onClose: () => void;
}
 

export default function ButtonTooltip({position, onClose}: ButtonTooltipProps): JSX.Element {
 
  return (
    <div className={`ButtonTooltip + ${position}`}>
        
        <div className="ButtonTooltip__content">
            <div className="ButtonTooltip__content__close" onClick={onClose}><CloseTooltip /></div>
             
            <div className="ButtonTooltip__content__text">
            Highlight the text you want to improve
            </div>
        
        </div>
    </div>
  );
}; 

