import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg';
import videoPlaceholder from '../../../../../images/Rectangle.png';
import { ReactComponent as Lock} from '../../../../../images/lock-gradient.svg';
import { ReactComponent as ButtonGradient} from '../../../../../images/button-upgrade.svg';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';
import { setCurrentPage } from '../../../../../redux/slices/sharedSlice';
import SectionContainer  from '../../../../SectionContainer/SectionContainer';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import { useState, useEffect } from 'react';
import { useSharedModules } from '../../../../../hooks/useModules';
import { isVideo } from '../../../../../utils/helpers';
import './SharedEducationalContent.scss';

export default function SharedEducationalContent() {
  const [ mode, setMode ] = useState<'view' | 'edit'>('view');
  const dispatch = useDispatch<any>();
  const { loading } = useSelector((state: RootState) => state.shared); 
  const { educationalContent } = useSelector((state: RootState) => state?.shared?.activeCourse) || {}; 
  const { enableNextStep } = useSharedModules(); 
  const [saveSectionModalOpen, setSaveSectionModalOpen] = useState(false);


  useEffect(() => {
    enableNextStep('shared-education');
  }, []);

 
 
  return (
    <>
      {loading ? (
        <div className="ModulesEducation">
          <div className="ModulesEducation__container-loader">
            <CourseLoader
              customText={'Education content is being written…'}
            />
          </div>
        </div>
      ) : (
        <div className={`ModulesEducation 
        ${mode === 'edit' ? 'ModulesEducation__edit' : ''}
        `}>
          <div className="ModulesEducation__container">
            <div className="ModulesEducation__container__left" onClick={() => {
              if(mode === 'edit') {
                setSaveSectionModalOpen(true);
              } else{ 
                dispatch(setCurrentPage('shared-view'));
              }
            }}>
              <ArrowLeft/>
            </div>
            <div className="ModulesEducation__container__right">
              {  !educationalContent?.sections ? (  
                <div className="ModulesEducation__container__right__loader-container">
                 <CourseLoader customText='Education content is loading' />
                </div>
                
                ) : ( 
                  mode === 'edit' ? (
                    <SectionContainer 
                      saveSectionModalOpen={saveSectionModalOpen}
                      setSaveSectionModalOpen={setSaveSectionModalOpen}
                      changeMode={setMode}
                    />
                  ) :
                <>
                  <div className="ModulesEducation__container__right__header">
                  <div className="ModulesEducation__container__right__header__top">
                  
                  </div> 
                {/* TODO dont remove */}
                    {/* { (educationVideo) ? (
                      plan_name === "PROFESSIONAL PLAN" ? (
                        <video 
                          className="ModulesEducation__video-player" 
                          controls 
                          src={educationVideo}
                        >
                          Your browser does not support the video.
                        </video>
                          ) : (
                        <div  className="ModulesEducation__video-placeholder">
                          <img
                            className="ModulesEducation__video-player"
                            src={videoPlaceholder}
                            alt="logo"
                          />
                          <div className="ModulesEducation__video-placeholder__block">
                            <Lock />
                            <ButtonGradient 
                              className="ModulesEducation__video-placeholder__block__btn"
                              onClick={() => {
                                
                                window.location.href = `${process.env.REACT_APP_API_URL}payment`
                              }}/>
                            </div>

                        </div>
                        ))
                        :
                        educationVideo === '' &&
                        <div  className="ModulesEducation__video-loader">
                          <CourseLoader customText='Video is loading' />
                        </div>
                    } */}
                  </div>
                  <div className="ModulesEducation__container__right__body">

                  <div className="ModulesEducation__container__right__body__intro">

                    <div className="ModulesEducation__container__right__body__intro__header">
                      INTRODUCTION
                    </div>
                    <div className="ModulesEducation__container__right__body__intro__sections">
                      {
                        educationalContent?.agenda?.map((item, index) => (
                          <a className="ModulesEducation__container__right__body__intro__sections__item" key={index}  href={`#section-${index}`}>

                              <div className="ModulesEducation__container__right__body__intro__sections__item__name">
                                Section {index+1}:                      
                              </div>

                              <div className="ModulesEducation__container__right__body__intro__sections__item__text">
                              {item}                       
                              </div>

                          </a>
                        ))
                      }
                    </div>

                  </div>

                  {
                    educationalContent?.headerImage ? (
                      isVideo(educationalContent?.headerImage) ? (
                        <video controls className="header-image-block__image" key={educationalContent?.headerImage}>
                          <source src={educationalContent?.headerImage} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img className="ModulesEducation__container__right__body__headerImage" src={educationalContent?.headerImage || ''} alt="" />
             
                      )
                    ) : (
                      null
                    )
                  }

                  {
                    educationalContent?.sections?.map((section, index) => (
                      <div className="ModulesEducation__container__right__body__section" key={index}  id={`section-${index}`}>
                        <div className="ModulesEducation__container__right__body__section__title" >
                            {section.title}
                          </div>
                          <div className="ModulesEducation__container__right__body__section__description" >
                            {section.description}
                          </div>
                          {
                            section?.sub_sections?.map((block, index) => (
                            <div 
                                className={`ModulesEducation__container__right__body__section__block${((index % 2 === 0) && (block.image &&  block.image !== "template")) ? ' reversed' : ''}`} 
                                key={index}
                            >

                              <div className="ModulesEducation__container__right__body__section__block__text" >
                                <div className="ModulesEducation__container__right__body__section__block__text__title" >
                                  {block.title}
                                </div>
                                <div className="ModulesEducation__container__right__body__section__block__text__description" >
                                  {block.description}
                                </div>
                              
                              </div>
                             { 
                              (block.image &&  block.image !== "template") && 
                                <div className="ModulesEducation__container__right__body__section__block__image" >
                                    <img src={block.image} alt="" />
                                  </div>
                              }
                              
                              </div>
                            ))
                          }
                        

                      </div>
                    ))
                  }
                    <div className="ModulesEducation__container__right__body__summary">
                      
                      <div className="ModulesEducation__container__right__body__summary__header">
                        SUMMARY
                      </div>

                      <div className="ModulesEducation__container__right__body__summary__text">
                        {educationalContent?.summary}
                      </div>

                  </div>

                  <div className="ModulesEducation__container__right__body__bottom-btns">
                    <SaveButton
                        text="Finish"
                        action={() => {
                          dispatch(setCurrentPage('shared-view'));
                      }}
                        />
                  </div>

                  </div>
                
                </>
                ) 
              }
             
            </div>
            
          </div>

        </div>
        )
      }
    </>
  )
}
