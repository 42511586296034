import  { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg'; 
import { setCurrentPage, setQuiz } from '../../../../../redux/slices/coursesSlice';  
import SaveButton from '../../../../Buttons/SaveButton/SaveButton'; 
import QuizChips from '../../../../QuizChips/QuizChips';
import AddQuizQuestionModal from '../../../../Modals/AddQuizQuestionModal/AddQuizQuestionModal';
import { ReactComponent as QuizNext } from '../../../../../images/quiz-next.svg';
import { ReactComponent as QuizPrev } from '../../../../../images/quiz-prev.svg';
import QuizEditInput from '../../../../Inputs/QuizEditInput/QuizEditInput';
import QuizCheckBoxInput from '../../../../Inputs/QuizCheckBoxInput/QuizCheckBoxInput';
import QuizRadioInput from '../../../../Inputs/QuizRadioInput/QuizRadioInput';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import { toast } from 'react-toastify';
import './ModulesQuizEdit.scss'; 

type Assessment_RightOptions = {
    question: string;
    options: string[];
    answer: number[];
    userAnswer: number[];
}

type Assessment_TrueFalse = {
    question: string;
    answer: boolean;
    userAnswer: boolean;
}

type Assessment_FillBlank = {
    question: string;
    options: string[];
    answer: number;
    userAnswer: number;
}

type Assessment_Matching = {
    statement: string;
    match: string;
    userAnswer: string;
}[]

type QuizQuestionContent = Assessment_RightOptions | Assessment_TrueFalse | Assessment_FillBlank | Assessment_Matching;

interface Quiz{
    quiz: {
        type: "right_options" | "true_false" | "fill_blank" | "matching";
        content: Assessment_RightOptions | Assessment_TrueFalse | Assessment_FillBlank | Assessment_Matching[] | any;
    }[] 
} 
const initialQuizData: Quiz['quiz'] = [
    {
      type: "right_options",
      content: {
        question: "What are the primary colors?",
        options: ["Red", "Green", "Blue", "Yellow"],
        answer: [1, 3, 4], // Assuming options are 1-indexed
        userAnswer: [1, 3, 4]
      }
    },
    {
      type: "true_false",
      content: {
        question: "The sky is green.",
        answer: false,
        userAnswer: false
      }
    },
    {
      type: "fill_blank",
      content: {
        question: "The capital of France is {input}.",
        options: ["Paris", "Madrid", "Berlin"],
        answer: 1, // Assuming options are 1-indexed
        userAnswer: 1
      }
    },
    {
      type: "matching",
      content: [
        { statement: "Water freezes at", match: "0 degrees Celsius", userAnswer: "0 degrees Celsius" },
        { statement: "Boiling point of water", match: "100 degrees Celsius", userAnswer: "100 degrees Celsius" },
        { statement: "Humans breathe", match: "Oxygen", userAnswer: "Oxygen" },
        { statement: "The sun is a", match: "Star", userAnswer: "Star" }
      ]
    },
    {
      type: "fill_blank",
      content: {
        question: "The capital of France is {input}.",
        options: ["Paris", "Madrid", "Berlin"],
        answer: 1, // Assuming options are 1-indexed
        userAnswer: 1
      }
    },
    {
      type: "matching",
      content: [
        { statement: "Water freezes at", match: "0 degrees Celsius", userAnswer: "0 degrees Celsius" },
        { statement: "Boiling point of water", match: "100 degrees Celsius", userAnswer: "100 degrees Celsius" },
        { statement: "Humans breathe", match: "Oxygen", userAnswer: "Oxygen" },
        { statement: "The sun is a", match: "Star", userAnswer: "Star" }
      ]
    }
  ];

  // Extend the QuizQuestionContent type to include specific update actions
  type UpdateAction =
  | { type: 'UPDATE_QUESTION_TEXT'; newText: string }
  | { type: 'UPDATE_OPTION_TEXT'; optionIndex: number; newText: string }
  | { type: 'TOGGLE_CORRECT_ANSWER'; optionIndex: number }
  | { type: 'UPDATE_MATCHING_PAIR'; pairIndex: number; newStatement?: string; newMatch?: string }
  | { type: 'UPDATE_USER_ANSWER'; newUserAnswer: any }; // newUserAnswer type depends on question type


  
export default function ModulesQuizEdit (){
    const dispatch = useDispatch<AppDispatch>();
    const { loading, activeCourse } = useSelector((state: RootState) => state.courses);
    const [quizState, setQuizState] = useState<Quiz['quiz']>(activeCourse?.quiz as unknown as Quiz["quiz"]);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0); 
    const [showAddQuestion, setShowAddQuestion] = useState<boolean>(false);
    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);

    const quizTypes =  quizState?.map && quizState?.map(quiz => quiz.type) || [];

    const handleDelete = (index: number) => {
        const newIndex = index === 0 ? 0 : index - 1;
        setActiveQuestionIndex(newIndex);
        setQuizState(currentQuiz => currentQuiz.filter((_, i) => i !== index));
    };
      
 
    const generateNewQuestionTemplate = (type: "right_options" | "true_false" | "fill_blank" | "matching"): Quiz['quiz'][number] => {
        let newQuestionContent;

        switch (type) {
            case "right_options":
                newQuestionContent = {
                    question: "",
                    options: ["", "", "", "", "", ""], // Provide a default set of 4 empty options
                    answer: []
                };
                break;
            case "true_false":
                newQuestionContent = {
                    question: "",
                    answer: true, // Default to true, can be edited 
                };
                break;
            case "fill_blank":
                newQuestionContent = {
                    question: "",
                    options: ["", "", "", ""], // Provide a default set of 1 empty option for the blank
                    answer: 0
                };
                break;
            case "matching":
                newQuestionContent = [
                    { statement: "", match: "", userAnswer: "" },
                    { statement: "", match: "", userAnswer: "" },
                    { statement: "", match: "", userAnswer: "" },
                    { statement: "", match: "", userAnswer: "" }
                    
                ];
                break;
            default:
                newQuestionContent = {}; // Default case, should ideally never be hit
        }

        return {
            type: type,
            content: newQuestionContent
        };
    };

    const handleQuestionAdd = (type: "right_options" | "true_false" | "fill_blank" | "matching") => {
        const newQuestion = generateNewQuestionTemplate(type);
        setQuizState(currentQuiz => [...currentQuiz, newQuestion]);
        setActiveQuestionIndex(quizState.length);  
    };

    function updateQuestionContent(questionIndex: number, action: UpdateAction) {
        setQuizState((prevQuizState) => {
          const updatedQuiz = prevQuizState.map((question, index) => {
            if (index !== questionIndex) return question;
      
            switch (question.type) {
              case 'right_options':
                return { ...question, content: updateRightOptionsQuestion(question.content, action) };
              case 'true_false':
                return { ...question, content: updateTrueFalseQuestion(question.content, action) };
              case 'fill_blank':
                return { ...question, content: updateFillBlankQuestion(question.content, action) };
              case 'matching':
                return { ...question, content: updateMatchingQuestion(question.content, action) };
              default:
                return question; // No update
            }
          });
      
          return updatedQuiz;
        });
      }
      
    // Update functions for different question types
    function updateRightOptionsQuestion(question: Assessment_RightOptions, action: UpdateAction): Assessment_RightOptions {
        switch (action.type) {
        case 'UPDATE_QUESTION_TEXT':
            return { ...question, question: action.newText };
        case 'UPDATE_OPTION_TEXT':
            const updatedOptions = question.options.map((option, index) => index === action.optionIndex ? action.newText : option);
            return { ...question, options: updatedOptions };
        case 'TOGGLE_CORRECT_ANSWER':
            const isAlreadyCorrect = question.answer.includes(action.optionIndex + 1);
            const updatedAnswers = isAlreadyCorrect
            ? question.answer.filter(answer => answer !== action.optionIndex + 1)
            : [...question.answer, action.optionIndex + 1];
            return { ...question, answer: updatedAnswers };
        default:
            return question;
        }
    }
    
    function updateTrueFalseQuestion(question: Assessment_TrueFalse, action: UpdateAction): Assessment_TrueFalse {
        switch (action.type) {
        case 'UPDATE_QUESTION_TEXT':
            return { ...question, question: action.newText };
        case 'TOGGLE_CORRECT_ANSWER':
            return { ...question, answer: action.optionIndex === 0 ? true : false};
        default:
            return question;
        }
    }
    
    function updateFillBlankQuestion(question: Assessment_FillBlank, action: UpdateAction): Assessment_FillBlank {
        switch (action.type) {
        case 'UPDATE_QUESTION_TEXT':
            return { ...question, question: action.newText };
        case 'TOGGLE_CORRECT_ANSWER':
            return { ...question, answer: action.optionIndex + 1 };
        case 'UPDATE_OPTION_TEXT':
            const updatedOptions = question.options.map((option, index) => 
                index === action.optionIndex ? action.newText : option
            );
            return { ...question, options: updatedOptions };
        default:
            return question;
        }
    }
    
    function updateMatchingQuestion(question: Assessment_Matching, action: UpdateAction): Assessment_Matching {
        if (action.type === 'UPDATE_MATCHING_PAIR') {
        return question.map((pair, index) => index === action.pairIndex ? { ...pair, statement: action.newStatement ?? pair.statement, match: action.newMatch ?? pair.match } : pair);
        }
        return question;
    }
  
    const renderQuestionContent = (question: { type: string; content: QuizQuestionContent }) => {
        switch (question.type) {
            case "right_options":
                const rightOptionsContent = question.content as Assessment_RightOptions;
                return (
                    <div className='quiz-question-right-options'>
                        <QuizEditInput
                            text={rightOptionsContent.question}            
                            setText={(newText) => updateQuestionContent(activeQuestionIndex, { type: 'UPDATE_QUESTION_TEXT', newText })}
                            customStyles={{width: '564px'}}
                        />
                   <div className='quiz-question-right-options__options'>
                        {rightOptionsContent.options.map((option, index) => (
                            <QuizCheckBoxInput
                                key={index}
                                text={option}  
                                correct={rightOptionsContent.answer.includes(index+1) }          
                                setText={(newText) => updateQuestionContent(activeQuestionIndex, { type: 'UPDATE_OPTION_TEXT', optionIndex: index, newText })}
                                setCorrect={() => updateQuestionContent(activeQuestionIndex, { type: 'TOGGLE_CORRECT_ANSWER', optionIndex: index })}
                                />
                        ))}
                    </div>
                </div>
                );
    
            case "true_false":
                const trueFalseContent = question.content as Assessment_TrueFalse;
                return (
                    <div className='quiz-question-true-false'>
                        
                        <QuizEditInput
                            text={trueFalseContent.question}
                            customStyles={{width: '629px'}}
                            setText={(newText) => updateQuestionContent(activeQuestionIndex, { type: 'UPDATE_QUESTION_TEXT', newText })}
                        />
                        <div className='quiz-question-true-false__options'>
                            <QuizRadioInput
                                text="True"
                                correct={trueFalseContent.answer === true}
                                setText={(newText) => {}}
                                setCorrect={(correct) => updateQuestionContent(activeQuestionIndex, { type: 'TOGGLE_CORRECT_ANSWER', optionIndex: 0 })}

                                />
                            <QuizRadioInput
                                text="False"
                                correct={trueFalseContent.answer === false}
                                setText={(newText) => {}}
                                setCorrect={(correct) => updateQuestionContent(activeQuestionIndex, { type: 'TOGGLE_CORRECT_ANSWER', optionIndex: 1 })}
                                />
                        </div>
                        
                    </div>
                );
    
            case "fill_blank":
                const fillBlankContent = question.content as Assessment_FillBlank;
                return (
                    <div className='quiz-question-fill-blank'>
                        <QuizEditInput
                            text={fillBlankContent.question}            
                            setText={(newText) => updateQuestionContent(activeQuestionIndex, { type: 'UPDATE_QUESTION_TEXT', newText })}
                            customStyles={{width: '629px'}}
                        />
                        <div className='quiz-question-fill-blank__options'>
                            {fillBlankContent.options.map((option, index) => (
                                <QuizCheckBoxInput
                                key={index}
                                text={option}  
                                correct={fillBlankContent.answer === index+1}          
                                setText={(newText) => updateQuestionContent(activeQuestionIndex, { type: 'UPDATE_OPTION_TEXT', optionIndex: index, newText })}
                                setCorrect={() => updateQuestionContent(activeQuestionIndex, { type: 'TOGGLE_CORRECT_ANSWER', optionIndex: index })}
                                />
                               
                            ))}
                        </div>
                    </div>
                );
    
            case "matching":
                const matchingContent = question.content as Assessment_Matching;
                return (
                    <div className='quiz-question-matching'>
                         
                        {matchingContent.map((match, index) => (
                            <div key={index} className='quiz-question-matching__question-block'>
                                 <QuizEditInput
                                    text={match.statement}            
                                    setText={(newStatement) => updateQuestionContent(activeQuestionIndex, { type: 'UPDATE_MATCHING_PAIR', pairIndex: index, newStatement })}
   
                                    customStyles={{width: '240px', color: "#00539C"}}
                                    />
                                <QuizEditInput
                                    text={match.match}            
                                    setText={(newMatch) => updateQuestionContent(activeQuestionIndex, { type: 'UPDATE_MATCHING_PAIR', pairIndex: index, newMatch })}
                                    customStyles={{width: '604px', color: "#00539C", fontSize: "18px", fontWeight: "400"}}
                                    />
                            </div>
                        ))}
                    </div>
                );
    
            default:
                return <div>Question type not supported</div>;
        }
    };

    const notify = () =>  toast(`Please, fill all the fields and mark the correct statement before moving further`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        style: {
            marginTop: "106px",
            width: "238px",
            background: "linear-gradient(0deg, #F38D2B 0%, #F38D2B 100%)",
            color: "#FCFCFC",
            fontSize: "11px",
            fontWeight: "500",
            lineHeight: "16px",
        },
        bodyClassName: 'inter-font', // Add a class for the body
    });

    const verifyQuestion = (question: Quiz['quiz'][number]) => {
        switch (question.type) {
            case "right_options":
                const rightOptionsContent = question.content as Assessment_RightOptions;
                return rightOptionsContent.question && rightOptionsContent.options.every(option => option) && rightOptionsContent.answer.length > 0;
            case "true_false":
                const trueFalseContent = question.content as Assessment_TrueFalse;
                return trueFalseContent.question;
            case "fill_blank":
                const fillBlankContent = question.content as Assessment_FillBlank;
                return fillBlankContent.question && fillBlankContent.options.every(option => option);
            case "matching":
                const matchingContent = question.content as Assessment_Matching;
                return matchingContent.every(pair => pair.statement && pair.match);
            default:
                return false;
        }
    }
 

    const onSave = () => {
        // dispatch(updateCourseAnswers({courseId: activeCourse.id, answers: quizState}));
        setShowSaveModal(false);
        dispatch(setQuiz(quizState));
        dispatch(setCurrentPage('module-quiz')) 
    }

    const onCancelSave = () => {
        setShowSaveModal(false);
        dispatch(setCurrentPage('module-quiz')) 
    }
 
 
    return (
        <>
          {loading ? (
            <div className="ModulesQuiz">
              <div className="ModulesQuiz-loader">
              <CourseLoader />
            </div>
            </div>
          ) : (
            <div className="ModulesQuiz">
                 {
                      showSaveModal && (
                        <CloseModal
                          type={'save'}
                          action={onSave}
                          text={'Would you like to save your progress?'}
                          closeNoSave={onCancelSave}
                          close={()=> setShowSaveModal(false)}
                          />
                      )
                    }
                {showAddQuestion &&
                
                <AddQuizQuestionModal
                    action={(type: "right_options" | "true_false" | "fill_blank" | "matching" ) => {
                        setShowAddQuestion(false);
                        handleQuestionAdd(type);
                    }}
                    text={"Do you want to add a new question?"}
                    closeNoSave={() => setShowAddQuestion(false)}
                    close={() => setShowAddQuestion(false)}
                />
                    }
              <div className="ModulesQuiz__container">
                <div className="ModulesQuiz__container__left" onClick={() => {
                    verifyQuestion(quizState[activeQuestionIndex]) ?
                        setShowSaveModal(true)
                        :
                        notify();
                }}>
                  <ArrowLeft
                  />
                </div>
                <div className="ModulesQuiz__container__right">
                    <div className="ModulesQuiz__container__right__header" style={{marginTop: '12px', marginBottom: "40px"}}>
                        Edit Mode: Quiz
                    </div>
                    <div className="ModulesQuiz__container__right__btn-cont">
                            <SaveButton
                                text="Save"
                                action={() => {
                                    verifyQuestion(quizState[activeQuestionIndex]) ?
                                        setShowSaveModal(true) 
                                        :
                                        notify();
                                    }}
                                />
                        </div>
                    <div className="ModulesQuiz__container__right__header">
                        <QuizChips
                            activeQuestion={activeQuestionIndex}
                            quizTypes={quizTypes}
                            handleQuizClick={(index: number) => {
                                verifyQuestion(quizState[activeQuestionIndex]) ?
                                    setActiveQuestionIndex(index)
                                    :
                                    notify();
                            }}
                            handleDelete={handleDelete}
                            handleQuestionAdd={() => {
                                verifyQuestion(quizState[activeQuestionIndex]) ?
                                    setShowAddQuestion(true)
                                    :
                                    notify();
                            }}
                            />
                    </div>
                  
                  <div className="ModulesQuiz__container__right__body">

                    <div className="ModulesQuiz__container__right__body__prev">
                       { !!activeQuestionIndex  && 
                            <QuizPrev 
                                className='ModulesQuiz__container__right__body__prev__icon'
                                onClick={() => {
                                    if (verifyQuestion(quizState[activeQuestionIndex])) {
                                      if (activeQuestionIndex !== null && activeQuestionIndex > 0) {
                                        setActiveQuestionIndex(activeQuestionIndex - 1);
                                      }
                                    } else {
                                      notify(); 
                                    }
                                  }}
                                  
                            />
                        }
                    </div>
                   
                    {
                        activeQuestionIndex !== null && 
                            <div className="ModulesQuiz__container__right__body__content">
                                {quizState[activeQuestionIndex] && renderQuestionContent(quizState[activeQuestionIndex])}
                            </div>
                    }
              
                    <div className="ModulesQuiz__container__right__body__next">
                       { activeQuestionIndex !== null && activeQuestionIndex < quizState.length - 1  &&
                            <QuizNext 
                                    className='ModulesQuiz__container__right__body__prev__icon'
                                    onClick={() => {
                                        if (verifyQuestion(quizState[activeQuestionIndex])) {
                                          if (activeQuestionIndex !== null && activeQuestionIndex < quizState.length - 1) {
                                            setActiveQuestionIndex(activeQuestionIndex + 1);
                                          }
                                        } else {
                                          notify();
                                        }
                                    }}
                                />
                        }
                    </div>
                  </div>
                
                  <div className="ModulesQuiz__container__right__footer">
                 
                  </div>
                </div>
    
              </div>
    
            </div>
            )
          }
        </>
      )

}