import {ReactComponent as LogoMedium} from '../../../../../images/logoMedium.svg';
import LogoBig from '../../../../../images/logoBig.png';
// import bigLogo from '../../../../images/LanndaiBigLogo.png';
import {ReactComponent as AlertTriangle} from '../../../../../images/alert-triangle.svg';
import { createSharedUser, checkSharedCourse, getSharedInfo } from '../../../../../api/shareApi';
import { useEffect, useState } from 'react';
import { RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import SectionsLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { setCurrentPage,  setActiveCourse, updateShareUserData, setLoadingPage } from '../../../../../redux/slices/sharedSlice';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';
import './SharedLogin.scss';

export default function SharedLogin() {
    const dispatch = useDispatch<any>();
    const { loadingPage } = useSelector((state: RootState) => state.shared);
    const activeCourseUid= window.location.pathname.split('/')[2];
    const activeCourseId = +window.location.pathname.split('/')[3];
    const [email,
        setEmail] = useState('');
    const [name,
        setName] = useState(''); 
    const [emailError,
        setEmailError] = useState(false);
    const [nameError,
        setNameError] = useState(false);

    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailError(false);
    }

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        setNameError(false);
    }
    const checkEmail = (email: string) => { 
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    

    const createUser = async () => {
            if(activeCourseId && name && email && checkEmail(email) ){
            dispatch(setLoadingPage(true));
            const link = await createSharedUser(email, activeCourseId, name);
            if(link?.statusCode === 400){
                
                dispatch(setCurrentPage('shared-unavaliable'));
                dispatch(setLoadingPage(false));
            } else if(link?.id){
                const courseContent = await getSharedInfo(email, activeCourseUid)
                console.log(courseContent);
                dispatch(updateShareUserData({
                    email: email,
                    name: name,
                    shareId: activeCourseId
                }));
                dispatch(setActiveCourse(courseContent.share)); 
                dispatch(setCurrentPage("shared-view")); 
                dispatch(setLoadingPage(false));
            } 
            } else{
                dispatch(setLoadingPage(false));
                if(!email || !checkEmail(email)){
                    setEmailError(true);
                }
                if(!name){
                    setNameError(true);
                }
            }
        }

      const checkShared = async (activeCourseUid: string) => {
        if(activeCourseUid){
            const course = await checkSharedCourse(activeCourseUid);
             if(!course.id){
                dispatch(setCurrentPage('shared-unavaliable'));
             }  
        }
    }

    useEffect(() => {
        checkShared(activeCourseUid);
    }
    , [activeCourseUid]);

    return (

        <>
        {loadingPage ? (
          <div className="ModulesFlashCards">
            <div className="ModulesFlashCards__container-loader">
            <SectionsLoader 
                customText='Please, wait...'
            />
          </div>
          </div>
        ) : (
        <div className='SharedLogin'>
            {/* <div className='SharedLogin__header'>
                <LogoMedium/>
            </div> */}
            <div className='SharedLogin__body'>
                <div className='SharedLogin__body__form-block'>
                    <div className='SharedLogin__body__form-block__title'>
                        Welcome!
                    </div>
                    <div className='SharedLogin__body__form-block__sub-header'>
                        <div className='SharedLogin__body__form-block__sub-header__text'>
                        Your assessment will be sent to the email you provide after the course has been completed
                        </div>
                    </div>
                    <div className='SharedLogin__body__form-block__form-container'>
                        <div className='SharedLogin__body__form-block__form-container__input-block'>
                            <div
                                className='SharedLogin__body__form-block__form-container__input-block__header'>
                                <div
                                    className='SharedLogin__body__form-block__form-container__input-block__title'>
                                    Name
                                </div>
                                <div
                                    className='SharedLogin__body__form-block__form-container__input-block__label'>
                                    Required
                                </div>
                            </div>
                            <input
                                type='text'
                                value={name}
                                onChange={onNameChange}
                                className={'SharedLogin__body__form-block__form-container__input-block__input' + (nameError ? ' SharedLogin__body__form-block__form-container__input-block__input--error' : '')}
                                placeholder='Name'/>
                                {nameError && (
                                    <div
                                        className='SharedLogin__body__form-block__form-container__input-block__error-block'>
                                        <AlertTriangle/>
                                        <span
                                            className='SharedLogin__body__form-block__form-container__input-block__error-block__text'>
                                            This field is required
                                        </span>
                                    </div>
                                )}
                        </div>
                        <div className='SharedLogin__body__form-block__form-container__input-block'>
                            <div
                                className='SharedLogin__body__form-block__form-container__input-block__header'>
                                <div
                                    className='SharedLogin__body__form-block__form-container__input-block__title'>
                                    Email
                                </div>
                                <div
                                    className='SharedLogin__body__form-block__form-container__input-block__label'>
                                    Required
                                </div>
                            </div>
                            <input
                                type='text'
                                value={email}
                                onChange={onEmailChange}
                                className={'SharedLogin__body__form-block__form-container__input-block__input' + (emailError ? ' SharedLogin__body__form-block__form-container__input-block__input--error' : '')}
                                placeholder='Email'/> {emailError && (
                                <div
                                    className='SharedLogin__body__form-block__form-container__input-block__error-block'>
                                    <AlertTriangle/>
                                    <span
                                        className='SharedLogin__body__form-block__form-container__input-block__error-block__text'>
                                        This field is required
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className='SharedLogin__body__form-block__form-container__button-block'>
                            <SaveButton
                                text='Go to Course'
                                action={() => { 
                                    createUser();
                                }}
                                />

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
        }
        </>
        )
}