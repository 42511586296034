import  { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../../../../utils/CookieHelper';
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import Mentoring from '../../../../../images/cursor-choose.svg'; 
import Simulation from '../../../../../images/male-case.svg';
import Story from '../../../../../images/wave.svg'; 
import { setCurrentPage, setCurrentTab} from '../../../../../redux/slices/sharedSlice';
import ModulesAiSimulationMentoring  from './ModulesAiSimulationMentoring/ModulesAiSimulationMentoring';
import ModulesAiSimulationStory  from './ModulesAiSimulationStory/ModulesAiSimulationStory';
import ModulesAiSimulationSimulation  from './ModulesAiSimulationSimulation/ModulesAiSimulationSimulation';
import {  useSharedModules } from '../../../../../hooks/useModules'; 
import './SharedSimulation.scss'; 

export default function SharedSimulation() {
  const dispatch = useDispatch<any>();
  const { currentTab, loadingPage } = useSelector((state: RootState) => state.shared); 
  const token = getCookie('jwtToken');
  const [modalOpen, setModalOpen] = useState(false);
  const { enableNextStep, hideEnableNextStep } = useSharedModules();
  const [hideModal, setHideModal] = useState<boolean>(false);

  useEffect(() => {
    enableNextStep('shared-ai-simulation');
  }, []);

  const onCancel = () => {
    setHideModal(false); 
  }
  const onHide = () => {
    hideEnableNextStep('module-ai-simulation', 'module-quiz'); 
    setHideModal(false);
    dispatch(setCurrentPage('module-view'));
  }
    
  const mainBlocks = [
    {
      title: 'Mentor Simulation',
      text: 'Choose Mentoring to try yourself as a mentor in the field of your knowledge base',
      icon: Mentoring,
      page: 'mentoring'
    },
    // {
    //   title: 'Student Simulation',
    //   text: 'Choose Student Simulation to try yourself in a simulation of a real conversation with potential student',
    //   icon: Simulation,
    //   page: 'simulation'
    // },
    // {
    //   title: 'Personalised Simulation',
    //   text: 'Choose Your story to share a personal experience with the AI coach and receive an advise on how to resolve the issues',
    //   icon: Story,
    //   page: 'story'
    // }
  ];


  return (
    <>
     {
        modalOpen && (
          <CloseModal
            type={'save'}
            submitText='Yes'
            action={()=> { 
              dispatch(setCurrentTab('main'));
              setModalOpen(false)
            }}
            text={'Are you sure you want to exit'}
            closeNoSave={()=> setModalOpen(false)}
            close={()=> {
              setModalOpen(false)
            }}
            />
        )

      } 
      {loadingPage ? (
        <div className="SharedSimulation">
          <div className="SharedSimulation__container-loader">
            <CourseLoader 
              customText='Please, wait...'
            
              />
          </div>
        </div>
      ) : (
        <div className="SharedSimulation">
          {
            hideModal && (
              <CloseModal
                type={'hide'}
                submitText={'Yes'}
                action={onHide}
                text={'Would you like to hide selected tabs, making them no longer visible?'}
                closeNoSave={onCancel}
                close={()=> setHideModal(false)}
                />
            )
          } 
          <div className="SharedSimulation__container">
            <div className="SharedSimulation__container__left" onClick={() => {
                enableNextStep('shared-ai-simulation');
                currentTab === 'main' ? dispatch(setCurrentPage('shared-view')) :  setModalOpen(true);
              }}>
              <ArrowLeft/>
            </div>
            <div className="SharedSimulation__container__right">
              

              {
                (!currentTab || currentTab === 'main') && (
                  <>
                 
                    <div className="SharedSimulation__container__right__main__header">         
                      {/* <div className="SharedSimulation__container__right__main__header__title">
                       Welcome to AI Simulation Room!
                      </div> */}
                      <div className="SharedSimulation__container__right__main__header__btns" >
                       
                      </div>
                    </div>
                    {/* <div className="SharedSimulation__container__right__main__sub-header">         
                      Choose the mode you want to try yourself in today
                    </div> */}

                    {/* <div className="SharedSimulation__container__right__main__body"> 
                      {
                        mainBlocks.map((block, index) => (
                          <div className="SharedSimulation__container__right__main__body__block" key={index} onClick={() => dispatch(setCurrentTab(block.page))}> 
                            <div className="SharedSimulation__container__right__main__body__block__icon">
                              <img src={block.icon} alt={block.title}  />
                            </div>
                            <div className="SharedSimulation__container__right__main__body__block__header">
                              {block.title}
                            </div>
                            <div className="SharedSimulation__container__right__main__body__block__line"/>
                            <div className="SharedSimulation__container__right__main__body__block__text">
                              {block.text}
                            </div>   
                          </div>
                        ))
                      }
                    </div> */}
                    <ModulesAiSimulationMentoring/>
                  </>
                )
              }

              {
                currentTab === 'mentoring' && (
                  <>
                    <ModulesAiSimulationMentoring/>
                  </>
                )
              }

              {/* {
                currentTab === 'simulation' && (
                  <>
                   <ModulesAiSimulationSimulation/>
                  </>
                )
              }

              {
                currentTab === 'story' && (
                  <>
                    <ModulesAiSimulationStory/>
                  </>
                )
              } */}

            </div>
            
          </div>

        </div>
        )
      }
    </>
  )
}
