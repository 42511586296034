import axios from 'axios';

const API = `${process.env.REACT_APP_API_URL}api`;

export const createShareLink = (token: string | undefined, sharedUserEmail: string, educationId: number) =>
axios.post(
  `${API}/share/`, // URL
  { // Data
    sharedUserEmail,
    educationId
  }, 
  { // Config
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
)
.then((res: any) => res.data);

export const checkSharedCourse = (uuid: string | undefined) =>
  axios.get(`${API}/share/${uuid}/check`, {
    
    headers: { 
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then((res: any) => res.data)
  .catch((err) => err.response.data);

  export const getSharedInfo = (sharedUserEmail: string, uid: string) =>
  axios.post(`${API}/share/info`, 
    {
      sharedUserEmail,
      uid
    },
    {
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res) => res.data)

  export const createSharedUser = (email: string, shareId: number, name: string ) =>
 
  axios.post(`${API}/share/user`, 
    {
      email,
      shareId,
      name
    },
    {
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res) => res.data).catch((err) => err.response.data) 
   
    export const submitShared = ({
        sharedUserName,
        questions,
        shareId,
        sharedUserEmail,
        quiz,
        evaluation,
        strong,
        improved,
        essayResult
    }: {
        sharedUserName: string,
        questions: {question: string, answer: string}[],
        shareId: number,
        sharedUserEmail: string,
        quiz: number,
        evaluation: number,
        strong: string[],
        improved: string[],
        essayResult: string
    
    }) =>
        axios.post(`${API}/share/submit`, 
            {
                shareId,
                sharedUserEmail,
                quiz,
                evaluation,
                strong,
                improved,
                sharedUserName,
                questions,
                essayResult
            },
            {
            headers: { 
                
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            },
            )
            .then((res) => res.data)


 
  export const removeSimulationId = ( id: number | undefined) =>
  axios.delete(
    `${API}/share/${id}`, // Updated URL
    { // Config
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res: any) => res.data);

  export const removeSimulation = ({
    mentoring,
    story,
    student
  }: {
    mentoring: number,
    story: number,
    student: number
  
  }) =>
  axios.post(
    `${API}/share/simulation/remove`, // Updated URL
    {
        mentoring,
        story,
        student
    },
    { // Config
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res: any) => res.data);
 

