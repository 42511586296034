import { ReactComponent as QuizRadio } from '../../../images/quiz-radio.svg';
import { ReactComponent as QuizRadioChecked } from '../../../images/quiz-radio-checked.svg';
import './QuizRadioInput.scss';

interface QuizRadioInputProps {
  text: string;
  correct: boolean;
  setText: (text: string) => void;
  setCorrect: (correct: boolean) => void;
  customStyles?: React.CSSProperties;
}

export default function QuizRadioInput({
  text,
  correct,
  setText,
  setCorrect,
  customStyles
}: QuizRadioInputProps) {
  return (
    <div className="quiz-edit-radio" style={customStyles}  onClick={() => setCorrect(!correct)} >
        {
            correct ?
            <QuizRadioChecked 
                /> 
             : 
            <QuizRadio 
                />
        }
       
      <input
        type="text"
        className="quiz-edit-radio__input"
        value={text}
        readOnly
        onChange={(e) => setText(e.target.value)}
      />
    </div>
  );
}

