import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './PayPlanMob.scss';
import { plans } from '../../api/paymentPlans';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFreePlan, sendStripe } from '../../api/scriptsApi';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../utils/CookieHelper';
import { endLoading, endPriceLoading, getUserInfo, startPriceLoading } from '../../redux/slices/scriptsSlice';
import { RootState } from '../../redux/store';
import Loader from '../../components/Loader/Loader';
import bigLogo from '../../images/LanndaiBigLogo.png';
import { isMobile } from 'react-device-detect';

export default function PayPlanMob() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [selectPlan, setSelectPlan] = useState(1);
  const [currentPlan, setCurrentPlan] = useState(plans[1]);
  const token = getCookie('jwtToken');

  const { user, loadingPayment } = useSelector((state: RootState) => state.scripts);
  const { tamplates } = useSelector((state: RootState) => state.slides);
  
  useEffect(() => {
    dispatch(endPriceLoading());
    token && dispatch(getUserInfo(token));
    dispatch(startPriceLoading());
  }, [dispatch, token]);

  useEffect(() => {
    if (user) {
      if (((user?.quantity && user?.isFreeTrialActive) || user?.isPlan) && !tamplates.length) {
        // navigate('/wall');
        dispatch(endPriceLoading());
      } else {
        dispatch(endPriceLoading());
      }
    }
  }, [user]);

  const handleClick = async () => {
    if (currentPlan.priceId === plans[0].priceId) {
      if (token) {
        await getFreePlan(token);
      }
      navigate('/wall');
    } else {
      if (user && token) {
        const body = {
          priceId: selectPlan === 1 ? currentPlan?.priceId : currentPlan?.priceIdYearly,
          quantity: currentPlan?.quantity,
          email: user?.email,
        };

        const link = await sendStripe(body, token, isMobile ? 'mob' : 'desktop');
        //@ts-ignore
        if (window.Rewardful?.affiliate) {
          //@ts-ignore
          window.Rewardful.convert({ email: user?.email })
        }
        window.location.href = link?.url;
      };
    }
  }

  return (
    <div className="PayPlanMob">
      {loadingPayment ? (
        <div className="PayPlanMob__loader">
          <Loader />
        </div>
      ) : (
        <>
          <img src={bigLogo} alt='logo' />
          <div className="PayPlanMob__selector">
            <div
              className={classNames('PayPlanMob__selector-btn', {
                'PayPlanMob__selector-btn--select': selectPlan === 1
              })}
              onClick={() => setSelectPlan(1)}
            >
              Monthly
            </div>
            <div
              className={classNames('PayPlanMob__selector-btn', {
                'PayPlanMob__selector-btn--select': selectPlan === 2
              })}
              onClick={() => setSelectPlan(2)}
            >
              Yearly <span>25%</span> OFF
            </div>
            <div
              className={classNames('PayPlanMob__bg', {
                'PayPlanMob__bg--select': selectPlan === 2
              })}
            ></div>
          </div>
          <div className='PayPlanMob__list'>
            {plans?.map(plan => 
              <div className={`PayPlanMob__list_item ${currentPlan.priceId === plan.priceId ? 'active' : ''}`} onClick={() => setCurrentPlan(plan)}>
               <div className="PayPlanMob__list_item__content">
                 <div className="PayPlanMob__list_item__header">
                    <div className='PayPlanMob__list_item__circle'></div>
                    <p className='PayPlanMob__list_item__title'>{plan.title}</p>
                    { plan.priceId === plans[1].priceId &&<div className='PayPlanMob__list_item__best'>
                      <p>Best</p>
                    </div> }
                 </div>
                 <div className='PayPlanMob__list_item__pricing'>
                   <p>${selectPlan === 1 ? plan.priceMob : plan.priceMobYear}</p>
                   <span>/month</span>
                 </div>
                 <div className='PayPlanMob__list_item__contains'>
                   <p>( {plan.sub} )</p>
                 </div>
               </div>
             </div>  
            )}
          </div>
          <div className='PayPlanMob__submit'>
              <button onClick={handleClick}>Select</button>
          </div>
        </>
      )}
    </div>
  )
}
