
import './QuizEditInput.scss'

interface QuizEditInputProps {
    text: string;
    setText: (text: string) => void;
    customStyles?: React.CSSProperties;
}




export default function QuizEditInput ({text, setText, customStyles} : QuizEditInputProps){

    return (
        <>
            <input
             type="text"
             className="quiz-edit-input" 
             value={text} 
             onChange={(e) => setText(e.target.value)}
             style={customStyles}
             />
        </>
    )

}