import { useState } from 'react'; 
import { RootState } from '../../../redux/store';
import LowBalanseModal from '../LowBalanseModal/LowBalanseModal';  
import { useSelector } from 'react-redux';
import PresentationGenModal from '../PresentationGenModal/PresentationGenModal';
import './AiGenerateModal.scss';


export default function AiGenerateModal() {
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const { user } = useSelector((state: RootState) => state.scripts);

  return (
    <>
      {openSubmitModal ? (
        <LowBalanseModal />
      ) : (
        <div className="AiGenerateModal">
          <PresentationGenModal
            setOpenSubmitModal={setOpenSubmitModal}
            user={user}
          />
        </div>
      )}
    </>
  )
}
