import axios from 'axios';
 
const API = `${process.env.REACT_APP_API_URL}api`;
 

  export const sendOTP = (email: string ) =>
  axios.post(
    `${API}/auth/send-otp`, // URL
    { // Data
       email
    }, 
    { // Config
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res: any) => res.data)
  .catch((error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data.message || 'An error occurred';
    } else if (error.request) {
      // The request was made but no response was received
      return 'No response received from the server';
    } else {
      // Something happened in setting up the request that triggered an Error
      return error.message;
    }
  });
  
 
  export const verifyOTP = (email: string, code: string) =>
  axios.post(
    `${API}/auth/verify-otp`, // Updated URL
    {
        email,
        otp: code
    },
    { // Config
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res: any) => res.data)
  .catch((error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data.message || 'An error occurred';
    } else if (error.request) {
      // The request was made but no response was received
      return 'No response received from the server';
    } else {
      // Something happened in setting up the request that triggered an Error
      return error.message;
    }
  });
   
   
