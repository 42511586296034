import SharedSuccess from './SharedContent/SharedSuccess/SharedSuccess';
import SharedReport from './SharedContent/SharedReport/SharedReport';
import SharedQuiz from './SharedContent/SharedQuiz/SharedQuiz';
import SharedSimulation from './SharedContent/SharedSimulation/SharedSimulation';
import SharedEssay from './SharedContent/SharedEssay/SharedEssay';
import SharedFlashCards from './SharedContent/SharedFlashCards/SharedFlashCards';
import SharedEducationalContent from './SharedContent/SharedEducationalContent/SharedEducationalContent';
import SharedKeyTerms from './SharedContent/SharedKeyTerms/SharedKeyTerms';
import SharedView from './SharedContent/SharedView/SharedView';
import SharedEvaluation from './SharedContent/SharedEvaluation/SharedEvaluation';
import SharedUnavaliable from './SharedContent/SharedUnavaliable/SharedUnavaliable';
import SharedModulesCheckPoints from './SharedContent/SharedModulesCheckPoints/SharedModulesCheckPoints';
import MobileSharedLogin from './SharedContent/MobileSharedLogin/MobileSharedLogin';
import MobileSharedUnavaliable from './SharedContent/MobileSharedUnavaliable/MobileSharedUnavaliable'; 
import SharedWallMob from '../../../pages/SharedWallMob/SharedWallMob';
import Loader from '../../Loader/Loader';
import SharedLogin from './SharedContent/SharedLogin/SharedLogin';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store'; 
import { isMobile } from 'react-device-detect';
import SharedProgressModal from '../../Modals/SharedProgressModal/SharedProgressModal';
import { useState, useEffect } from 'react';
import './SharedSections.scss'; 


export default function SharedSections() {
    const [infoModal, setInfoModal] = useState<boolean>(true); 
    const { currentPage, loading } = useSelector((state: RootState) => state.shared);

    useEffect(() => {
       if(currentPage === 'shared-login') {
           setInfoModal(true);
       }
    }, [currentPage])
    window.onbeforeunload = function() {
        return "Data will be lost if you leave the page, are you sure?";
      }; 
    return (
        <div className='SharedSections'>
          {loading ? (
            <Loader />
          ) : (
           <>
                {
                    currentPage === 'shared-login' && (
                        isMobile ?
                         <MobileSharedLogin /> :
                         <SharedLogin />
                    )
                }
                {
                    currentPage === 'shared-thanks' && (
                        <SharedWallMob />
                    )
                }
                {
                    currentPage === "shared-unavaliable" && (
                        isMobile ?
                        <MobileSharedUnavaliable /> :
                        <SharedUnavaliable />
                    )
                }
                {
                    currentPage === "shared-checkpoints" && (
                        <SharedModulesCheckPoints />
                    )

                }
                {
                    currentPage === "shared-view" && (
                        <>
                        <SharedView />
                        {
                            infoModal && (
                            <SharedProgressModal
                              close={() => setInfoModal(false)}
                              />
                            )
                          }
                          </>
                    )
                }
                    {
                    currentPage === "shared-evaluation" && (
                        <SharedEvaluation />
                    )
                }
                {    currentPage === 'shared-key-terms' && (
                        <SharedKeyTerms />
                    )
                }
                {
                    currentPage === 'shared-education' && (
                        <SharedEducationalContent />
                    )
                }
                {
                    currentPage === 'shared-flashcards' && (
                        <SharedFlashCards />
                    )
                }
                {
                    currentPage === 'shared-essay' && (
                        <SharedEssay />
                    )
                }
                {
                    currentPage === 'shared-ai-simulation' && (
                        <SharedSimulation />
                    )
                }
                {
                    currentPage === 'shared-quiz' && (
                        <SharedQuiz />
                    )
                } 
                {
                    currentPage === 'shared-report' && (
                        <SharedReport />
                    )
                }
                {
                    currentPage === 'shared-success' && (
                        <SharedSuccess />
                    )
                }

           </>
            
            )
          }
        </div>
      )
    }
