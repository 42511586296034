import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg';
import { setCurrentPage, updateCourseAnswers, updateEvaluationAnswers} from '../../../../../redux/slices/sharedSlice'; 
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ReactComponent as RocketEval } from '../../../../../images/rocket-eval.svg';
import { ReactComponent as AlertTriangle } from '../../../../../images/alert-triangle.svg';
import { ReactComponent as ErrorTooltip} from '../../../../../images/error-tooltip.svg';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';
import { useSharedModules } from '../../../../../hooks/useModules';
import './SharedEvaluation.scss';

export default function SharedEvaluation() {
  const dispatch = useDispatch<any>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [tooltipVisibility, setTooltipVisibility] = useState<Record<number, boolean>>({});
  const { loading } = useSelector((state: RootState) => state.shared);
  const { evaluation, evaluationAnswers } = useSelector((state: RootState) => state?.shared?.activeCourse) || {};
  const [step, setStep] = useState(1);
  const [inputValues, setInputValues] = useState<Record<number, string>>(evaluationAnswers || {});
  const [inputErrors, setInputErrors] = useState<Record<number, boolean>>({});
  const { enableNextStep } = useSharedModules();

  useEffect(() => {
    const allInputsFilled = evaluation?.every((_, index) => Boolean(inputValues[index]?.replaceAll(' ', '')));
    setIsButtonDisabled(!allInputsFilled);
  }, [inputValues]);

  useEffect(() => {
    enableNextStep('shared-evaluation');
  }, []);

  const handleTooltipClick = (index: number) => {
    const newTooltipVisibility = { ...tooltipVisibility, [index]: false };
    setTooltipVisibility(newTooltipVisibility);
  };

  const handleInputFocus = (index: number) => {
    const newInputErrors = { ...inputErrors };
    delete newInputErrors[index];
    setInputErrors(newInputErrors);
  };
  
  const handleInputBlur = (index: number) => {
    const newInputErrors = { ...inputErrors, [index]: !Boolean(inputValues[index]?.replaceAll(' ', '')) };
    setInputErrors(newInputErrors);
    const newTooltipVisibility = { ...tooltipVisibility, [index]: true };
    setTooltipVisibility(newTooltipVisibility);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newInputValues = { ...inputValues, [index]: e.target.value.trimStart()};
    setInputValues(newInputValues);
    dispatch(updateEvaluationAnswers(newInputValues));
    
    if (e.target.value) {
      const newInputErrors = { ...inputErrors };
      delete newInputErrors[index];
      setInputErrors(newInputErrors);
    }
  };
  

  const handleSave = () => {
    if(step === 1) {
      const newInputErrors: Record<number, boolean> = {};
    
      evaluation?.forEach((_, index) => {
        newInputErrors[index] = !inputValues[index];
      });
    
      setInputErrors(newInputErrors);
    
      // Check if all inputs are filled and not error is present
      const allInputsAreValid = Object.values(newInputErrors).every(error => !error);
    
      if(allInputsAreValid) {
        // Replace {input} placeholders with respective input values
        const preparedData = evaluation?.map((question, index) => {
          return question.replace("{input}", inputValues[index] || "");
        });
    
        // Dispatch prepared data to Redux store
        dispatch(updateCourseAnswers({evaluate: preparedData}));
        setStep(step + 1);  
        // dispatch(setCurrentPage('module-view'));
      }
    } else { 
        dispatch(setCurrentPage('shared-view'));
    }
  };
  
   
 
  return (
    <>
      {loading ? (
        <div className="ModulesEvaluate">
          <div className="ModulesEvaluate__container-loader">
          <CourseLoader />
        </div>
        </div>
      ) : (
        <div className="ModulesEvaluate">
          <div className="ModulesEvaluate__container">
          <div 
            className="ModulesEvaluate__container__left" 
            onClick={() => {
                if (step === 1) {
                  dispatch(setCurrentPage('shared-view'));
                } else{
                    setStep(step - 1);
                }
              }
            }
            >
              <ArrowLeft/>
            </div>
            <div className="ModulesEvaluate__container__right">
             

              {step === 1 ? (
                <>
                  <div className="ModulesEvaluate__container__right__header">
                    Fill in the blank with your best answer
                    </div>
                  <div className="ModulesEvaluate__container__right__body">
                  {
                    evaluation?.map((question, index) => (
                      <div key={index}>
                        <div className="ModulesEvaluate__container__right__body__term">
                          <div className="ModulesEvaluate__container__right__body__term__icon">
                            {index + 1}
                          </div>
                          <div className="ModulesEvaluate__container__right__body__term__block">
                            <div className="ModulesEvaluate__container__right__body__term__block__title">
                              {
                                question.split("{input}").map((text, subIndex, array) => (
                                  <div key={subIndex}>
                                    {text}
                                    {subIndex !== array.length - 1 && 
                                      <div className={`evaluate-input-wrapper ${inputErrors[index] ? 'input-error' : ''}`}>
                                        <OutlinedInput 
                                          required 
                                          className="evaluate-input"
                                          value={inputValues[index] || ''}
                                          onChange={(e) => handleInputChange(e, index)}
                                          onBlur={() => handleInputBlur(index)}  // Add onBlur event
                                          onFocus={() => handleInputFocus(index)}
                                          error={!!inputErrors[index]}
                                          style={{ backgroundColor: '#FCFCFC', top: "-5px" }} // Inline style for background color
                                        />
                                        {inputErrors[index] && <AlertTriangle className="alert-icon" />}
                                        {inputErrors[index] && tooltipVisibility[index] && // Check tooltipVisibility
                                          <div className="error-tooltip" onClick={() => handleTooltipClick(index)}>
                                            <ErrorTooltip/>
                                          </div>
                                        }
                                      </div>
                                    }
    
                                  </div>
                                ))
                              }
                        </div>
                      </div>
                    </div>
                  </div>
                    ))
                  }
                  </div>
                  
                </>
                
                ) : (
 
                  <div className="ModulesEvaluate__container__right__success">
                     <div className="ModulesEvaluate__container__right__success__header">
                      Thank you!
                     </div>
                     <div className="ModulesEvaluate__container__right__success__subtitle">
                      Your results have been recorded.
                     </div>
                     <div className="ModulesEvaluate__container__right__success__image">
                      <RocketEval/>
                     </div>
                  </div>

                )}

                <div className="ModulesEvaluate__container__right__footer">
                    <SaveButton 
                        text={step === 1 ? 'Finish' : 'Next'}
                        disabled={isButtonDisabled}  // Updated this line
                        action={handleSave}
                      />
                  </div>
             
            
            </div>
          </div>

        </div>
        )
      }
    </>
  )
}
