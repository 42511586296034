import  { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg';
import { ReactComponent as BigRocket } from '../../../../../images/big-rocket.svg';
import { setCurrentPage } from '../../../../../redux/slices/coursesSlice';
import ConfirmButton from '../../../../Buttons/ConfirmButton/ConfirmButton';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import { updateCourseAnswers, setQuiz as updateQuizAnswers, setReloadReport } from '../../../../../redux/slices/coursesSlice';
import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
import StepThree from './Steps/StepThree';
import StepFour from './Steps/StepFour';
import Stepper from '../../../../Stepper/Stepper';
import SaveButton  from '../../../../Buttons/SaveButton/SaveButton';
import CoursesAlert from '../../../../Modals/CoursesAlert/CoursesAlert';
import { useModules } from '../../../../../hooks/useModules';
import './ModulesQuiz.scss';  


type Assessment_RightOptions = {
  question: string;
  options: string[];
  answer: number[];
  userAnswer: number[];
}

type Assessment_TrueFalse = {
  question: string;
  answer: boolean;
  userAnswer: boolean;
}

type Assessment_FillBlank = {
  question: string;
  options: string[];
  answer: number;
  userAnswer: number;
}

type Assessment_Matching = {
  statement: string;
  match: string;
  userAnswer: string;
}[]

type Quiz = {
  type: "right_options" | "true_false" | "fill_blank" | "matching";
  content: Assessment_RightOptions | Assessment_TrueFalse | Assessment_FillBlank | Assessment_Matching[] | any;
}[]
const initialQuizData: Quiz = [
  {
    type: "right_options",
    content: {
      question: "What are the primary colors?",
      options: ["Red", "Green", "Blue", "Yellow"],
      answer: [1, 3, 4], // Assuming options are 1-indexed
      userAnswer: [1, 3, 4]
    }
  },
  {
    type: "true_false",
    content: {
      question: "The sky is green.",
      answer: false,
      userAnswer: false
    }
  },
  {
    type: "fill_blank",
    content: {
      question: "The capital of France is {input}.",
      options: ["Paris", "Madrid", "Berlin"],
      answer: 1, // Assuming options are 1-indexed
      userAnswer: 1
    }
  },
  {
    type: "matching",
    content: [
      { statement: "Water freezes at", match: "0 degrees Celsius", userAnswer: "0 degrees Celsius" },
      { statement: "Boiling point of water", match: "100 degrees Celsius", userAnswer: "100 degrees Celsius" },
      { statement: "Humans breathe", match: "Oxygen", userAnswer: "Oxygen" },
      { statement: "The sun is a", match: "Star", userAnswer: "Star" }
    ]
  },
  {
    type: "fill_blank",
    content: {
      question: "The capital of France is {input}.",
      options: ["Paris", "Madrid", "Berlin"],
      answer: 1, // Assuming options are 1-indexed
      userAnswer: 1
    }
  },
  {
    type: "matching",
    content: [
      { statement: "Water freezes at", match: "0 degrees Celsius", userAnswer: "0 degrees Celsius" },
      { statement: "Boiling point of water", match: "100 degrees Celsius", userAnswer: "100 degrees Celsius" },
      { statement: "Humans breathe", match: "Oxygen", userAnswer: "Oxygen" },
      { statement: "The sun is a", match: "Star", userAnswer: "Star" }
    ]
  }
];
  

interface StepFourPreparedData {
  questions: string[];
  matches: MatchingContent[]; // Changed to 'matches' to better reflect the content and match the usage in your function
}

interface MatchingContent {
  match: string;
  label: string;
  originalIndex: number;
} 

interface Matching {
  statement: string;
  match: string;
  originalIndex: number;  
}



export default function ModulesQuiz() {
  const { enableStep } = useModules();

  useEffect(() => {
    enableStep('module-report');
  }, []);

  const getLetter = (index: number) => {
    return String.fromCharCode(65 + index);
  }; 
  const generateRandomOrder = () => {
    const array = [0, 1, 2, 3]; // Create a copy of the defaultOrder
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }
  
  // Example usage
  const defaultOrder = useMemo(() => generateRandomOrder(), []);
  const [alertModal, setAlertModal] = useState(false);
  const [order, setOrder] = useState<number[]>(defaultOrder);
  const [currentStep, setCurrentStep] = useState(0);
  const [finishModal, setFinishModal] = useState(false);
  const dispatch = useDispatch<any>();
  const { loading, activeCourse } = useSelector((state: RootState) => state.courses);
  const [quiz, setQuiz] = useState<Quiz>(activeCourse?.quiz || initialQuizData);   

  const prepareStepFourData = (matchingQuestions: Matching[]): StepFourPreparedData => {   
    const questions = matchingQuestions.map((q) => q.statement);
    const matchingAnswers = matchingQuestions.map((q, index) => ({ 
      match: q.match,  
      originalIndex: defaultOrder[index],
      label: getLetter(defaultOrder[index]) 
    }));
 
    return {
      questions: questions,
      matches: matchingAnswers,
    }
  };
  
  const handleUpdateAnswer = (questionIndex: number, newAnswer: any) => { 
    updateAnswer(questionIndex, newAnswer);
  };

  const updateAnswer = (questionIndex: number, newAnswer: any) => {
    setQuiz((currentQuiz) => {
      return currentQuiz.map((question, index) => {
        // Only update the question that matches the specified index
        if (index === questionIndex) {
          // Deep clone the content to avoid directly mutating state
          let updatedContent = JSON.parse(JSON.stringify(question.content));
  
          // Update the userAnswer based on the question type
          switch (question.type) {
            case "right_options":
            case "fill_blank":
              // Assuming newAnswer is the correct format for these types
              updatedContent.userAnswer = newAnswer;
              break;
            case "true_false":
              // Assuming newAnswer is a boolean for this type
              updatedContent.userAnswer = newAnswer;
              break;
            case "matching":
              // For matching, newAnswer should include both statement and match
              // Here you may need a more complex logic depending on how newAnswer is structured
              updatedContent.userAnswer = newAnswer;
              break;
            default:
              // Handle any unsupported types or log a warning
              console.warn(`Unsupported question type: ${question.type}`);
          }
  
          // Return the updated question with new content
          return { ...question, content: updatedContent };
        }
  
        // Return other questions unmodified
        return question;
      });
    });
    dispatch(updateQuizAnswers(quiz));
    dispatch(setReloadReport(true));
  };

  const handleUpdateMatchingAnswer = (questionIndex: number) => { 
    setQuiz((currentQuiz) => {
      return currentQuiz.map((question, idx) => {
        if (idx === questionIndex && question.type === "matching") {
          // Ensure that we are working with a matching question
          let updatedContent = [...question.content];
  
          // Update each matching question's userAnswer based on the current order
          const newContent = updatedContent.map((matchingQuestion, index) => { 
            const originalOrderIndex = order[index];
            const matchBasedOnOrder = updatedContent.find((_, findIndex) => findIndex === originalOrderIndex);
  
            return {
              ...matchingQuestion,
              // Update userAnswer with the match from the order-based originalIndex
              userAnswer: matchBasedOnOrder ? matchBasedOnOrder.match : matchingQuestion.userAnswer,
            };
          });
  
          return { ...question, content: newContent };
        }
        return question;
      });
    });
    dispatch(updateQuizAnswers(quiz));
  };

  const updateOrder = (newOrder: number[]) => {
    setOrder(newOrder);
    handleUpdateMatchingAnswer(currentStep);
  }

  const isNextButtonEnabled = () => {
    const currentQuestion = quiz[currentStep];
    switch (currentQuestion?.type) {
      case "right_options":
        return currentQuestion.content.userAnswer?.length > 0;
      case "fill_blank":
        return currentQuestion.content.userAnswer || currentQuestion.content.userAnswer === 0;
      case "true_false":
        return currentQuestion.content.userAnswer || currentQuestion.content.userAnswer === false;
      case "matching":
        return order.length === currentQuestion.content.length;
      default:
        return true;
    }
  };
  
  function renderStepContent(quizStep : Quiz[0], index: number) {
    console.log( quizStep, index)
    switch (quizStep.type) {
      case "right_options":
        return <StepOne 
                question={quizStep.content.question}
                options={quizStep.content.options}
                answer={quizStep.content.userAnswer}
                setAnswer={(newAnswer) => handleUpdateAnswer(index, newAnswer)}
                  />;
      case "true_false":
        return <StepTwo 
                question={quizStep.content.question}
                answer={quizStep.content.userAnswer}
                setAnswer={(newAnswer) => handleUpdateAnswer(index, newAnswer)}
                  />;
      case "fill_blank":
        return <StepThree
                question={quizStep.content.question}
                options={quizStep.content.options}
                answer={quizStep.content.userAnswer}
                setAnswer={(newAnswer) => handleUpdateAnswer(index, newAnswer)}
                  />;
      case 'matching': {
        // You need to ensure `quizStep.content` is the expected type
        if (!Array.isArray(quizStep.content)) {
          console.error('Invalid content type for matching questions');
          return null;
        }
        const matchingContent = quizStep.content as Matching[];
        const preparedData = prepareStepFourData(matchingContent); 
        return (
          <StepFour
            key={index}
            question="Match the items correctly."
            options={preparedData.questions}
            answer={preparedData.matches} 
            order={order}
            setOrder={updateOrder} 
          />
        );
      }
      default:
        return <div>Unsupported quiz type</div>;
    }
  }

  return (
    <>
      {loading ? (
        <div className="ModulesQuiz">
          <div className="ModulesQuiz-loader">
          <CourseLoader />
        </div>
        </div>
      ) : (
        <div className="ModulesQuiz">
          {
            finishModal && (
              <CloseModal
                type={'finish'}
                submitText={'Yes'}
                action={() => dispatch(setCurrentPage('module-view'))}
                text={'Are you sure you want to finish?'}
                extraText={'It looks like there are still unanswered questions.'}
                closeNoSave={() => setFinishModal(false)}
                close={() => setFinishModal(false)}
                />
            )
          }
          {
              alertModal &&
              (
                <CoursesAlert
                  page='quiz'
                  close={() =>  { 
                    setAlertModal(false);
                    dispatch(setCurrentPage('module-view'));  
                  } }
                />
              )
            }
          <div className="ModulesQuiz__container">
            <div className="ModulesQuiz__container__left" onClick={() => {
              // dispatch(setCurrentPage('module-view'));
               if (currentStep === 0) {
                dispatch(setCurrentPage('module-view'));
              } else {
                setCurrentStep(currentStep - 1);
              }
            }}>
              <ArrowLeft/>
            </div>
            <div className="ModulesQuiz__container__right">
              { currentStep !==  quiz.length && (
                <>
                <div className="ModulesQuiz__container__right__header">
                  <Stepper currentStep={currentStep+1} stepsLength={quiz.length}/>                  
                </div> 
                <div className="ModulesQuiz__container__right__btn-cont">
                <SaveButton
                    text="Edit"
                    action={() => {
                      dispatch(setCurrentPage('module-quiz-edit'));
                  }}
                    />
              </div>
              </>
                
              )}
              <div className="ModulesQuiz__container__right__body">

                {currentStep < quiz.length && renderStepContent(quiz[currentStep], currentStep)}
                {
                  currentStep === quiz.length && (
                    <div className="ModulesQuiz__container__right__body__success">
                      <div className="ModulesQuiz__container__right__body__success__img">
                      <BigRocket/> 
                      </div>
                     
                      <div className="ModulesQuiz__container__right__body__success__title">
                        Congratulations!
                      </div>
                      <div className="ModulesQuiz__container__right__body__success__subtitle">
                        Click on "Continue" to be taken to your evaluation.
                      </div>
                    </div>
                  )
                }  
                              
              </div>
              <div className="ModulesQuiz__container__right__footer"> 
               {/* { currentStep <  quiz.length && <ConfirmButton
                    text='Finish' 
                    action={() => handleFinishClick()}
                  />} */}
                <ConfirmButton
                    text={"Next"}
                    disable={ 
                      !isNextButtonEnabled()
                    }
                    action={() => {
                      setOrder(defaultOrder); 
                      handleUpdateMatchingAnswer(currentStep); 
                      if(currentStep === quiz.length){ 
                        dispatch(updateCourseAnswers({quiz: quiz}));
                        enableStep('module-report');
                        if(activeCourse?.evaluationAnswers && 
                          Object.keys(activeCourse?.evaluationAnswers).length === activeCourse?.evaluation?.length
                        ){
                          dispatch(setCurrentPage('module-report'));
                        } else{
                          setAlertModal(true);
                        }
                       
                      } 
                      
                      setCurrentStep(currentStep + 1)
                    }}
                   
                  />
                
              </div>
            </div>

          </div>

        </div>
        )
      }
    </>
  )
}
