import React from 'react'
import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import CloseIcon from '@mui/icons-material/Close';
import './CloseModal.scss';

type Props = {
  type?: string,
  action: any,
  submitText?: string,
  text: string,
  extraText?: string,
  closeNoSave: any,
  close: any
  customButtonStyles?: React.CSSProperties
  singleButton?: boolean
};

export default function CloseModal({
  type,
  submitText,
  action,
  text,
  extraText,
  closeNoSave,
  close,
  customButtonStyles,
  singleButton
 }: Props) {

  return (
    <div className="CloseModal">
      <div className={`CloseModal__wrapper ${type ? type : ''}`}>
        <div
          className="CloseModal__close"
          onClick={close}
        >
          <CloseIcon
            sx={{
              color: '#5A6861'
            }}
          />
        </div>
        {
          extraText ? <><h1>{text}</h1> <p>{extraText}</p></>
          : <p>{text}</p>
        }
        
        <div className="CloseModal__btns">
          <ConfirmButton
            customStyles={customButtonStyles}
            text={submitText || "Save"}
            action={action}
          />
         {!singleButton && <div
            className="CloseModal__btn"
            onClick={closeNoSave} 
          >
            No
          </div>}
        </div>
      </div>
    </div>
  )
}
