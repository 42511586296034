import React from 'react';
import { ReactComponent as GoogleIcon } from '../../../images/googleIcon.svg';
import './GoogleButton.scss';

interface GoogleButtonProps {
  onClick: () => void,
  disabled?: boolean,
}

function GoogleButton({ onClick, disabled = false }: GoogleButtonProps) {
  return (
    <button
      className="GoogleButton"
      onClick={onClick}
      disabled={disabled}>
      <GoogleIcon className="GoogleButton__icon" />
      <span className="GoogleButton__text">
        {`Log In with Google`}
      </span>
    </button>
  );
}

export default GoogleButton;