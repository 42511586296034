import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, Modifier, SelectionState, ContentState, RichUtils, CharacterMetadata } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../../../../../App";
import { clearAddedText, endLoading, updateCurrentSelection } from "../../../../../redux/slices/scriptsSlice";
import { RootState } from "../../../../../redux/store";
import { docEditorToolbar } from "./DocEditorSettings/DocEditorSettings";
import './DocEditor.scss';

type Props = {
  text: string;
  change: any,
  onChange: (text: string) => void,
  styled: any
};

const styleMap = {
  "fontSize-24": {
    fontSize: '24px',
  },
  "fontSize-16": {
    fontSize: '16px',
  },
};

const DocsTextEditor = ({ text, onChange, change, styled }: Props) => {
  const dispatch = useDispatch();
  const newSocket = useSocket();
  
  const [pastedText, setPastedText] = useState('');
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );
  const { 
    addedText, 
    currentSelectionData,
  } = useSelector((state: RootState) => state.scripts);
  const selectionState = editorState.getSelection();

  const applyFontSizeToBlock = (contentBlock: any) => {
    const type = contentBlock.getType();
    const styleToApply = type === 'header-two' ? 'fontSize-24' : 'fontSize-16';
    
    const charList = contentBlock.getCharacterList();
    const styledCharList = charList.map((char: any) => CharacterMetadata.applyStyle(char, styleToApply));
    
    return contentBlock.set('characterList', styledCharList);
  };

  useEffect(() => {
    newSocket?.on('generate-more-content-doc:end', (data: any) => {
      dispatch(endLoading());
        const contentState = editorState.getCurrentContent();
        const newHtml = data.doc.replaceAll("\n<p>_</p>\n<ul>", "\n<ul>").replace("\n<p>_</p>\n", "\n");
        const newContentState = convertFromHTML(newHtml);

        const styledContentBlocks = newContentState.contentBlocks.map(block => {
          const text = block.getText().replace(/_/g, "") + " ";
          const modifiedBlock = block.set("text", text);
          return applyFontSizeToBlock(modifiedBlock);
        });

        const newContentStateWithBlock = ContentState.createFromBlockArray(
          styledContentBlocks,
          newContentState.entityMap,
        );

        if (selectionState.getAnchorOffset() || selectionState.getFocusOffset()) {
          const updatedContentState = Modifier.replaceWithFragment(
            contentState,
            selectionState,
            newContentStateWithBlock.getBlockMap()
          );

          const newEditorState = EditorState.push(
            editorState,
            updatedContentState,
            'insert-fragment'
          );

          setEditorState(newEditorState);
        }
    });
  }, [selectionState]);

  useEffect(() => {
    if (addedText && addedText !== pastedText) {
      setPastedText(addedText);

      const currentContent = editorState.getCurrentContent();
      const newContentState = convertFromHTML(addedText);
      dispatch(clearAddedText());

      const styledContentBlocks = newContentState.contentBlocks.map(block => {
        const text = block.getText().replace(/_/g, " ");
        const modifiedBlock = block.set("text", text);
        return applyFontSizeToBlock(modifiedBlock);
      });

      const newContentStateWithBlock = ContentState.createFromBlockArray(
        styledContentBlocks,
        newContentState.entityMap
      );

      if (currentSelectionData) {
        const selection = new SelectionState({
          anchorKey: currentSelectionData.anchorKey,
          anchorOffset: currentSelectionData.anchorOffset,
          focusKey: currentSelectionData.focusKey,
          focusOffset: currentSelectionData.focusOffset,
          isBackward: currentSelectionData.isBackward,
          hasFocus: currentSelectionData.hasFocus,
        });
  
        const newContent = Modifier.replaceWithFragment(
          currentContent,
          selection,
          newContentStateWithBlock.getBlockMap()
        );
  
        const newEditorState = EditorState.push(editorState, newContent, 'insert-fragment');
        setEditorState(EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()));
      } else {
        const blockMap = currentContent.getBlockMap();
        const key = blockMap.last().getKey();
        const length = blockMap.last().getLength();
        const selection = new SelectionState({
          anchorKey: key,
          anchorOffset: length,
          focusKey: key,
          focusOffset: length,
        });
        
        const newContent = Modifier.replaceWithFragment(
          currentContent,
          selection,
          newContentStateWithBlock.getBlockMap()
        );
  
        const newEditorState = EditorState.push(editorState, newContent, 'insert-fragment');
        setEditorState(EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()));
      }

    }
  }, [addedText]);

  const createState = (t: string) => {
    const blocksFromHTML = convertFromHTML(t);

    const bloks = [...JSON.parse(JSON.stringify(blocksFromHTML.contentBlocks))].map((item: any, i: number) => {
      const firsWord = item?.text?.split(' ')[0];
      const firstTwoWords = item?.text?.split(' ').slice(0, 2).join(' ');
      if (item.type === "header-one") {
        return {
          ...item,
          text: item?.text + '\n',
          "inlineStyleRanges": [
            {
              "style": 'fontsize-30',
              "offset": 0,
              "length": item?.text?.length
            },
            {
              "offset": 0,
              "length": item?.text?.length,
              "style": "BOLD"
            }
          ]
        }
      } else if (item.type === "header-two") {
        return {
          ...item,
          text: '\n' + item?.text,
          "inlineStyleRanges": [
            {
              "style": 'fontsize-24',
              "offset": 0,
              "length": item?.text?.length + 1
            },
          ]
        }
      } else if (item.type === "header-three") {
        return {
          ...item,
          text: '\n' + item?.text,
          "inlineStyleRanges": [
            {
              "style": 'fontsize-24',
              "offset": 0,
              "length": item?.text?.length + 1
            },
          ]
        }
      } else if (firsWord === 'Quote:' || firsWord === 'Statistic:' || firsWord === 'Introduction:' || firsWord ===  'Questions:') {
        return {
          ...item,
          text: '\n' + item?.text,
          "inlineStyleRanges": [
            {
              "offset": 0,
              "length": firsWord.length,
              "style": "BOLD"
            },
            {
              "style": 'fontsize-16',
              "offset": 0,
              "length": item?.text?.length
            }
          ]
        }
      } else if (firstTwoWords === 'Action Items:') {
        return {
          ...item,
          text: '\n' + item?.text,
          "inlineStyleRanges": [
            {
              "offset": 0,
              "length": firstTwoWords.length,
              "style": "BOLD"
            },
            {
              "style": 'fontsize-16',
              "offset": 0,
              "length": item?.text?.length
            }
          ]
        }
      } else if (item?.text === "Action Items:") {
        return {
          ...item,
          text: '\n' + item?.text,
          "inlineStyleRanges": [
            {
              "offset": 0,
              "length": item?.text?.length,
              "style": "BOLD"
            },
            {
              "style": 'fontsize-16',
              "offset": 0,
              "length": item?.text?.length
            }
          ]
        }
      } else {
        return {
          ...item,
          text: item?.type === 'unordered-list-item' ? item?.text : '\n' + item?.text,
          "inlineStyleRanges": [
            {
              "style": 'fontsize-16',
              "offset": 0,
              "length": item?.text?.length
            },
          ]
        }
      }
    
    });

    const resObj = {
      "entityMap": {},
      "blocks": bloks
    }

    if (!styled) {
      onChange(JSON.stringify(resObj));
    }
    return EditorState.createWithContent(convertFromRaw(resObj));
  };

  useEffect(() => {
    if (text) {
      setEditorState(createState(text));
    }
  }, [text]);

  useEffect(() => {
    if (change) {
      setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(change))));
    }
  }, [change]);

  const onEditorStateChange = (e: any) => {
    setEditorState(e);
    const currentSelection = editorState.getSelection();
    const selectionData = {
      anchorKey: currentSelection.getAnchorKey(),
      anchorOffset: currentSelection.getAnchorOffset(),
      focusKey: currentSelection.getFocusKey(),
      focusOffset: currentSelection.getFocusOffset(),
      isBackward: currentSelection.getIsBackward(),
      hasFocus: currentSelection.getHasFocus(),
    };

    dispatch(updateCurrentSelection(selectionData));
  };

  return (
    <Editor
      customStyleMap={styleMap}
      editorState={editorState}
      wrapperClassName="doc-wrapper"
      editorClassName="doc-editor"
      toolbarClassName="doc-toolbar"
      onEditorStateChange={onEditorStateChange}
      onBlur={() => onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())))}
      toolbar={docEditorToolbar}
    />
  );
};

export default DocsTextEditor;
