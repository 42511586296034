import { useDispatch, useSelector} from 'react-redux';
import { useState, useEffect } from 'react';
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg';
import { ReactComponent as Key} from '../../../../../images/key-round.svg';
import { setCurrentPage } from '../../../../../redux/slices/sharedSlice';
import { useSharedModules } from '../../../../../hooks/useModules';
import WhiteButton from '../../../../Buttons/WhiteButton/WhiteButton';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import './SharedKeyTerms.scss';
import { use } from 'passport';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';

export default function SharedKeyTerms() {

  const dispatch = useDispatch<any>();
  const { loading } = useSelector((state: RootState) => state.shared);
  const { keyTerms, menu } = useSelector((state: RootState) => state?.shared?.activeCourse) || {};
  const { enableNextStep, hideEnableNextStep } = useSharedModules();
  const [hideModal, setHideModal] = useState<boolean>(false);

  useEffect(() => {
    enableNextStep('shared-key-terms');
  }, []);
  const onCancel = () => {
    setHideModal(false); 
  }
  const onHide = () => {
    hideEnableNextStep('module-key-terms', 'module-education'); 
    setHideModal(false);
    dispatch(setCurrentPage("shared-view"));
  }
 
  return (
    <>
      {loading ? (
        <div className="SharedKeyTerms">
          <div className="SharedKeyTerms__container-loader">
          <CourseLoader />
        </div>
        </div>
      ) : (
        <div className="SharedKeyTerms">
            {
              hideModal && (
                <CloseModal
                  type={'hide'}
                  submitText={'Yes'}
                  action={onHide}
                  text={'Would you like to hide selected tabs, making them no longer visible?'}
                  closeNoSave={onCancel}
                  close={()=> setHideModal(false)}
                  />
              )
            } 
          <div className="SharedKeyTerms__container">
            <div className="SharedKeyTerms__container__left" onClick={() => { 
              dispatch(setCurrentPage('shared-view'))
              }}>
              <ArrowLeft/>
            </div>
            <div className="SharedKeyTerms__container__right">
              <div className="SharedKeyTerms__container__right__header">
              <div className="SharedKeyTerms__container__right__header__title">
                There are {keyTerms?.length} key terms you should know and pay attention to:
                </div>
                <div className="SharedKeyTerms__container__right__header__btns" >
                  {/* <WhiteButton
                        text="Hide"
                        className='WhiteButton--p15-24'
                        action={() => {
                          setHideModal(true);
                        }}
                        /> */}
                </div>
              </div>
              <div className="SharedKeyTerms__container__right__body">
                {
                  keyTerms?.map((term, index) => (
                    <div key={index}>
                      <div className="SharedKeyTerms__container__right__body__term" >
                        <div className="SharedKeyTerms__container__right__body__term__icon">
                          <Key/>
                        </div>
                        <div className="SharedKeyTerms__container__right__body__term__block">
                          <div className="SharedKeyTerms__container__right__body__term__block__title">
                            {term.title}
                          </div>
                          <div className="SharedKeyTerms__container__right__body__term__block__description">
                            {term.description}
                          </div>
                        </div>
                      </div>
                      { 
                        index !== keyTerms.length -1 &&  
                          <div className="SharedKeyTerms__container__right__body__line"/>
                      }
                         
                  </div>

                  ))
                }
                <div className="ModulesKeyTerms__container__right__body__btn-cont">
                  <SaveButton 
                      text='Finish'
                      action={() => {
                        dispatch(setCurrentPage('shared-view'));
                      }}
                    />
                  </div>
              </div>
            </div>

          </div>

        </div>
        )
      }
    </>
  )
}
