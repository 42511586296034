import axios from 'axios';

const API = `${process.env.REACT_APP_API_URL}api`;

export const uploadImg = (file: any, token: string) => axios.post(`${API}/presentation/upload-logo`, file,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const deleteLogo = (token: string) => axios.delete(`${API}/presentation/delete-logo`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const deleteBg = (token: string) => axios.delete(`${API}/presentation/delete-background`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getTemplates = (body: any, token: string) => axios.get(`${API}/template/get-all-templates?isScript=${body?.isScript}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getPrompts = (body: any,token: string) => axios.get(`${API}/template/get-template-by-category?templateId=${body?.id}&isScript=${body?.isScript}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const savePresentation = (body: any, token: string) => axios.post(`${API}/presentation/update-presentation`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);


export const createOpenAIPresentation = (body: any, token: string) => axios.post(`${API}/open-ai/create-presentation`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const createOpenAIPresentationFromDoc = (body: any, token: string) => axios.post(`${API}/open-ai/generate-presentation-by-doc`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getOpenAIPresentation = (id: number, token: string) => axios.get(`${API}/open-ai/get-presentation?presentation_id=${id}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const updateOpenAISlide = (body: any, token: string) => axios.post(`${API}/open-ai/create-presentation`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);


export const updateOpenAIImage= (body: any, token: string) => axios.post(`${API}/open-ai/regenerate-image`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const updateOpenAIText = (body: any, token: string) => axios.post(`${API}/open-ai/regenerate-text`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const generateOpenAISlide = (body: any, token: string) => axios.post(`${API}/open-ai/generate-slide`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);


export const refreshGoogle = (token: string) => axios.post(`${API}/auth/refresh-google-token`,
{},
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getPresentationsList = (body: any, token: string) => axios.get(`${API}/presentation/get-presentations?take=${body.take || '10'}&skip=${body.skip || '10'}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const deletePresentation = (body: any, token: string) => axios.patch(`${API}/presentation/delete-presentation`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const deleteSlideApi = (body: any, token: string) => axios.post(`${API}/presentation/delete-slide`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const removeLogo = (id: number, token: string) => axios.get(`${API}/presentation/delete-logo-from-slide?slideId=${id}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getImageSettings = () => axios.get(`${API}/open-ai/get-image-settings`,
  {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);