import React, { useEffect, useState} from 'react'; 
import { useDispatch, useSelector } from 'react-redux';  
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg'; 
import { ReactComponent as Rocket} from '../../../../../images/rocket.svg';
import { ReactComponent as Infinity} from '../../../../../images/infinity.svg';
import { setCurrentPage, setLoadingPage } from '../../../../../redux/slices/sharedSlice';
import CirclePercentage from '../../../../CirclePercentage/CirclePercentage';
import { updateAssessmentReport } from '../../../../../redux/slices/sharedSlice';
import { useSocket } from '../../../../../App';
import { submitShared } from '../../../../../api/shareApi';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton'; 
import removeMd from 'remove-markdown';
import ShareSaveAssesment from '../../../../Modals/ShareSaveAssesment/ShareSaveAssesment';
import './SharedReport.scss';

export default function SharedReport() {

  const newSocket = useSocket();
  const dispatch = useDispatch<any>();
  const { activeCourse, loadingPage, courseAnswers, shareUserData } = useSelector((state: RootState) => state.shared);
  const [takeAway, setTakeAway] = useState<string>('');
  const [ learned, setLearned] = useState<string>('');
  const [ when, setWhen] = useState<string>(''); 
  const report = activeCourse && activeCourse.assessmentReport;
  const essayResult = removeMd(activeCourse?.essay?.result || '') ;
  const [showSaveModal, setShowSaveModal] = useState<boolean>(true);

  console.log('sharedReport', newSocket);

  useEffect(() => {

    if(courseAnswers.quiz && courseAnswers.evaluate){
      if (newSocket) {
        dispatch(setLoadingPage(true));
        newSocket.emit('share:assessment', 
          courseAnswers
        );

        newSocket?.on('share:assessment:end', (data: any) => {
          dispatch(updateAssessmentReport(data)); 
          dispatch(setLoadingPage(false));
        });
      }

    } else{
      dispatch(setCurrentPage('shared-view'));
    } 
 
  }, []);

  const submitSharedFunction = async () => { 
    if(activeCourse?.id){
      dispatch(setLoadingPage(true));
      const link = await submitShared({
        sharedUserName: shareUserData.name,
        questions: [
          {
            question: 'What was your biggest takeaway?',
            answer: takeAway
          },
          {
            question: 'What one thing can you do based on what you learned?',
            answer: learned
          },
          {
            question: 'When will you do it?',
            answer: when
          }
         ],
        shareId: shareUserData.shareId,
        sharedUserEmail: shareUserData.email,
        strong: report?.strong ? report?.strong : [],
        improved: report?.improve ? report?.improve : [],
        quiz: report?.quiz ? +report?.quiz : 0,
        evaluation: report?.statements_correct ? +report?.statements_correct : 0,
        essayResult: essayResult || ''
      }); 
      if(link){ 
        dispatch(setCurrentPage('shared-success'));
      }
      dispatch(setLoadingPage(false));
    }
  }
  const finalScore = report?.quiz && +report?.quiz ? +report?.quiz : 0; 
  const initialScore = report?.statements_correct ? +report?.statements_correct : 0;
  const improvementPercentage = finalScore - initialScore; // Improvement from the initial 0%
 


  return (
    <>
      {loadingPage ? (
        <div className="ModulesReport">
          <div className="ModulesReport__container-loader">
          <CourseLoader 
              customText={'Loading Your Course Assessment Report'}
          />
        </div>
        </div>
      ) : (
        <div className="ModulesReport">
           
          <div className="ModulesReport__container">
            <div className="ModulesReport__container__left" onClick={() => dispatch(setCurrentPage('shared-view'))}>
              <ArrowLeft/>
            </div>
            {
              showSaveModal &&
              <ShareSaveAssesment
                close={() => setShowSaveModal(false)}
              />
            }
            <div className="ModulesReport__container__right">
              <div className="ModulesReport__container__right__header">
               Congratulations on reaching the last step of your learning course!
              </div>
              <div className="ModulesReport__container__right__body">

                <div className="ModulesReport__container__right__body__metrics-container">

                  <div className="ModulesReport__container__right__body__metrics-container__block">

                    <div className="ModulesReport__container__right__body__metrics-container__block__header">
                      Evaluation:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__block__percent-display">     
                      <CirclePercentage percentage={report?.statements_correct ? report?.statements_correct : '0%'  } />               
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__block__text"> 
                      At the start of this course, You got {report?.statements_correct ? report?.statements_correct +'%' : '0%'} of the assessment questions correct.  
                    </div>

                  </div>

                  <div className="ModulesReport__container__right__body__metrics-container__block">

                    <div className="ModulesReport__container__right__body__metrics-container__block__header">
                       Quiz results:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__block__percent-display">                    
                      <CirclePercentage percentage={report?.quiz ? report?.quiz : '0%'  } />
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__block__text">
                      At the end of this course, {report?.quiz ? report?.quiz + '%' : '0%'} of your answers in the quiz were correct.             
                    </div>
                    
                  </div>
                 
                </div>

                <div className="ModulesReport__container__right__body__metrics-container__text-block">
                  <div className="ModulesReport__container__right__body__metrics-container__text-block__placement-block">
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__header">
                      Original Evaluation:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                    At the start of this learning course, you got {report?.statements_correct ? report?.statements_correct + '%' : '0%'  } of the evaluation questions correct.
                    </div>
                  </div>
                  <div className="ModulesReport__container__right__body__metrics-container__text-block__placement-block">
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__header">
                      Quiz Results:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                      At the end of this learning course you got  {report?.quiz ? report?.quiz + '%' : '0%'} of the questions correct. 
                      That is a {improvementPercentage.toFixed(2)}% improvement.
                    </div>
                  </div>
                  <div className="ModulesReport__container__right__body__metrics-container__text-block__placement-block">
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__header">
                      Welcome to your personalized performance assessment.
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                      Here is your analysis and three areas in which you truly excelled:
                    </div>

                    <div className="ModulesReport__container__right__body__metrics-container__text-block__list-block">
                    { report?.strong.map((item: string, index: number) => {
                      return (
                        <div key={index} className="ModulesReport__container__right__body__metrics-container__text-block__list-item">
                          <div className="ModulesReport__container__right__body__metrics-container__text-block__list-item__icon">
                            &#9679;
                          </div>
                          <div className="ModulesReport__container__right__body__metrics-container__text-block__list-item__text">
                            {item}
                          </div>
                        </div>
                      )
                    })}
                    </div>
                  </div>
                   
                  <div className="ModulesReport__container__right__body__metrics-container__text-block__placement-block">
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                      While you’ve developed in your understanding of this topic.   
                      <br/>
                      <br/>
                      Here are a few areas that you might consider focusing on as you continue to grow:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__list-block">
                    { 
                      report?.improve.map((item: string, index: number) => {
                        return (
                          <div key={index} className="ModulesReport__container__right__body__metrics-container__text-block__list-item">
                            <div className="ModulesReport__container__right__body__metrics-container__text-block__list-item__icon">
                              &#9679;
                            </div>
                            <div className="ModulesReport__container__right__body__metrics-container__text-block__list-item__text">
                              {item}
                            </div>
                          </div>
                        )
                      })
                    }
                    </div>
                  </div>

                  <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                    Congratulations on completing this eLearning Course. 
                   <br/>
                   <br/>
                    You will receive a full assessment delivered to you shortly
                  </div>
 
                </div>

                <div className="ModulesReport__container__right__body__metrics-container__contact-block">
                  <div className="ModulesReport__container__right__body__metrics-container__contact-block__header">
                    Let us know your insights here!
                  </div>
                  <div className="ModulesReport__container__right__body__metrics-container__contact-block__text">
                    What was your biggest takeaway?
                  </div>
                  <input
                   type="text"
                   className="ModulesReport__container__right__body__metrics-container__contact-block__input" 
                   onChange={(e) => setTakeAway(e.target.value)}
                   />
                  

                  <div className="ModulesReport__container__right__body__metrics-container__contact-block__text">
                    What one thing can you do based on what you learned?
                  </div>
 
                  <input 
                    type="text" 
                    className="ModulesReport__container__right__body__metrics-container__contact-block__input"
                    onChange={(e) => setLearned(e.target.value)}
                    />
                  
                  <div className="ModulesReport__container__right__body__metrics-container__contact-block__text">
                    When will you do it?
                  </div>
                  <input 
                    type="text"
                    className="ModulesReport__container__right__body__metrics-container__contact-block__input" 
                    onChange={(e) => setWhen(e.target.value)}
                    />
 
                   <SaveButton 
                    disabled={takeAway.trim() === '' || learned.trim() === '' || when.trim() === ''}
                    text='Submit'
                    action={submitSharedFunction}
                    />
                </div>
               
              </div>
             
            </div>

          </div>

        </div>
        )
      }
    </>
  )
}
