import React, { useState} from 'react';
import { ReactComponent as GripVerticalIcon } from '../../../../../../images/grip-vertical.svg';
import './Steps.scss';
 

 
interface LabeledMatch {
  label: string;
  match: string; // This should be a string to hold the matching answer text
  originalIndex: number; // Preserved original index for reference
}
 
interface StepFourProps {
  question?: string;
  options: string[]; // Assuming this matches your data structure
  answer: LabeledMatch[]; // This should match the initial structure you have for labeled answers 
  order: number[]; // This should be an array of indexes to match the order of the options
  setOrder: (newOrder: number[]) => void; // Function to update the parent component 
}

const StepFour: React.FC<StepFourProps> = ({
  question,
  options,
  answer,
  order,
  setOrder,
}) => {
 
  const [draggingItemOriginalIndex, setDraggingItemOriginalIndex] = useState<number | null>(null);

 
  const onDrop = (e: React.DragEvent<HTMLDivElement>, targetIndex: number) => {
    e.preventDefault();
    if (draggingItemOriginalIndex === null) return;

    // Find the current index in the order array for the item being dragged.
    const currentDraggingIndex = order.findIndex(index => answer[index].originalIndex === draggingItemOriginalIndex);

    // Exit if the dragging item is not found
    if (currentDraggingIndex < 0) return;

    // If the dragging operation does not change the position, no need to update
    if (currentDraggingIndex === targetIndex) return;

    let newOrder = [...order];

    // Approach: Directly move the item from its current position to the new target position
    // Step 1: Remove the item from the current position
    const [removedItem] = newOrder.splice(currentDraggingIndex, 1);

    // Step 2: Calculate the adjusted target index after the item has been removed
    // Note: When moving downwards, the removal of the item reduces the index of items below it by 1
    // Hence, no adjustment is required. The targetIndex is already in the correct position relative to the new array state
    // When moving upwards, no adjustment is necessary since items above it are not affected by the removal

    // Step 3: Insert the item at the new target position
    newOrder.splice(targetIndex, 0, removedItem);

    // Update the order state with the newly arranged order
    setOrder(newOrder);
};
  
  // Function to handle the start of dragging an answer element
  const onDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    setDraggingItemOriginalIndex(answer[order[index]].originalIndex);
    e.dataTransfer.effectAllowed = 'move';
  };

  // Function to prevent the default behavior in onDragOver to allow dropping
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    // setDraggingIndex(null);
  };

  const onDragEnd = () => { 
    setDraggingItemOriginalIndex(null);
  };



  return (
    <div className="Step">
      <div className="Step__container">
        <div className="Step__container__header">
          <div className="Step__container__header__question">{question}</div>
          <div className="Step__container__header__instruction">
            Drag and drop the terms to match with the correct statements.
          </div>
        </div>
        <div className="Step__container__body__four">
          <div className="Step__container__body__four__left">
            {options?.map((option, index) => (
              <div key={index} className="Step__container__body__four__left__option">
                <div className="Step__container__body__four__left__option__icon">
                  {index + 1}
                </div>
                <div className="Step__container__body__four__left__option__question">
                  {option}
                </div>
              </div>
            ))}
          </div>
          
          <div className="Step__container__body__four__right">
 
            {order.map((orderIndex, index) => (
              <div key={answer[orderIndex].label}
                   draggable
                   className="Step__container__body__four__right__option"
                   onDragStart={(e) => onDragStart(e, index)}
                   onDragOver={onDragOver}
                   onDrop={(e) => onDrop(e, index)}
                   onDragEnd={onDragEnd}>
                <div className="Step__container__body__four__right__option__letter">
                  {answer[orderIndex].label}
                </div>
                <div className="Step__container__body__four__right__option__statement">
                  {answer[orderIndex].match}
                </div>
                <div className="Step__container__body__four__right__option__drag-icon">
                  <GripVerticalIcon />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
