import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as CheckCircle } from '../../../../../images/check-circle-2.svg';
import { ReactComponent as Atom } from '../../../../../images/checkpoints/atom.svg';
import { ReactComponent as Bus } from '../../../../../images/checkpoints/bus.svg';
import { ReactComponent as Pen } from '../../../../../images/checkpoints/pen.svg';
import { ReactComponent as Pin } from '../../../../../images/checkpoints/pin.svg';
import { ReactComponent as Projector } from '../../../../../images/checkpoints/projector.svg';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';
import { setCurrentPage } from '../../../../../redux/slices/sharedSlice';
import './SharedModulesCheckPoints.scss';

export default function SharedModulesCheckPoints() {
  const dispatch = useDispatch<any>();
  const { sections, title } = useSelector((state: RootState) => state?.shared?.activeCourse) || {};
  const { loading } = useSelector((state: RootState) => state.shared);
  const getIcon = (icon: number) => {
    const icons = [<Pen />, <Pin />, <Projector />, <Atom />, <Bus />];
    if (icon >= 0 && icon < icons.length) {
      return icons[icon];
    }
    // Generate a random index for selecting an icon
    const randomIndex = Math.floor(Math.random() * icons.length);
  
    return icons[randomIndex];
  };
  
  return (
    <>
      {loading ? (
        <div className="ModulesCheckPoints">
          <div className="ModulesCheckPoints__container-loader">
          <CourseLoader />
        </div>
        </div>
      ) : (
        <div className="ModulesCheckPoints">
          <div className="ModulesCheckPoints__container">

            <div className="ModulesCheckPoints__container__block">

              <div className="ModulesCheckPoints__container__block__centered">
                <CheckCircle />
              </div>

              <div className="ModulesCheckPoints__container__block__centered ModulesCheckPoints__container__block__header">
                {title}
              </div>

              <div className="ModulesCheckPoints__container__block__item-cont">
                { 
                   sections?.map((section: any, index: number) => (
                    <div className="ModulesCheckPoints__container__block__item-cont__item" key={index}>
                      <div className="ModulesCheckPoints__container__block__item-cont__item__icon">
                        {getIcon(index)}
                      </div>
                      <div className="ModulesCheckPoints__container__block__item-cont__item__title">
                        Section {index + 1}:
                      </div>
                      <div className="ModulesCheckPoints__container__block__item-cont__item__text">
                        {section.title}
                      </div>
                    </div>
                  ))
                }
              </div>


              <div className="ModulesCheckPoints__container__block__centered ModulesCheckPoints__container__block__button">
               <SaveButton 
               text="Let's explore" 
                action={
                  () => {
                    dispatch(setCurrentPage('shared-view'));
                  }
                }
               />
              </div>
            </div>

          </div>

        </div>
        )
      }
    </>
  )
}
