import React, { useEffect, useState} from 'react'; 
import { exportSubmitsAPI, exportAsSCORMAPI } from '../../../../../api/scormApi';
import { useDispatch, useSelector } from 'react-redux';  
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg'; 
import { ReactComponent as Rocket} from '../../../../../images/rocket.svg';
import { ReactComponent as Infinity} from '../../../../../images/infinity.svg';
import { setCurrentPage, setLoadingPage } from '../../../../../redux/slices/coursesSlice';
import CirclePercentage from '../../../../CirclePercentage/CirclePercentage';
import { updateAssessmentReport, saveCourses, setReloadReport } from '../../../../../redux/slices/coursesSlice';
import { useSocket } from '../../../../../App';
import { createShareLink, checkSharedCourse, getSharedInfo, createSharedUser, submitShared } from '../../../../../api/shareApi';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';
import ShareCourseModal from '../../../../Modals/ShareCourseModal/ShareCourseModal';
import { getCookie } from '../../../../../utils/CookieHelper';
import './ModulesReport.scss';

export default function ModulesReport() {
  const api_url = `${process.env.REACT_APP_API_URL}`; 
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const newSocket = useSocket();
  const dispatch = useDispatch<any>();
  const { activeCourse, loadingPage, courseAnswers, reloadReport } = useSelector((state: RootState) => state.courses);
  const { email } = useSelector((state: RootState) => state.scripts.user);
  const token = getCookie('jwtToken');
  const report = activeCourse && activeCourse.assessmentReport;
  const [copied, setCopied] = useState(false);

  useEffect(() => {

    if(activeCourse?.assessmentReport && !reloadReport){
      dispatch(setLoadingPage(false));
      return;
    }
    if(courseAnswers.quiz && courseAnswers.evaluate){
      if (newSocket) {
        dispatch(setLoadingPage(true));
        newSocket.emit('education:assessment', 
          courseAnswers
        );

        newSocket?.on('education:assessment:end', (data: any) => {
          dispatch(setReloadReport(false));
          dispatch(updateAssessmentReport(data));
          dispatch(saveCourses(token));
          dispatch(setLoadingPage(false));
        });
      }

    } else{
      dispatch(setCurrentPage('module-view'));
    } 
 
  }, []);
  // dispatch(setLoadingPage(false));

  const createShareLinkFunction = async () => { 
    if(activeCourse?.id){
      const link = await createShareLink(token, email, activeCourse?.id); 
      setShareLink(api_url + 'shared/' + link.uid + '/' + link.id);
      setShowShareModal(true);
     
    }
  }

  const exportSubmitsFunction = async () => { 
    if(activeCourse?.id){
      const shareLink = await createShareLink(token, email, activeCourse?.id); 
      if(shareLink?.uid){
        const response = await exportSubmitsAPI(token, shareLink.uid);
        console.log(response);
         // Assume this now returns raw CSV data
        
        const link = document.createElement('a');
        link.href = response;
        link.setAttribute('download', `${shareLink.uid}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const exportScormFunction = async () => {
    if(activeCourse?.id){
      const shareLink = await createShareLink(token, email, activeCourse?.id); 
      if(shareLink?.uid){
        const scorm = await exportAsSCORMAPI(token, shareLink.uid);
        console.log(scorm);
        const url = window.URL.createObjectURL(new Blob([scorm]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `course-${shareLink?.uid}.zip`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link && link?.parentNode?.removeChild(link);
      }
    }
  }
  const finalScore = report?.quiz && +report?.quiz ? +report?.quiz : 0;
  const initialScore = report?.statements_correct ? +report?.statements_correct : 0;
  const improvementPercentage = finalScore - initialScore;
 

  return (
    <>
      {loadingPage ? (
        <div className="ModulesReport">
          <div className="ModulesReport__container-loader">
          <CourseLoader 
              customText={'Loading Your Course Assessment Report'}
          />
        </div>
        </div>
      ) : (
        <div className="ModulesReport">
          {
            showShareModal && (
              <ShareCourseModal 
                close={() => setShowShareModal(false)}
                copied={copied}
                action={() =>{
                  navigator.clipboard.writeText(shareLink);
                  setCopied(true);
                }}
                text={shareLink}
              />
            )
          }
          <div className="ModulesReport__container">
            <div className="ModulesReport__container__left" onClick={() => dispatch(setCurrentPage('module-view'))}>
              <ArrowLeft/>
            </div>
            <div className="ModulesReport__container__right">
              <div className="ModulesReport__container__right__header">
               Congratulations on reaching the last step of your learning course!
              </div>
              <div className="ModulesReport__container__right__body">

                <div className="ModulesReport__container__right__body__metrics-container">

                  <div className="ModulesReport__container__right__body__metrics-container__block">

                    <div className="ModulesReport__container__right__body__metrics-container__block__header">
                      Evaluation:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__block__percent-display">     
                      <CirclePercentage percentage={report?.statements_correct ? report?.statements_correct : '0%'  } />               
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__block__text">
                    At the start of this course, You got {report?.statements_correct ? report?.statements_correct +'%' : '0%'} of the assessment questions correct.  
                    </div>

                  </div>

                  <div className="ModulesReport__container__right__body__metrics-container__block">

                    <div className="ModulesReport__container__right__body__metrics-container__block__header">
                       Quiz results:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__block__percent-display">                    
                      <CirclePercentage percentage={report?.quiz ? report?.quiz : '0%'  } />
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__block__text">
                      At the end of this course, {report?.quiz ? report?.quiz + '%' : '0%'} of your answers in the quiz were correct.        
                    </div>
                    
                  </div>
                 
 
                </div>

                <div className="ModulesReport__container__right__body__metrics-container__text-block">
                  <div className="ModulesReport__container__right__body__metrics-container__text-block__placement-block">
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__header">
                      Original Evaluation:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                    At the start of this learning course, you got {report?.statements_correct ? report?.statements_correct + '%' : '0%'  } of the evaluation questions correct.
                    </div>
                  </div>
                  <div className="ModulesReport__container__right__body__metrics-container__text-block__placement-block">
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__header">
                      Quiz Results:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                      At the end of this learning course you got  {report?.quiz ? report?.quiz + '%' : '0%'} of the questions correct. 
                      That is a {improvementPercentage.toFixed(2)}% improvement.
                    </div>
                  </div>
                  <div className="ModulesReport__container__right__body__metrics-container__text-block__placement-block">
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__header">
                      Welcome to your personalized performance assessment.
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                      Here is your analysis and few areas in which you truly excelled:
                    </div>

                    <div className="ModulesReport__container__right__body__metrics-container__text-block__list-block">
                    { report?.strong.map((item: string, index: number) => {
                      return (
                        <div key={index} className="ModulesReport__container__right__body__metrics-container__text-block__list-item">
                          <div className="ModulesReport__container__right__body__metrics-container__text-block__list-item__icon">
                            &#9679;
                          </div>
                          <div className="ModulesReport__container__right__body__metrics-container__text-block__list-item__text">
                            {item}
                          </div>
                        </div>
                      )
                    })}
                    </div>
                  </div>
                   
                  <div className="ModulesReport__container__right__body__metrics-container__text-block__placement-block">
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                      While you’ve developed in your understanding of this topic.   
                      <br/>
                      <br/>
                      Here are a few areas that you might consider focusing on as you continue to grow:
                    </div>
                    <div className="ModulesReport__container__right__body__metrics-container__text-block__list-block">
                    { 
                      report?.improve.map((item: string, index: number) => {
                        return (
                          <div key={index} className="ModulesReport__container__right__body__metrics-container__text-block__list-item">
                            <div className="ModulesReport__container__right__body__metrics-container__text-block__list-item__icon">
                              &#9679;
                            </div>
                            <div className="ModulesReport__container__right__body__metrics-container__text-block__list-item__text">
                              {item}
                            </div>
                          </div>
                        )
                      })
                    }
                    </div>
                  </div>

                  <div className="ModulesReport__container__right__body__metrics-container__text-block__text">
                    Congratulations on completing this eLearning Course. 
                   <br/>
                   <br/>
                    You will receive a full assessment delivered to you shortly
                  </div>
 
                </div>

                <div className="ModulesReport__container__right__body__metrics-container__contact-block">
                  <div className="ModulesReport__container__right__body__metrics-container__contact-block__header">
                    Let us know your insights here!
                  </div>
                  <div className="ModulesReport__container__right__body__metrics-container__contact-block__text">
                    What was your biggest takeaway?
                  </div>
                  <input type="text" className="ModulesReport__container__right__body__metrics-container__contact-block__input" />
                  

                  <div className="ModulesReport__container__right__body__metrics-container__contact-block__text">
                    What one thing can you do based on what you learned?
                  </div>
 
                  <input type="text" className="ModulesReport__container__right__body__metrics-container__contact-block__input" />
                  
                  <div className="ModulesReport__container__right__body__metrics-container__contact-block__text">
                    When will you do it?
                  </div>
                  <input type="text" className="ModulesReport__container__right__body__metrics-container__contact-block__input" />
                  
                    <div className="ModulesReport__container__right__body__metrics-container__contact-block__btn-block">

                    <SaveButton 
                      text='Share'
                      action={createShareLinkFunction}
                    />
                    <SaveButton 
                      text='Export as SCORM'
                      action={exportScormFunction}
                    />
                    <SaveButton 
                      text='Export submits'
                      action={exportSubmitsFunction}
                    />
                    </div>
                </div>
 
              </div>
            </div>

          </div>

        </div>
        )
      }
    </>
  )
}
