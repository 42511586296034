import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    getUserChats,
    getChatHistory,
    deleteUserChat,
    createChat,
    makeVoice,
    editChatName
}  from '../../api/trainerBotApi';

import { playAudio } from '../../utils/playAudio';
import { greetingAudioPath } from '../../data';

export interface Message {
    id: string;
    content: string;
    voiceLink: string;
    role: 'system' | 'assistant' | 'user';
    created_at: string;
}
  
export interface Chat {
    id: number;
    title: string;
    created_at: string;
}

  export interface SlidesState {
    chats: Chat[];
    chatHistory: Message[];
    chatTitle: string;
    loading: boolean;
    loadingPage: boolean;
    loadingMessage: boolean;
    error: string
    chat_id: number | null;
  }
  

const initialState: SlidesState = {
  chats: [],
  chat_id: null,
  chatTitle: '',
  chatHistory: [],
  loading: false,
  loadingPage: false,
  loadingMessage: false,
  error: ''
};



export const fetchChats = createAsyncThunk(
  'trainerBot/getChats',
  async (data: { email: string | undefined, token: string  | undefined}, { rejectWithValue, dispatch }) => {
    const { email, token } = data;
    const body =  {email}

    dispatch(startLoading());
    try {
      const res = await getUserChats(body, token);
      if (!res.length) {
        dispatch(createNewChat({email, token: token, title: 'Training Chat'}));
      } else{
        // TODO refactor here and check if chat_id is null
        dispatch(setChatId(res.length ? res[0].id : null));
        dispatch(getHistory({chat_id: res.length ? res[0].id : null, token: token}));
      }


      dispatch(endLoading());
      return res;
    } catch (error) {
      dispatch(endLoading());
      console.log(error);
    };
  }
);
export const renameChat = createAsyncThunk(
    'trainerBot/renameChat',
    async (data: { email: string, chat_id: number| null, title: string, token: string  | undefined}, { rejectWithValue, dispatch }) => {
      const { token, chat_id, title, email} = data;
      const body =  {chat_id, title}
  
      dispatch(startLoading());
      try {
        const res = await editChatName(body, token);
        dispatch(fetchChats({ email, token }));
        dispatch(setChatTitle(title));
        dispatch(endLoading());
        return res;
      } catch (error) {
        dispatch(endLoading());
        dispatch(addMessage({id: Date.now().toString(), content: "Some error occured while renaming chat", voiceLink: "", role: "system", created_at: new Date().toISOString()}))
        // throw error;
        // console.log(error);
      };
    }
  );

export const getHistory = createAsyncThunk(
  'trainerBot/getHistory',
  async (data: {chat_id: number, token: string | undefined}, { rejectWithValue, dispatch }) => {
    const { chat_id, token } = data;
    const body =  {chat_id}
    dispatch(startLoading());
    try {
      const res = await getChatHistory(body, token);
      dispatch(endLoading());
      return res;
    } catch (error) {
      dispatch(endLoading());
      console.log(error);
    };
  }
);

export const removeChat = createAsyncThunk(
    'trainerBot/deleteChat',
    async (data: { email: string, chat_id: number, token: string | undefined }, { rejectWithValue, dispatch }) => {
      const { chat_id, token, email } = data;
      const body = { chat_id };
  
      dispatch(startLoading());
      try {
        const res = await deleteUserChat(body, token);
        dispatch(fetchChats({ email, token }));
        dispatch(setChatId(null));        
        dispatch(endLoading());
        deleteChat(chat_id);
        return res;
      } catch (error) {
        dispatch(endLoading());
        console.log(error);
      }
    }
);
  
export const createNewChat = createAsyncThunk(
    'trainerBot/createNewChat',
    async (data: { title: string, email: string | undefined, token: string| undefined }, { rejectWithValue, dispatch }) => {
      const { title, email, token } = data;
      const body = { title, email };
  
      dispatch(startLoading());
      try {
        const res = await createChat(body, token);
        dispatch(fetchChats({ email, token }));
        dispatch(setChatId(res.chatId));
        dispatch(getHistory({chat_id: res.chatId , token: token}))
        dispatch(endLoading());
        playAudio(greetingAudioPath);
        console.log(res)
        return res;
      } catch (error) {
        dispatch(endLoading());
        console.log(error);
      }
    }
);
  
export const generateVoice = createAsyncThunk(
    'trainerBot/generateVoice',
    async (data: { chat_id: string, chat_message_id: string, token: string }, { rejectWithValue, dispatch }) => {
      const { chat_id, chat_message_id, token } = data;
      const body = { chat_id, chat_message_id };
  
      dispatch(startLoading());
      try {
        const res = await makeVoice(body, token);
  
        dispatch(endLoading());
        return res;
      } catch (error) {
        dispatch(endLoading());
        console.log(error);
      }
    }
);
  

export const trainerBotSlice = createSlice({
  name: 'trainerBot',
  initialState,
  reducers: {
    getChats: (state, action) => {
        state.chats = [...action.payload]
      },
    addChat: (state, action) => {
      state.chats = [...state.chats, action.payload]
    },
    deleteChat: (state, action) => {
        const { id } = action.payload;
        const newList = state.chats.filter((a: any) => a.id !== id)
        state.chats = newList;
    },
    addMessage: (state, action) => {
        state.chatHistory = [...state.chatHistory, action.payload]
        if(action?.payload?.role === 'assistant'){
          state.loadingMessage = false;
          state.loading = false;
        }
    },
    startLoading: (state) => {
        state.loading = true;
    },
    endLoading: (state) => {
        state.loading = false;
    },
    startLoadingPage: (state) => {
        state.loadingPage = true;
    },
    endLoadingPage: (state) => {
        state.loadingPage = false;
    },
    setLoadingMessage: (state, action) => {
        state.loadingMessage = action.payload;
    },
    setError: (state, action) => {
        state.error = action.payload;
    },
    setChatTitle: (state, action) => {
        state.chatTitle = action.payload;
    },
    setChatId: (state, action) => {
        state.chat_id = action.payload;
    },
    setChatHistory: (state, action) => {
        state.chatHistory = action.payload;
    }

      
      

  },

  extraReducers: (builder) => {
    builder.addCase(fetchChats.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchChats.fulfilled, (state, action) => {
        state.loading = false;
        state.chats = action.payload;
      });
      builder.addCase(fetchChats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch chats.';
      });
      builder.addCase(getHistory.pending, (state) => {
        state.loadingPage = true;
      });
      builder.addCase(getHistory.fulfilled, (state, action) => {
        state.loadingPage = false;
        if (action.payload.length > 0) {
            action.payload.shift(); // Remove the first element
          }        
        state.chatHistory = action.payload;
      });
      builder.addCase(getHistory.rejected, (state, action) => {
        state.loadingPage = false;
        state.error = action.error.message || 'Failed to fetch chat history.';
      });
      builder.addCase(removeChat.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(removeChat.fulfilled, (state, action) => {
        state.loading = false;
        state.chatHistory = [];
        state.chatTitle = '';
        
      });
      builder.addCase(removeChat.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to delete chat.';
      });
      builder.addCase(createNewChat.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createNewChat.fulfilled, (state, action) => {
        state.loading = false;
        state.chatTitle = 'Training Chat';
      });
      builder.addCase(createNewChat.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create chat.';
      });
  
      builder.addCase(generateVoice.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(generateVoice.fulfilled, (state, action) => {
        state.loading = false;
        // Handle successful voice generation if needed
      });
      builder.addCase(generateVoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to generate voice.';
      });
      builder.addCase(renameChat.pending, (state) => {
        state.loading = true;
      });
      
      builder.addCase(renameChat.fulfilled, (state, action) => {
        state.loading = false;
        
        const { chat_id, title } = action.payload;
        const chatIndex = state.chats.findIndex((chat) => chat.id === chat_id);
        if (chatIndex !== -1) {
          state.chats[chatIndex].title = title;
        }
      });
      
      builder.addCase(renameChat.rejected, (state, action) => {
        state.loading = false;
        state.error = 'Failed to rename chat.';
      });
      

  }
});

export const {
    getChats,
    addChat,
    deleteChat,
    addMessage,
    startLoading,
    endLoading,
    startLoadingPage,
    endLoadingPage,
    setLoadingMessage,
    setError,
    setChatTitle,
    setChatId,
    setChatHistory

} = trainerBotSlice.actions;

export default trainerBotSlice.reducer;