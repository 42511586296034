import React from 'react'
import logo from '../../../images/LanddaiLogo.png';
import './EmptySection.scss'

export default function EmptySection() {
  return (
    <div className="EmptySection">
      <img
        src={logo}
        alt="image"
        className="EmptySection__image"
      />
      <h2 className="EmptySection__title">
        COMING SOON  
      </h2>
      <p className="EmptySection__text">
        The page is coming soon! We’re working hard <br /> to give you the best experience!   
      </p>
    </div>
  )
}
