import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../../../../utils/CookieHelper';
import { CurrentPageType } from '../../../../../redux/slices/coursesSlice';
import { RootState } from '../../../../../redux/store';
import { setLoadingPage, updateAssessmentReport, 
    clearData }
    from '../../../../../redux/slices/sharedSlice';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { setCurrentPage } from '../../../../../redux/slices/sharedSlice';
import { ReactComponent as Logo} from '../../../../../images/courses-logo.svg';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import './SharedView.scss';
import SuccessSaveModal from '../../../../Modals/SuccessSaveModal/SuccessSaveModal';
import CoursesAlert from '../../../../Modals/CoursesAlert/CoursesAlert'; 
import { ReactComponent as ShareRocket} from '../../../../../images/shared-rocket.svg';
import { ReactComponent as ShareStar } from '../../../../../images/shared-start.svg';
import SharedProgressModal from '../../../../Modals/SharedProgressModal/SharedProgressModal';

export default function SharedView() {
  const dispatch = useDispatch<any>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const { courseAnswers, activeCourse, loadingPage } = useSelector((state: RootState) => state.shared);
  const [infoModal, setInfoModal] = useState<boolean>(true); 
 
 
  const onSave = async   () => {
    setModalOpen(false)
    setSuccessModal(true); 
    dispatch(clearData());
    
    setTimeout(() => {
      setSuccessModal(false);
    }, 1000);
    
  }

  const onCancel = async () => {
    dispatch(clearData()); 
  } 
 
  return (
    <>
      {loadingPage ? (
        <div className="SharedView">
          <div className="SharedView__container-loader">
            <CourseLoader />
          </div>
        </div>
        ) : (
          <div className="SharedView">
              {
              successModal && (
                <SuccessSaveModal  />
              )
            } 
            {/* {
              infoModal && (
              <SharedProgressModal
                close={() => setInfoModal(false)}
                />
              )
            } */}
            {
              modalOpen && (
                <CloseModal
                  type={'save'}
                  action={onSave}
                  text={'Would you like to save your progress?'}
                  closeNoSave={onCancel}
                  close={()=> setModalOpen(false)}
                  />
              )
            } 
            {
              alertModal &&
              (
                <CoursesAlert
                  page='view'
                  close={() =>   setAlertModal(false)  }
                />
              )
            }
            <div className="SharedView__container">

              <div className="SharedView__container__left">

                <div className="SharedView__container__left__menu">
                  {activeCourse?.menu?.map((item, index) => (
                    !item.hidden &&
                    <div 
                      key={index}
                      className={
                        item.disabled ?
                        `SharedView__container__left__menu__item--disabled` :
                        `SharedView__container__left__menu__item`
                      }
                      onClick={() =>  {
                        if (!item.disabled) {
                          if(item.page !== 'shared-report'){
                            dispatch(setCurrentPage(item.page as CurrentPageType ));
                          } else {
                            if(courseAnswers?.quiz?.length && courseAnswers?.evaluate?.length ){
                              dispatch(setCurrentPage('shared-report'));
                            } else{
                              setAlertModal(true);
                            }
                          }
                        }
                      }}
                    >
                    
                      <div 
                          className={
                            item.disabled ?
                            `SharedView__container__left__menu__item--disabled__name` :
                            `SharedView__container__left__menu__item__name`
                          }
                        >
                        {item.name}
                      </div>
                    </div>
                  ))}
                </div>

              </div>

              <div className="SharedView__container__right">

                <div className="SharedView__container__right__header">
                 
                </div>

                <div className="SharedView__container__right__body">
                  <div className="SharedView__container__right__body__header">
                    This is a created a custom, interactive eLearning course <br/>
                     just for you
                  </div>
                  <div className="SharedView__container__right__body__text">

                    <div className="SharedView__container__right__body__text__block">
                      <div className="SharedView__container__right__body__text__block__number">
                      1.
                      </div>
                      <div className="SharedView__container__right__body__text__block__text-block">
                        <span className="SharedView__container__right__body__text__block__text-block__span">
                        Course begins with an evaluation to benchmark your current knowledge:
                        </span>
                        This course guides you guides you through essential terms and educational content and utilizes flashcards and open-ended questions to enhance understanding.
                      </div>
                    </div>

                    {/* <div className="SharedView__container__right__body__text__block">
                      <div className="SharedView__container__right__body__text__block__number">
                      2.
                      </div>
                      <div className="SharedView__container__right__body__text__block__text-block">
                        <span className="SharedView__container__right__body__text__block__text-block__span">
                        Opportunity to practice learning through voice-based interaction with AI:
                        </span>
                        Role-playing simulation provided for practice.
                      </div>
                    </div> */}
                     
                    <div className="SharedView__container__right__body__text__block">
                      <div className="SharedView__container__right__body__text__block__number">
                      2.
                      </div>
                      <div className="SharedView__container__right__body__text__block__text-block">
                        <span className="SharedView__container__right__body__text__block__text-block__span">
                        Invitation to take a quiz:
                        </span>
                        View growth and progress in the assessment report afterward.
                      </div>
                    </div>

                  </div>
                  <div className="SharedView__container__right__body__text__footer">
                  Begin by clicking on the evaluation tab.  { <ShareStar />}
                    </div>
  
                </div>

              </div>

            </div>

          </div>
          )
        }
    </>
  )
}
