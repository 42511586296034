import axios from 'axios'; 

const API = `${process.env.REACT_APP_API_URL}api`;

export const exportSubmitsAPI  = (token: string | undefined, uid: string) =>
  axios.get(`${API}/share/export/${uid}`, {
    headers: { 
      'Authorization': `Bearer ${token}` 
    }
  })
  .then((res: any) => res.data);

export const exportAsSCORMAPI  = (token: string | undefined, uid: string) =>
    axios.get(`${API}/education/scorm/${uid}`, {
    
    responseType: 'blob',
    headers: {

        'Authorization': `Bearer ${token}` 
    }
    })
    .then((res: any) => res.data);
    
   