import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './../redux/store';
import { setMenuState } from './../redux/slices/coursesSlice';
import { setMenuState as setSharedMenuState } from './../redux/slices/sharedSlice';

export const useModules = () => {
   
    const { menu } = useSelector((state: RootState) => state?.courses?.activeCourse) || {};
    const dispatch = useDispatch();
  
    const hideMenuItem = async (page: string) => { 
        const updatedMenu = menu?.map((item) => item.page === page ? { ...item, hidden: true } : item);
        updatedMenu && dispatch(setMenuState(updatedMenu));
    };

    const showMenuItems = async () => {  
      const updatedMenu = menu?.map((item) => {
        if (item.page !== 'module-ai-simulation') {
          return { ...item, hidden: false };
        }
        return item;
      });
      
      updatedMenu && dispatch(setMenuState(updatedMenu));
  };    

    const enableStep =  async (step: string) => { 
        const updatedMenu = menu?.map((item) => item.page === step ? { ...item, disabled: false } : item);
        updatedMenu && dispatch(setMenuState(updatedMenu));
    }

    const hideEnableNextStep = (hideStep: string, enableStep: string) => {
        const updatedMenu = menu?.map((item) => {
          if (item.page === hideStep) {
            return { ...item, hidden: true };
          } else if (item.page === enableStep) {
            return { ...item, disabled: false };
          }
          return item;
        });
        updatedMenu && dispatch(setMenuState(updatedMenu));
      };

    return {
        hideMenuItem,
        showMenuItems,
        enableStep,
        hideEnableNextStep
    };
};

export const useSharedModules = () => {
  const { menu } = useSelector((state: RootState) => state?.shared?.activeCourse) || {};
  const dispatch = useDispatch();

  const enableStep =  async (step: string) => { 
      const updatedMenu = menu?.map((item) => item.page === step ? { ...item, disabled: false } : item);
      updatedMenu && dispatch(setSharedMenuState(updatedMenu));
  }

  const enableNextStep = (currentStep: string) => {
    if (!menu) {
      return;
    }
    const currentStepIndex = menu.findIndex(item => item.page === currentStep);
  
    if (currentStepIndex === -1) { 
      return;
    }
  
    let nextStepIndex = currentStepIndex + 1; 
    while (nextStepIndex < menu.length && (menu[nextStepIndex].hidden)) {
      nextStepIndex++;
    }
    if (nextStepIndex < menu.length) {
      const updatedMenu = menu.map((item, index) => 
        index === nextStepIndex ? { ...item, disabled: false } : item
      );
      dispatch(setSharedMenuState(updatedMenu));
    } 
    
  };

  const hideEnableNextStep = (hideStep: string, enableStep: string) => {
      const updatedMenu = menu?.map((item) => {
        if (item.page === hideStep) {
          return { ...item, hidden: true };
        } else if (item.page === enableStep) {
          return { ...item, disabled: false };
        }
        return item;
      });
      updatedMenu && dispatch(setSharedMenuState(updatedMenu));
    };

  return {  
      enableStep,
      enableNextStep,
      hideEnableNextStep
  };
};

