import axios from 'axios';

const API = `${process.env.REACT_APP_API_URL}api`;


export const getUserChats = (body: {email: string | undefined}, token: string  | undefined,) => axios.post(`${API}/open-ai/get-user-chats`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getChatHistory = (body: {chat_id: number}, token: string | undefined) => axios.post(`${API}/open-ai/get-chat-history`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const deleteUserChat = (body: { chat_id: number }, token: string | undefined) =>
  axios.delete(`${API}/open-ai/delete-user-chat?chat_id=${body.chat_id}`, {
   
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }).then((res: any) => res.data);


export const createChat = (body: {title: string, email: string | undefined}, token: string | undefined) => axios.post(`${API}/open-ai/create-chat`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const makeVoice = (body: {chat_id: string, chat_message_id: string }, token: string) => axios.post(`${API}/open-ai/make-a-voice`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const editChatName = (body: {chat_id: number | null, title: string}, 
  token: string | undefined) => axios.patch(`${API}/open-ai/edit-chat-name`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const textToSpeach = (body: {text: string, message_id: string }, token: string | undefined) => axios.post(`${API}/open-ai/text-to-speech`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);
