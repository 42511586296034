import { exportPdf, sendOneDrive } from "../api/azureApi";
import { createSlide, customizeSlide } from "../api/googleApi";
import { refreshGoogle } from "../api/presentationApi";
import { getCookie } from "./CookieHelper";
import { invertLine } from "./colorHelper";

function convertHexToObj(hexColor: string) {
  // Remove the "#" symbol if present
  const hex = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;

  // Split the hex color into three components (red, green, and blue)
  const redHex = hex.substring(0, 2);
  const greenHex = hex.substring(2, 4);
  const blueHex = hex.substring(4, 6);

  // Convert the hex values to decimal
  const red = parseInt(redHex, 16) / 255;
  const green = parseInt(greenHex, 16) / 255;
  const blue = parseInt(blueHex, 16) / 255;

  // Create the object with the converted values
  const obj = { red, green, blue };

  return obj;
}

const refreshToken = async () => {
  const key = getCookie('jwtToken');

  if (key) {
    const token = await refreshGoogle(key);
    return token;
  }

  return '';
};

export const sendSlideToGoogle = async (name: string, arr: any, save?: string, preview?: any) => {
    const key = getCookie('jwtToken');

    if(!key){
      return;
    }

    const data = await refreshGoogle(key);

    if(!data){
      return;
    }
    
    const token = data.access_token;
    let presentation = {} as any;

    if (token && save !== 'save') {
      try {
        presentation = await createSlide(
          token,
          {
            title: name
          }
        );
      } catch (error) {
        refreshToken();
      }
    };

    if (token && (presentation || save === 'save')) {
      let body = {"requests": []} as any;

      if (save !== 'saveAndSend' && save !== 'saveAndSendAzure' && save !== 'saveAndSendAzurePdf') {
        arr?.forEach((el: any, i: number) => {
          body.requests = [...body?.requests,
          {
            "createSlide": {
              "objectId": `${i}_slide`,
            },
          }];

          if (i === 0) {
            body.requests = [...body.requests, {
              "deleteObject": {
                "objectId": "p"
              }
            }]
          };

          if (preview?.format_options === 'Bold') {
            const pagePropertiesRequest = {
              "updatePageProperties": {
                "objectId": `${i}_slide`,
                "pageProperties": {
                  "pageBackgroundFill": {
                    "solidFill": {
                      "color": {
                        "rgbColor": convertHexToObj(preview?.color_palette)
                      }
                    }
                  }
                },
                "fields": 'pageBackgroundFill'
              }
            };
          
            body.requests.push(pagePropertiesRequest);
          }

          if (preview?.background_image) {
            body.requests = [...body.requests, {
                "createImage": {
                  "objectId": `${i}_bgImage`,
                  "url": preview?.background_image,
                  "elementProperties": {
                    "pageObjectId": `${i}_slide`,
                    "size": {
                      "height": {
                        "magnitude": 406,
                        "unit": "PT"
                      },
                      "width": {
                        "magnitude": 720,
                        "unit": "PT"
                      }
                    },
                    "transform": {
                      "scaleY": 1,
                      "scaleX": 1,
                      "translateX": 0,
                      "translateY": 0,
                      "unit": "EMU"
                    }
                  }
                }
              }]
          }

          
          if ((!el?.text && el?.title)
            || preview?.format_options === "Minimalistic"
            || (preview?.format_options === "Modern" && !el?.image)) {
            const pagePropertiesRequest = {
              "createShape": {
                "elementProperties": {
                  "pageObjectId": `${i}_slide`,
                  "size": {
                    "height": {
                      "magnitude": 5,
                      "unit": "PT"
                    },
                    "width": {
                      "magnitude": 720,
                      "unit": "PT"
                    }
                  },
                  "transform": {
                    "scaleY": 1,
                    "scaleX": 1,
                    "translateX": 0,
                    "translateY": 0,
                    "unit": "PT"
                  }
                },
                "shapeType": "TEXT_BOX",
                "objectId": `${i}_line`
              }
            };

            const lineProps = {
              "updateShapeProperties": {
                "objectId": `${i}_line`,
                "shapeProperties": {
                  "shapeBackgroundFill": {
                    "solidFill": {
                      "color": {
                        "rgbColor": preview?.format_options === 'Bold'
                          ? convertHexToObj(invertLine(preview?.color_palette)) 
                          : convertHexToObj(preview?.color_palette)
                      }
                    }
                  },
                },
                "fields": "shapeBackgroundFill.solidFill.color",
              }
            }
          
            body.requests.push(pagePropertiesRequest);
            body.requests.push(lineProps);
          };

          if (el?.image) {
            body.requests = [...body.requests, {
                "createImage": {
                  "objectId": `${i}_image`,
                  "url": el.image,
                  "elementProperties": {
                    "pageObjectId": `${i}_slide`,
                    "size": {
                      "height": {
                        "magnitude": 12700 * 406,
                        "unit": "EMU"
                      },
                      "width": {
                        "magnitude": 12700 * 406,
                        "unit": "EMU"
                      }
                    },
                    "transform": {
                      "scaleY": 1,
                      "scaleX": 1,
                      "translateX": 12700 * 335,
                      "translateY": -(12700 * 1),
                      "unit": "EMU"
                    }
                  }
                }
              }]
          }

          if (el?.image && preview?.format_options === "Modern") {
            const pagePropertiesRequest = {
              "createShape": {
                "elementProperties": {
                  "pageObjectId": `${i}_slide`,
                  "size": {
                    "height": {
                      "magnitude": 406,
                      "unit": "PT"
                    },
                    "width": {
                      "magnitude": 5,
                      "unit": "PT"
                    }
                  },
                  "transform": {
                    "scaleY": 1,
                    "scaleX": 1,
                    "translateX": 12700 * 331,
                    "translateY": -(12700 * 1),
                    "unit": "EMU"
                  }
                },
                "shapeType": "TEXT_BOX",
                "objectId": `${i}_line`
              }
            };

            const lineProps = {
              "updateShapeProperties": {
                "objectId": `${i}_line`,
                "shapeProperties": {
                  "shapeBackgroundFill": {
                    "solidFill": {
                      "color": {
                        "rgbColor": convertHexToObj(preview?.color_palette)
                      }
                    }
                  },
                },
                "fields": "shapeBackgroundFill.solidFill.color",
              }
            }

            body.requests.push(pagePropertiesRequest);
            body.requests.push(lineProps);
          }

          if (preview?.brand_logo && el?.isLogo) {
            body.requests = [...body.requests, {
                "createImage": {
                  "objectId": `${i}_logo`,
                  "url": preview?.brand_logo,
                  "elementProperties": {
                    "pageObjectId": `${i}_slide`,
                    "size": {
                      "height": {
                        "magnitude": 12700 * 32,
                        "unit": "EMU"
                      },
                      "width": {
                        "magnitude": 12700 * 32,
                        "unit": "EMU"
                      }
                    },
                    "transform": {
                      "scaleY": 1,
                      "scaleX": 1,
                      "translateX": 100_000,
                      "translateY": 5_144_414 - (12700 * 32) - 90000,
                      "unit": "EMU"
                    }
                  }
                }
              }]
          }

          if (el?.textStyle) {
            if (el?.textStyle) {
              const objTitle = document.querySelectorAll(`[id^='${el.id}-title']`);
              const titleHeight = (objTitle[0].clientHeight) * 1.6;

              const shape: any = {
                "createShape": {
                  "elementProperties": {
                    "pageObjectId": `${i}_slide`,
                    "size": {
                      "height": {
                        "magnitude": 150,
                        "unit": "PT"
                      },
                      "width": {
                        "magnitude": el?.image ? 300 : 680,
                        "unit": "PT"
                      }
                    },
                    "transform": {
                      "scaleY": 1,
                      "scaleX": 1,
                      "translateX": 25 * 0.714,
                      "translateY": el?.image ? (JSON.parse(el?.textStyle)?.blocks?.some((row: any) => row?.type === 'ordered-list-item' || row?.type === 'unordered-list-item') 
                            ? (titleHeight  * 0.714)
                            : (25 * 0.714 + (titleHeight  * 0.714)))
                          : (el?.text ? (25 * 0.714 + (titleHeight  * 0.714)) : (180 * 0.714 + (titleHeight * 0.714))),
                      "unit": "PT"
                    }
                  },
                  "shapeType": "TEXT_BOX",
                  "objectId": `${i}_text`
                }
              }

              body.requests = [...body.requests, shape];

              let textLength = 0;

              JSON.parse(el?.textStyle)?.blocks?.forEach((row: any, index: number) => {
                const text = {
                  "insertText": {
                    "text": `\n${row?.text}`,
                    "objectId": `${i}_text`,
                    "insertionIndex": textLength
                  }
                }

                body.requests.push(text);

                if (row.text.length > 0) {
                  textLength += row.text.length + 1;
                } else {
                  textLength += 1;
                }

                if (row?.type === 'unordered-list-item') {
                  const bullet = {
                    "createParagraphBullets": {
                      "textRange": {
                        "type": "FIXED_RANGE",
                        "startIndex": textLength,
                        "endIndex": textLength + 1
                      },
                      "objectId": `${i}_text`,
                      "bulletPreset": "BULLET_DISC_CIRCLE_SQUARE"
                    }
                  };

                  body.requests.push(bullet);
                };

                if (row?.type === 'ordered-list-item') {
                  const bullet = {
                    "createParagraphBullets": {
                      "textRange": {
                        "type": "FIXED_RANGE",
                        "startIndex": textLength,
                        "endIndex": textLength + 1
                      },
                      "objectId": `${i}_text`,
                      "bulletPreset": "NUMBERED_DIGIT_ALPHA_ROMAN"
                    }
                  };

                  body.requests.push(bullet);
                };

                let alignObj = {} as any;

                if (row?.data) {
                  alignObj = {
                    "updateParagraphStyle": {
                      "objectId": `${i}_text`,
                      "textRange": {
                        "type": "FIXED_RANGE",
                        "startIndex": textLength,
                        "endIndex": textLength + 1
                      },
                      "style": {
                        "alignment": row?.data["text-align"] === 'center' ? 'CENTER' : (row?.data["text-align"] === 'right' ? 'END' : 'START')
                      },
                      'fields': 'alignment'
                    }
                  }
                } else {
                  alignObj = {
                    "updateParagraphStyle": {
                      "objectId": `${i}_text`,
                      "textRange": {
                        "type": "FIXED_RANGE",
                        "startIndex": textLength,
                        "endIndex": textLength + 1
                      },
                      "style": {
                        "alignment": 'START'
                      },
                      'fields':'*'
                    }
                  }
                }

                body.requests = [...body?.requests, alignObj];
              });

              let textLengthStyle = 1;

              JSON.parse(el?.textStyle)?.blocks?.forEach((row: any, index: number) => {
                let resultArray = [] as any;
                if (row?.inlineStyleRanges?.length > 0) { 
                  row?.inlineStyleRanges?.forEach((st: any) => {
                    if (st.style === "UNDERLINE") {
                      const objStyle = {
                        "updateTextStyle": {
                          "objectId": `${i}_text`,
                          "textRange": {
                            "type": "FIXED_RANGE",
                            "startIndex": st?.offset + textLengthStyle,
                            "endIndex": st?.offset + st?.length + textLengthStyle,
                          },
                          "style": {
                            "underline": true
                          },
                          "fields": "underline"
                        }
                      };
                      
                      resultArray = [...resultArray, objStyle];
                    };

                    if (st.style.split("-")[0] === "color") {
                      const objStyle = {
                        "updateTextStyle": {
                          "objectId": `${i}_text`,
                          "textRange": {
                            "type": "FIXED_RANGE",
                            "startIndex": st?.offset + textLengthStyle,
                            "endIndex": st?.offset + st?.length + textLengthStyle,
                          },
                          "style": {
                            "foregroundColor": {
                              "opaqueColor": {
                                "rgbColor": {
                                  "red": st.style.includes("color") ? +st.style.replace("color-rgb(", "").split(",")[0] / 255 : 0,
                                  "green": st.style.includes("color") ? +st.style.replace("color-rgb(", "").split(",")[1] / 255 : 0,
                                  "blue": st.style.includes("color") ? +st.style.replace(")", "").split(",")[2] / 255 : 0
                                }
                              }
                            }
                          },
                          "fields": "foregroundColor"
                        }
                      };
                      
                      resultArray = [...resultArray, objStyle];
                    };

                    if (st.style === "ITALIC") {
                      const objStyle = {
                        "updateTextStyle": {
                          "objectId": `${i}_text`,
                          "textRange": {
                            "type": "FIXED_RANGE",
                            "startIndex": st?.offset + textLengthStyle,
                            "endIndex": st?.offset + st?.length + textLengthStyle,
                          },
                          "style": {
                            "italic": true
                          },
                          "fields": "italic"
                        }
                      };
                      
                      resultArray = [...resultArray, objStyle];
                    };

                    if (st.style.split("-")[0] === "fontfamily") {
                      const objStyleFamly = {
                        "updateTextStyle": {
                          "objectId": `${i}_text`,
                          "textRange": {
                            "type": "FIXED_RANGE",
                            "startIndex": st?.offset + textLengthStyle,
                            "endIndex": st?.offset + st?.length + textLengthStyle,
                          },
                          "style": {
                            "fontFamily": st.style.split("-")[1],
                          },
                          "fields": "fontFamily"
                        }
                      };
                      
                      resultArray = [...resultArray, objStyleFamly];
                    } else if (!row?.inlineStyleRanges?.filter((a: any) => a.style.split("-")[0] === "fontfamily").length
                     && !resultArray.filter((a: any) => a.updateTextStyle.fields === "fontFamily").length) {
                      const objStyleFamly = {
                        "updateTextStyle": {
                          "objectId": `${i}_text`,
                          "textRange": {
                            "type": "FIXED_RANGE",
                            "startIndex": st?.offset + textLengthStyle,
                            "endIndex": st?.offset + st?.length + textLengthStyle,
                          },
                          "style": {
                            "fontFamily": "Montserrat",
                          },
                          "fields": "fontFamily"
                        }
                      };
                      
                      resultArray = [...resultArray, objStyleFamly];
                    }
                    
                    if (st.style.split("-")[0] === "fontsize" || st.style.includes("fontsize")) {
                      const objStyleSize = {
                        "updateTextStyle": {
                          "objectId": `${i}_text`,
                          "textRange": {
                            "type": "FIXED_RANGE",
                            "startIndex": st?.offset + textLengthStyle,
                            "endIndex": st?.offset + st?.length + textLengthStyle,
                          },
                          "style": {
                            "fontSize": {
                              "magnitude": +(st.style.split("-")[1]) || 16,
                              "unit": 'PT',
                            }
                          },
                          "fields": "fontSize"
                        }
                      };
                      
                      resultArray = [...resultArray, objStyleSize];
                    } else if (!row?.inlineStyleRanges?.filter((a: any) => a.style.split("-")[0] === "fontsize").length
                    && !resultArray.filter((a: any) => a.updateTextStyle.fields === "fontSize").length) {
                      const objStyleSize = {
                        "updateTextStyle": {
                          "objectId": `${i}_text`,
                          "textRange": {
                            "type": "FIXED_RANGE",
                            "startIndex": st?.offset + textLengthStyle,
                            "endIndex": st?.offset + st?.length + textLengthStyle,
                          },
                          "style": {
                            "fontSize": {
                              "magnitude": 16,
                              "unit": 'PT',
                            }
                          },
                          "fields": "fontSize"
                        }
                      };
                      
                      resultArray = [...resultArray, objStyleSize];
                    };

                    if (st?.style === "BOLD" || st?.style.includes("BOLD")) {
                      const objStyle = {
                        "updateTextStyle": {
                          "objectId": `${i}_text`,
                          "textRange": {
                            "type": "FIXED_RANGE",
                            "startIndex": st?.offset + textLengthStyle,
                            "endIndex": st?.offset + st?.length + textLengthStyle,
                          },
                          "style": {
                            "bold": true
                          },
                          "fields": "bold"
                        }
                      };
                      
                      resultArray = [...resultArray, objStyle];
                    };
                  });
                } else {
                  if (row.text.length > 0) {
                    const objStyle = {
                      "updateTextStyle": {
                        "objectId": `${i}_text`,
                        "textRange": {
                          "type": "FIXED_RANGE",
                          "startIndex": textLengthStyle,
                          "endIndex": row.text.length + textLengthStyle,
                        },
                        "style": {
                          "fontSize": {
                            "magnitude": 16,
                            "unit": 'PT',
                          }
                        },
                        "fields": "fontSize"
                      }
                    };
                    
                    resultArray = [...resultArray, objStyle];
                  }
                }

                body.requests = [...body?.requests, ...resultArray];
                if (row.text.length > 0) {
                  textLengthStyle += row.text.length + 1;
                } else {
                  textLengthStyle += 1;
                }
              });
            }
          }

          if (el?.title || el?.titleStyle) {
            if (el?.titleStyle) {
              const shapeArr = JSON.parse(el?.titleStyle)?.blocks?.map((row: any, index: number) => {
                const obj = document.querySelectorAll(`[id^='${el.id}-title']`);
                const objTwo = obj[0]?.querySelectorAll('div[data-block="true"]');
                let heightSum = 0;
                objTwo?.forEach((block: any, idn: number) => {
                  if (idn < index) {
                    heightSum += row.text.length > 0 ? (block?.clientHeight * 1.8) : 0;
                  };
                })

                return {
                  "createShape": {
                    "elementProperties": {
                      "pageObjectId": `${i}_slide`,
                      "size": {
                        "height": {
                          "magnitude": row.text.length > 0 ? ((objTwo[index]?.clientHeight * 1.8) * 0.714) : 1,
                          "unit": "PT"
                        },
                        "width": {
                          "magnitude": el?.image ? 295 : 680,
                          "unit": "PT"
                        }
                      },
                      "transform": {
                        "scaleY": 1,
                        "scaleX": 1,
                        "translateX": 23 * 0.714,
                        "translateY": i !== 0 ? (27 * 0.714 + (heightSum * 0.714)) 
                          : (el?.text ? (25 * 0.714 + (heightSum  * 0.714)) : (180 * 0.714 + (heightSum * 0.714))),
                        "unit": "PT"
                      }
                    },
                    "shapeType": "TEXT_BOX",
                    "objectId": `${i}${index}_title`
                  }
                }
              });

              const textArr = JSON.parse(el?.titleStyle)?.blocks?.map((row: any, index: number) => ({
                "insertText": {
                  "text": row?.text,
                  "objectId": `${i}${index}_title`
                }
              }));

              body.requests = [...body.requests, ...shapeArr, ...textArr ]
            } else {
              const obj = document.querySelectorAll(`[id^='${el.id}-title']`)
              const objTwo = obj[0]?.querySelectorAll('div[data-block="true"]');

              body.requests = [...body.requests, {
                "createShape": {
                  "elementProperties": {
                    "pageObjectId": `${i}_slide`,
                    "size": {
                      "height": {
                        "magnitude": (objTwo[0]?.clientHeight * 3.5) * 0.714,
                        "unit": "PT"
                      },
                      "width": {
                        "magnitude": el?.image ? 300 : 680,
                        "unit": "PT"
                      }
                    },
                    "transform": {
                      "scaleY": 1,
                      "scaleX": 1,
                      "translateX": 25 * 0.714,
                      "translateY": el?.image ? 50 * 0.714 : 180 * 0.714,
                      "unit": "PT"
                    }
                  },
                  "shapeType": "TEXT_BOX",
                  "objectId": `${i}_title`
                }
              },
              {
                "insertText": {
                  "text": el?.title,
                  "objectId": `${i}_title`
                }
              }]
            }
          }
            
          if (el?.title && el?.titleStyle) {
            JSON.parse(el?.titleStyle)?.blocks?.forEach((row: any, index: number) => {
              row?.text && row?.inlineStyleRanges.forEach((st: any) => {
                const objSt = {
                  "updateTextStyle": {
                    "objectId": `${i}${index}_title`,
                    "textRange": {
                      "type": "FIXED_RANGE",
                      "startIndex": st?.offset,
                      // "endIndex": st?.length < st?.offset ? st?.length + st?.offset : st?.length,
                      "endIndex": st?.offset + st?.length
                    },
                    "fields": "",
                  }
                } as any;

                if (st.style.includes("UNDERLINE")) {
                  objSt.updateTextStyle = {
                    ...objSt.updateTextStyle,
                    "style": {
                      "underline": true
                    },
                    "fields": objSt.updateTextStyle.fields + "underline ",
                  }
                };
                if (st.style.includes("color")) {
                  objSt.updateTextStyle = {
                    ...objSt.updateTextStyle,
                    "style": {
                      "foregroundColor": {
                        "opaqueColor": {
                          "rgbColor": {
                            "red": st.style.includes("color") ? st.style.replace("color-rgb(", "").split(",")[0] / 255 : 0,
                            "green": st.style.includes("color") ? st.style.replace("color-rgb(", "").split(",")[1] / 255 : 0,
                            "blue": st.style.includes("color") ? st.style.replace(")", "").split(",")[2] / 255 : 0
                          }
                        }
                      }
                    },
                    "fields": objSt.updateTextStyle.fields + "foregroundColor ",
                  }
                }

                if (st.style.includes("fontsize")) {
                  objSt.updateTextStyle = {
                    ...objSt.updateTextStyle,
                    "style": {
                      "fontSize": {
                        "magnitude": st.style.split("-")[1] || 24,
                        "unit": 'PT',
                      }
                    },
                    "fields": objSt.updateTextStyle.fields + "fontSize",
                  }
                }
                
                if (st.style.includes("BOLD")) {
                  objSt.updateTextStyle = {
                    ...objSt.updateTextStyle,
                    "style": {
                      "bold": true,
                    },
                    "fields": objSt.updateTextStyle.fields + "bold",
                  }
                }

                if (st.style.includes("ITALIC")) {
                  objSt.updateTextStyle = {
                    ...objSt.updateTextStyle,
                    "style": {
                      "italic": true,
                    },
                    "fields": objSt.updateTextStyle.fields + "italic",
                  }
                };

                if (st.style.split("-")[0] === "fontfamily") {
                  objSt.updateTextStyle = {
                    ...objSt.updateTextStyle,
                    "style": {
                      "fontFamily": st.style.split("-")[1],
                    },
                    "fields": objSt.updateTextStyle.fields + "fontFamily",
                  }
                };
        
                body.requests.push(objSt);
              })
            })
          } else {
            if (el?.title) {
              const objSt = {
                "updateTextStyle": {
                  "objectId": `${i}_title`,
                  "textRange": {
                    "type": "ALL",
                  },
                  "style": {
                    "bold": true,
                    "fontSize": {
                      "magnitude": 24,
                      "unit": 'PT',
                    },
                    "fontFamily": "Montserrat",
                  },
                  "fields": "*",
                }
              } as any;
              
              body.requests.push(objSt);
            }
          };
            
          if (el?.titleStyle) {
            JSON.parse(el?.titleStyle)?.blocks?.forEach((row: any, index: number) => {
              const alignedTitle = {
                "updateParagraphStyle": {
                  "objectId": `${i}${index}_title`,
                  "textRange": {
                    "type": "ALL"
                  },
                  "style": {
                    "alignment": row?.data["text-align"] === 'center' ? 'CENTER' : (row?.data["text-align"] === 'right' ? 'END' : 'START')
                  },
                  'fields': '*'
                }
              };

              row?.text && body.requests.push(alignedTitle);
            });
          }
          
          if (el?.title && !el?.titleStyle?.length) {
            const alignedTitle = {
              "updateParagraphStyle": {
                "objectId": `${i}_title`,
                "textRange": {
                  "type": "ALL"
                },
                "style": {
                  "alignment": el?.image ? 'START' : 'CENTER'
                },
                'fields':'*'
              }
            };

            body.requests.push(alignedTitle);
          };
        });
      }



      if (save === 'save') {
        return JSON.stringify(body);
      } else if (save === 'saveAndSend') {
        presentation && await customizeSlide(token, presentation?.presentationId, arr);
        presentation?.presentationId && window?.open(`https://docs.google.com/presentation/d/${presentation.presentationId}/edit#slide=id.0_slide`, '_blank')?.focus();
      } else if (save === 'azure') {
        presentation && await customizeSlide(token, presentation?.presentationId, body);
        if (presentation?.presentationId) {
          try {
            await sendOneDrive(key, {
              presentationId: presentation?.presentationId,
              isDoc: false
            });
          } catch (error: any) {
            return error.response.data.message;
          }
        }
      } else if (save === 'azurePdf') {
        presentation && await customizeSlide(token, presentation?.presentationId, body);
        if (presentation?.presentationId) {
            const res = await exportPdf(key, {
              presentationId: presentation?.presentationId,
              isDoc: false
            });

            const blob = await res.blob();

            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = 'document.pdf';

            link.click();

            URL.revokeObjectURL(url)
        }
      } else if (save === 'saveAndSendAzure') {
        presentation && await customizeSlide(token, presentation?.presentationId, arr);
        if (presentation?.presentationId) {
          try {
            const res = await sendOneDrive(key, {
              presentationId: presentation?.presentationId,
              isDoc: false
            });

            console.log(res.data.message)
          } catch (error: any) {
            return error.response.data.message;
          }
        }
      } else if (save === 'saveAndSendAzurePdf') {
        presentation && await customizeSlide(token, presentation?.presentationId, arr);
        if (presentation?.presentationId) {
            const res = await exportPdf(key, {
              presentationId: presentation?.presentationId,
              isDoc: false
            });
  
            const blob = await res.blob();
  
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'document.pdf';
            link.click(); 
            URL.revokeObjectURL(url);
        }
      } else {
        presentation && await customizeSlide(token, presentation?.presentationId, body);
        presentation?.presentationId && window?.open(`https://docs.google.com/presentation/d/${presentation.presentationId}/edit#slide=id.0_slide`, '_blank')?.focus();
      }  
    };
  };