import React from 'react'; 
import './Stepper.scss';

interface StepperProps {
  currentStep: number;
  stepsLength: number;
}
const Stepper = ({ currentStep, stepsLength }: StepperProps) => {
  return (
    <div className="stepper">
      {Array.from({ length: stepsLength }, (_, index) => (
        <svg key={index} className="stepper__line-container" height="10" width="101">
          <line
            x1="5"
            y1="5"
            x2="95"
            y2="5"
            strokeLinecap="round"
            className={`stepper__line ${index < currentStep ? 'stepper__line--completed' : ''}`}
          />
        </svg>
      ))}
    </div>
  );
};

 

export default Stepper;
