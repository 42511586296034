import { ReactComponent as SaveSuccess } from '../../../images/save-success.svg';
import './SuccessSaveModal.scss';

 

export default function SuccessSaveModal( ) {

  return (
    <div className="SuccessSaveModal">
      <div className="SuccessSaveModal__wrapper">
        <div className="SuccessSaveModal__image">
          <SaveSuccess />
        </div>
        <div className="SuccessSaveModal__text">
         Success!
        </div>
        <div className="SuccessSaveModal__text-bottom">
         Your progress is saved!
        </div>

      </div>
    </div>
  )
}