import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../utils/CookieHelper';
import { AppDispatch, RootState } from '../../redux/store';
import { cleanPresentation, endLoading, getPresentationFromDoc, setObjAzure, setObjGoogle, setObjPDF, startLoading } from '../../redux/slices/slidesSlice';
import { sendSlideToGoogle } from '../../utils/sender';
import { sendDoc } from '../../utils/sendDocs';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'; 
import './DropdownExport.scss';
import { setExportError } from '../../redux/slices/scriptsSlice';

type Props = {
  presName: string;
  slideList?: any;
  presStyle: any;
  setExportModal: (arg: string) => void,
  setLoginModal: (arg: boolean) => void,
};

export default function DropdownExport({
  presName, 
  slideList, 
  presStyle, 
  setExportModal,
  setLoginModal,
}: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false); 
  const azureToken = getCookie('azureRefreshToken');
  const googleToken = getCookie('googleToken');
  const token = getCookie('jwtToken');

  const { prompts } = useSelector((state: RootState) => state.slides);

  const exportGoogle = () => {
    setVisible(false);
    if (window.location.pathname === '/slide') {
      if(!googleToken){
        dispatch(setObjGoogle({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}slide`;
        setLoginModal(true);
        setVisible(false);
        return;
      }
      sendSlideToGoogle(presName || 'go', slideList, "", presStyle);
      setExportModal("send");
    } else if (window.location.pathname === '/slidesArchive') {
      if(!googleToken){
        dispatch(setObjGoogle({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}slidesArchive`;
        setLoginModal(true);
        setVisible(false);
        return;
      }
      sendSlideToGoogle(presName, presStyle, 'saveAndSend');
      setExportModal('send');
    } else if (window.location.pathname === '/scriptsArchive') {
      if(!googleToken){
        dispatch(setObjGoogle({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}scriptsArchive`;
        setLoginModal(true);
        setVisible(false);
        return;
      }
      setExportModal('send');
      sendDoc(presName, 'send', presStyle);
    } else {
      if(!googleToken){
        dispatch(setObjGoogle({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}script`;
        setLoginModal(true);
        setVisible(false);
        return;
      }
      sendDoc(presName || 'go', 'send', presStyle);
      setExportModal("send");
    }
  }

  const exportDrive = async() => {
    setVisible(false);
    dispatch(startLoading());
    if (window.location.pathname === '/slide') {
      if(!googleToken) {
        document.cookie = `path=${process.env.REACT_APP_API_URL}slide`;
        dispatch(setObjAzure({
          presName,
          slideList,
          presStyle
        }));
        setLoginModal(true);
      } else if (!azureToken) {
        document.cookie = `path=${process.env.REACT_APP_API_URL}slide`;
        window.location.href = `${process.env.REACT_APP_API_URL}api/auth/azure`;
        dispatch(setObjAzure({
          presName,
          slideList,
          presStyle
        }));
      } else  {
        const res = await sendSlideToGoogle(presName || 'go', slideList, "azure", presStyle);
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          setExportModal('send');
        }
      }
    } else if (window.location.pathname === '/slidesArchive') {
      if(!googleToken) {
        document.cookie = `path=${process.env.REACT_APP_API_URL}slidesArchive`;
        dispatch(setObjAzure({
          presName,
          slideList,
          presStyle
        }));
        setLoginModal(true);
      } else   if (!azureToken) {
        dispatch(setObjAzure({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}slidesArchive`;
        window.location.href = `${process.env.REACT_APP_API_URL}api/auth/azure`;
      }  else {
        const res = await sendSlideToGoogle(presName, presStyle, 'saveAndSendAzure');
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          setExportModal('send');
        }
      }
    } else if (window.location.pathname === '/scriptsArchive') {
      if(!googleToken) {
        document.cookie = `path=${process.env.REACT_APP_API_URL}scriptsArchive`;
        dispatch(setObjAzure({
          presName,
          slideList,
          presStyle
        }));
        setLoginModal(true);
      } else if (!azureToken) {
        dispatch(setObjAzure({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}scriptsArchive`;
        window.location.href = `${process.env.REACT_APP_API_URL}api/auth/azure`;
      }  else {
        const res = await sendDoc(presName, 'sendAzure', presStyle);
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          setExportModal('send');
        }
      }
    } else {
      if(!googleToken) {
        document.cookie = `path=${process.env.REACT_APP_API_URL}script`;
        dispatch(setObjAzure({
          presName,
          slideList,
          presStyle
        }));
        setLoginModal(true);
      } else  if (!azureToken) {
        dispatch(setObjAzure({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}script`;
        window.location.href = `${process.env.REACT_APP_API_URL}api/auth/azure`;
      } else  {
        const res = await sendDoc(presName || 'go', 'azure', presStyle);
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          setExportModal('send');
        }
      }
    }
  }

  const exportPdf = async() => {    
    setVisible(false);
    dispatch(startLoading());

    if (window.location.pathname === '/slide') {
      if(!googleToken){
        dispatch(setObjPDF({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}slide`;
        setLoginModal(true);
        setVisible(false);
        return;
      }
      await sendSlideToGoogle(presName || 'go', slideList, "azurePdf", presStyle);
      dispatch(endLoading());
      setExportModal('send');
    } else if (window.location.pathname === '/slidesArchive') {
      if(!googleToken){
        dispatch(setObjPDF({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}slidesArchive`;
        setLoginModal(true);
        setVisible(false);
        return;
      }
      await sendSlideToGoogle(presName, presStyle, 'saveAndSendAzurePdf');
      dispatch(endLoading());
      setExportModal('send');
    } else if (window.location.pathname === '/scriptsArchive') {
      if(!googleToken){
        dispatch(setObjPDF({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}scriptsArchive`;
        setLoginModal(true);
        setVisible(false);
        return;
      }
      await sendDoc(presName, 'Pdf', presStyle);
      dispatch(endLoading());
      setExportModal('send');
    } else {
      if(!googleToken){
        dispatch(setObjPDF({
          presName,
          slideList,
          presStyle
        }));
        document.cookie = `path=${process.env.REACT_APP_API_URL}script`;
        setLoginModal(true);
        setVisible(false);
        return;
      }
      await sendDoc(presName || 'go', 'Pdf', presStyle);
      dispatch(endLoading());
      setExportModal('send');
    }
  }

  const exportPres = () => {
    setVisible(false);
    dispatch(cleanPresentation());
    if (token && presStyle) {
      const names = prompts?.map((a: any) => {
        if (window.location.pathname === '/slide') {
          if (a?.second_title) {
            if (!a?.isScript) {
              return a?.second_title;
            }
          } else {
            return "";
          }
        } else {
          if (a?.second_title) {
            if (a?.isScript) {
              return a?.second_title;
            }
          } else {
            return "";
          }
        }
      })?.filter((b: any) => b?.length > 0);

      const bodyText = {
        template_name: names[0] || 'custom presentation',
        slides: []
      } as any;

      let obj = {
        title: '',
        text: '',
      } as any;

      JSON.parse(presStyle?.style)?.blocks.forEach((item: any, i: number) => {
        if (item.type === 'header-one') {
          bodyText.title = item.text.replaceAll('\n', '');
          if (JSON.parse(presStyle?.style)?.blocks[i + 1]?.type === 'header-two' || JSON.parse(presStyle?.style)?.blocks[i + 1]?.type === 'header-three') {
            bodyText.text = JSON.parse(presStyle?.style)?.blocks[i + 2].text.replaceAll('\n', '');
          } else {
            bodyText.text = JSON.parse(presStyle?.style)?.blocks[i + 1].text.replaceAll('\n', '');
          }
        } else if (item.type === 'header-two' || item.type === 'header-three') {
          if (obj?.title?.length && obj?.text?.length) {
            bodyText.slides.push(obj);
            obj = {
              title: '',
              text: '',
            };
          }
          obj.title = item.text.replaceAll('\n', '');
        } else {
            if (item.type === 'unordered-list-item') {
              obj.text += ' \t ' + item.text.replaceAll('\n', '');
            } else {
              if (item?.text?.includes('Action Items:')) {
                obj.text += item.text.replaceAll('\n', '');
              }

              if (obj?.title?.includes('Conclusion')) {
                obj.text += item.text.replaceAll('\n', '');
              }
            };
        } 

        if (i === JSON.parse(presStyle?.style)?.blocks.length - 1) {
          bodyText.slides.push(obj);
        }
      });
      
      dispatch(getPresentationFromDoc({
        token,
        body: bodyText,
      }));
      
      navigate(`/slide`);
    };
  }

  return (
    <div className="DropdownExport">
      
      <button
        type="button"
        className={`DropdownExport__button ${
          (window.location.pathname === '/slidesArchive' || window.location.pathname === '/scriptsArchive') && 'DropdownExport__button--archive'}`} 
        onClick={() => {
          // if(!googleToken){
            
          //   setVisible(false);
          // } else{
            setVisible(!visible);
          // }

        }}
      >
        Export to
        {visible ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
      </button>
      {visible && <div className="DropdownExport__list">
        <button 
          type="button"
          className="DropdownExport__item"
          onClick={exportGoogle}
        >
          {(window.location.pathname === '/script' 
          || window.location.pathname === '/scriptsArchive') 
          ? 'Google Docs' 
          : 'Google Slides'}
        </button>
        <button
          type="button"
          className="DropdownExport__item"
          onClick={exportDrive}
        >
          One Drive
        </button>
        <button
          type="button"
          className="DropdownExport__item"
          onClick={exportPdf}
        >
          PDF
        </button>
      </div>}
    </div>
  )
};

