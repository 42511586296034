import React from 'react';
import { ReactComponent as LoaderPath } from '../../../images/loader-sections.svg';
import './SectionsLoader.scss';

const SectionsLoader = () => {
  return (
    <div className="loader-sections">
        <LoaderPath />    
    </div>

  );
};

export default SectionsLoader;

