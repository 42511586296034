import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import SectionRename from '../Modals/SectionRename/SectionRename';
import TextAreaSection from '../TextAreaSection/TextAreaSection';
import  CustomColorButton  from '../Buttons/CustomColorButton/CustomColorButton';
import SectionGenerateModal from '../Modals/SectionGenerateModal/SectionGenerateModal';
import axios from 'axios';
import SectionsButton from '../Buttons/SectionsButton/SectionsButton'; 
import EducationSubSectionRename from '../Inputs/EducationSubSectionRename/EducationSubSectionRename';
import CloseModal from '../Modals/CloseModal/CloseModal';
import { getCookie } from '../../utils/CookieHelper';
import { useSocket } from '../../App';
import './SectionBlock.scss';

interface Section {
  title: string;
  description: string;
  sub_sections: SubSection[];
}

interface SubSection {
  title: string;
  description: string;
  image: string | null;
}

interface SectionBlockProps {
  section: Section;
  updateSection: (index: number, field: keyof Section | keyof SubSection, value: any, subSectionIndex?: number) => void;
  index: number; // The index of this section in the parent's state array 
}

interface SectionReferenceProps {
  index: number;
  subIndex?: number;
  selectionStart: number;
  selectionEnd: number;
}

interface ImageReferenceProps{
  index: number;
  subIndex: number; 
}
interface UpdateTextInSectionParams {
  sectionReference: SectionReferenceProps | null;
  data: string;
  updateSection: (index: number, field: keyof Section | keyof SubSection, value: string, subSectionIndex?: number) => void;
  index: number;
  resetSectionReference: () => void;
}

interface UpdateImageInSectionParams {
  imageReference: ImageReferenceProps | null;
  data: string;
  updateSection: (index: number, field: keyof Section | keyof SubSection, value: string, subSectionIndex?: number) => void;
  index: number;
  resetImageReference: () => void;
}

const SectionBlock: React.FC<SectionBlockProps> = ({ section, updateSection, index }) => {

  const API = `${process.env.REACT_APP_API_URL}api`;
 
  const newSocket = useSocket();
  const [loading, setLoading] = useState(false);
  const [editTitles, setEditTitles] = useState<{ [key: number]: boolean }>({});
  const [sectionReference, setSectionReference] = useState<SectionReferenceProps | null>(null);
  const [imageReference, setImageReference] = useState<ImageReferenceProps | null>(null);
  const [ showModal, setShowModal] = useState<'image' | null>(null);
  const [ deleteSubsectionModal, setDeleteSubsectionModal] = useState<number | boolean>(false);

  
  const updateTextInSection = useCallback(({
    sectionReference,
    data,
    updateSection,
    index,
    resetSectionReference
  }: UpdateTextInSectionParams) => {
    if (!sectionReference) return;

    const targetSection = sectionReference.subIndex !== undefined ?
      section.sub_sections[sectionReference.subIndex].description : section.description;

    const beforeText = targetSection.substring(0, sectionReference.selectionStart);
    const afterText = targetSection.substring(sectionReference.selectionEnd);
    const newText = `${beforeText}${data}${afterText}`;

    if (sectionReference.subIndex !== undefined) {
      updateSection(index, 'description', newText, sectionReference.subIndex);
    } else {
      updateSection(index, 'description', newText);
    }

    resetSectionReference();
  }, [section]);

  const updateImageInSection = useCallback(({
    imageReference,
    data,
    updateSection,
    index,
  }: UpdateImageInSectionParams) => {
    if (!imageReference) return;

    updateSection(index, 'image', data, imageReference.subIndex);
  }
  , [ ]);

  useEffect(() => {
    const handleTextGenerationEnd = (data: string) => {
      updateTextInSection({
        sectionReference,
        data,
        updateSection,
        index,
        resetSectionReference: () => setSectionReference(null)
      });
      setLoading(false);
    };

    const handleImageGenerationEnd = (data: string) => {
      updateImageInSection({
        imageReference,
        data,
        updateSection,
        index,
        resetImageReference: () => setImageReference(null)
      });
      setLoading(false);
      closeModal();
    };
  
    newSocket?.on('education:generate-image:end', handleImageGenerationEnd);
    newSocket?.on('education:regenerate-text:end', handleTextGenerationEnd);
    newSocket?.on('education:generate-text:end', handleTextGenerationEnd);
  
    return () => {
      newSocket?.off('education:generate-image:end', handleImageGenerationEnd);
      newSocket?.off('education:regenerate-text:end', handleTextGenerationEnd);
      newSocket?.off('education:generate-text:end', handleTextGenerationEnd);
    };
  }, [sectionReference, imageReference, updateImageInSection, index, updateSection, newSocket, updateTextInSection]);
  
  
  const handleSubSectionTitleEditToggle = (subSectionIndex: number) => {
    setEditTitles(prevEditTitles => ({
      ...prevEditTitles,
      [subSectionIndex]: !prevEditTitles[subSectionIndex],
    }));
  };

  // Function to handle saving of sub-section titles after editing
  const handleSaveSubSectionTitle = (newTitle: string, subSectionIndex: number) => {
    handleSubSectionChange('title', newTitle, subSectionIndex);
  };

  // Function to handle changes in section description
  const handleDescriptionChange = (value: string) => {
    updateSection(index, 'description', value);
  };

  // Incorrect updateSection call was leading to the issue
  const handleSubSectionChange = (field: keyof SubSection, value: any, subSectionIndex: number) => {
    // Make sure to specify that you're updating a subSection's field
    updateSection(index, field, value, subSectionIndex);
  };


  const handleImproveSubsection = (textToChange: string, instructions: string, value: string, subIndex?: number) => { 
    setLoading(true);
    
    newSocket?.emit('education:regenerate-text', {
      fullText: value, // Full text of the section
      textToChange, // The selected text to improve
      instructions, // Instructions from modal input
    });
  };
  
  // Implement the callback for the 'generate' action
  const handleGenerateSubsection = (instructions: string, currentText: string) => {
    setLoading(true);
  
    newSocket?.emit('education:generate-text', {
      title: section.title, // Title of the section
      instructions, // Instructions from modal input
      currentText
    });
  };

  const handleDeleteImage = (subIndex: number) => {
    handleSubSectionChange('image', null, subIndex);
  }

  const uploadImage = async (file: File, token: string) => {
    const formData = new FormData();
    formData.append("file", file);
  
    return axios.post(
      `${API}/user/upload-file`, // Make sure this is your correct endpoint
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`
          // Content-Type is not needed, it's set automatically by the browser
        }
      }
    )
    .then((response) => response.data);
  };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>, subIndex: number) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setLoading(true); // Indicate loading
      try {
        const token = getCookie('jwtToken');
        if (token) {
          const response = await uploadImage(file, token);
          // Assuming the response contains the URL of the uploaded image
          handleSubSectionChange('image', response, subIndex);
        }
      } catch (error) {
        console.error('Error during image upload', error);
        // Handle error, e.g., set error message in state
      }
      setLoading(false); // Reset loading indicator
      event.target.value = ''; // Reset the file input
    }
  };
  
  const handleModalConfirm = (modalInput: string) => {
    newSocket?.emit('education:generate-image', { 
      instructions: modalInput, // Instructions from modal input
    });
    setLoading(true);
  };
  

  const handleImageGenerate = ( index: number, subIndex: number) => { 
    setImageReference({index, subIndex});
    setShowModal('image'); 
  }

  const closeModal = () => {
    setShowModal(null);
    setImageReference(null);
  };

  const handleDeleteSubsection = (subIndex: number ) => { 
    if(typeof index === 'number'){
      updateSection(index, 'sub_sections', section.sub_sections.filter((_, i) => i !== subIndex));
    }
    setDeleteSubsectionModal(false);
  }

  return (
    <div className="section-block">
       { showModal && 
            <SectionGenerateModal
                header={ 'Generate image with AI'}
                subHeader={ 'The picture will be generated according to the prompt you add here'}
                text={ 'Tell Landdai what exactly you want to see in the picture for this section'}
                loading={loading}
                action={handleModalConfirm}
                close={closeModal}
            />
        }
           {
          typeof deleteSubsectionModal === 'number' && (
            <CloseModal
              type={'remove-subsection'}
              submitText='Yes'
              action={() => handleDeleteSubsection(deleteSubsectionModal as number)}
              text={'Do you want to permanently delete the subsection?'}
              closeNoSave={()=> setDeleteSubsectionModal(false)}
              close={()=> setDeleteSubsectionModal(false)}
              />
          )
        }
      <div className="section-block__header">
        {index+1}{'. '}{section.title}
      </div>
      <TextAreaSection
        loading={loading} 
        imageRequired={false}
        value={section.description}
        onChange={handleDescriptionChange}
        onGenerate={handleGenerateSubsection} // Adapt as needed
        onImprove={handleImproveSubsection}
        onImageAdd={(image) => handleSubSectionChange('image', image, 0)} // If applicable
        setSectionReference={(selectionStart, selectionEnd) => setSectionReference({ index, selectionStart, selectionEnd })}       
      />

      {section.sub_sections.map((subSection, subIndex) => (
        <div key={`${subSection.title || index + subIndex}-${subIndex}`}>
        { subIndex % 2 === 0 ? 
         <div  className={`section-block__sub-section`}>
              {subSection.image ? <div className="section-block__image-container">
                {subSection.image ?
                    subSection.image !== 'template' ? (
                        <img src={subSection.image} alt={''} className="section-block__image" />
                      )  :
                      <div className="image-to-replace"/>
                  : 
                  null
                  }
                  {subSection.image && (<div className="image-to-replace__btn-cont">
                    <CustomColorButton
                      text="Delete"
                      onClick={() => handleDeleteImage(subIndex)}
                    />
                    <CustomColorButton
                      text="Generate"
                      textColor='#00539C'
                      backgroundColor='#FCFCFC'
                      onClick={() => handleImageGenerate(index, subIndex)}
                    />
                    <CustomColorButton
                      text="Upload"
                      textColor='#00539C'
                      backgroundColor='#FCFCFC'
                      onClick={() => document?.getElementById(`file-input-${index}-${subIndex}`)?.click()}
                    />
                    <input
                      className="ImageUploader__file-input"
                      id={`file-input-${index}-${subIndex}`}
                      accept="image/jpeg, image/jpg, image/png"
                      type="file" 
                      onChange={(e: any) => {
                        handleImageChange(e, subIndex)
                        e.target.value = "";
                      }}
                    />
                  </div>)}
              </div>
              : null}
            <div 
              className={`section-block__sub-section__left`}>
              {
                subSection.title === ' ' ?
                 <EducationSubSectionRename
                  initialValue={''}
                  onSave={(newTitle) => handleSaveSubSectionTitle(newTitle, subIndex)}
                  />
                :
                <SectionRename 
                initialValue={subSection.title}
                editing={editTitles[subIndex]} 
                setEditing={() => handleSubSectionTitleEditToggle(subIndex)} 
                onSave={(newTitle) => handleSaveSubSectionTitle(newTitle, subIndex)}
                onDelete={() => {
                  console.log('delete', subIndex)
                  setDeleteSubsectionModal(subIndex);
                  // updateSection(index, 'sub_sections', section.sub_sections.filter((_, i) => i !== subIndex));
                }}
                />
              }
             
            
              <TextAreaSection
                loading={loading} 
                imageRequired={true}
                sectionImage={subSection.image}
                value={subSection.description}
                subIndex={subIndex}
                onChange={(value) => handleSubSectionChange('description', value, subIndex)} 
                onGenerate={handleGenerateSubsection}
                onImprove={handleImproveSubsection}
                onImageAdd={(image) => handleSubSectionChange('image', image, subIndex)}
                setSectionReference={(selectionStart, selectionEnd, subIndex) => setSectionReference({ index, subIndex, selectionStart, selectionEnd })}
              />
            </div>
          </div>
          : 
          <div key={subIndex} className={`section-block__sub-section`}>
           
            <div 
              className={` section-block__sub-section__left `}>

                <SectionRename 
                initialValue={subSection.title}
                editing={editTitles[subIndex]} 
                setEditing={() => handleSubSectionTitleEditToggle(subIndex)} 
                onSave={(newTitle) => handleSaveSubSectionTitle(newTitle, subIndex)}
                onDelete={() => { 
                  setDeleteSubsectionModal(subIndex);
                  // updateSection(index, 'sub_sections', section.sub_sections.filter((_, i) => i !== subIndex));
                }}
                />

              <TextAreaSection
                loading={loading} 
                imageRequired={true}
                sectionImage={subSection.image}
                value={subSection.description}
                subIndex={subIndex}
                onChange={(value) => handleSubSectionChange('description', value, subIndex)} 
                onGenerate={handleGenerateSubsection}
                onImprove={handleImproveSubsection}
                onImageAdd={(image) => handleSubSectionChange('image', image, subIndex)}
                setSectionReference={(selectionStart, selectionEnd, subIndex) => setSectionReference({ index, subIndex, selectionStart, selectionEnd })}
              />
              
            </div>
            {subSection.image ? <div className="section-block__image-container">
                {subSection.image ?
                    subSection.image !== 'template' ? (
                        <img src={subSection.image} alt={''} className="section-block__image" />
                      )  :
                      <div className="image-to-replace"/>
                  : 
                  null
                  }
                  {subSection.image && (<div className="image-to-replace__btn-cont">
                    <CustomColorButton
                      text="Delete"
                      onClick={() => handleDeleteImage(subIndex)}
                    />
                    <CustomColorButton
                      text="Generate"
                      textColor='#00539C'
                      backgroundColor='#FCFCFC'
                      onClick={() => handleImageGenerate(index, subIndex)}
                    />
                    <CustomColorButton
                      text="Upload"
                      textColor='#00539C'
                      backgroundColor='#FCFCFC'
                      onClick={() => document?.getElementById(`file-input-${index}-${subIndex}`)?.click()}
                    />
                    <input
                      className="ImageUploader__file-input"
                      id={`file-input-${index}-${subIndex}`}
                      accept="image/jpeg, image/jpg, image/png"
                      type="file" 
                      onChange={(e: any) => {
                        handleImageChange(e, subIndex)
                        e.target.value = "";
                      }}
                    />
                  </div>)}
              </div>
              : null}
        </div>}
        {
          (subIndex === section.sub_sections.length - 1 || !section.sub_sections.length)  && 
          <div className="section-block__sub-section__left">
             <SectionsButton 
                icon 
                text='Add subsection'
                styles={{width: '155px', height: '24px', marginTop: '28px'}}
                action={() =>  updateSection(index, 'sub_sections', [...section.sub_sections, { title: 'Add a name of subsection', description: '', image: null }])}
                />
            
          </div>
        }
        </div>
      ))}
      {
          (!section.sub_sections.length)  && 
          <div className="section-block__sub-section__left">
             <SectionsButton 
                icon 
                text='Add subsection'
                styles={{width: '155px', height: '24px', marginTop: '28px'}}
                action={() =>  updateSection(index, 'sub_sections', [...section.sub_sections, { title: 'Add a name of subsection', description: '', image: null }])}
                />
            
          </div>
        }
    </div>
  );
};

export default SectionBlock;
