import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch } from '../../../redux/store';
import { sendScriptToAI, setTextareaDocText } from '../../../redux/slices/scriptsSlice';
import { cleanPrompts, getPresentation } from '../../../redux/slices/slidesSlice';

import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import PresentationCarousel from './PresentationCarousel/PresentationCarousel';
import './PresentationGenModal.scss';
import { getCookie } from '../../../utils/CookieHelper';

type Props = {
  setOpenSubmitModal: (arg: boolean) => void,
  user: any,
};

export default function PresentationGenModal({
  setOpenSubmitModal, 
  user
}: Props) {
  const [searchParams] = useSearchParams() || '';
  const getTemplate = searchParams.get('template') || '';
  const token = getCookie('jwtToken');
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [resultObj, setResultObj] = useState<any>({});

  const createPres = async() => {
    if (window.location.pathname === '/slide') {
      if (resultObj?.value.trim()) {
        const body = {
          default_options: {
            brand_logo: resultObj?.style?.selectImage || "",
            background_image: resultObj?.style?.selectBgImage || "",
            image_style: resultObj?.style?.imageStyle || "Business People",
            color_palette: resultObj?.style?.color || "#9747FF",
            format_options: resultObj?.style?.formatting || "Bold",
            text_color: resultObj?.style?.selectBgImage ? resultObj?.colorSave : "",
          },
          save_options: resultObj?.save,
          category_id: +getTemplate,
          promptId: resultObj?.category_id,
          title: resultObj?.title,
          text: "",
          title_second: resultObj?.value.split(" ").map(
            (text: string) => text.slice(0, 1).toUpperCase() + text?.slice(1)
          ).join(" "),
        };

        dispatch(getPresentation({body, token}));
      }
    } else {
      const body = {
        title_second: resultObj?.value.split(" ").map(
          (text: string) => text.slice(0, 1).toUpperCase() + text?.slice(1)
        ).join(" "),
        title: resultObj?.title,
        prompt: "",
        isCustom: "",
        promptId: resultObj?.category_id,
      } as any;
  
      dispatch(sendScriptToAI({
        token,
        body,
      }));
    }
  }

  return (
    <div 
      className="PresentationGenModal"
      style={{
        height: '950px',
        // height: window.location.pathname === '/slide' ? '830px' : '270px',
        // margin: window.location.pathname === '/slide' ? '24px 0' : 'auto 0',
      }}
    >
      <div
        className="PresentationGenModal__close"
        onClick={() => {
            if (window.location.pathname === '/slide') {
              navigate('/slides');
              dispatch(setTextareaDocText(""));
            } else {
              navigate('/scripts');
              dispatch(setTextareaDocText(""));
            }
        }}
      >
        <CloseIcon
          sx={{
            color: '#5A6861'
          }}
        />
      </div>
      <PresentationCarousel setResultObj={setResultObj}/>
      <div className="PresentationGenModal__btn-wrapper">
          <ConfirmButton
            disable={!resultObj?.value?.trim()}
            action={() => {
              if (resultObj?.value.trim()) {
                if (user?.quantity === 0) {
                  setOpenSubmitModal(true);
                } else {
                  createPres();
                  dispatch(setTextareaDocText(""));
                  dispatch(cleanPrompts());
                }
              }
            }}
            text={"Generate"}
          />
      </div>
    </div>
  )
}
