
import { ReactComponent as CloseTooltip } from '../../../images/closeTooltip.svg';
import './QuizEditTooltip.scss';

interface QuizEditTooltipProps {
  onClose: () => void;
}
 

export default function QuizEditTooltip({onClose}: QuizEditTooltipProps): JSX.Element {
 
  return (
    <div className="QuizEditTooltip">
        
        <div className="QuizEditTooltip__content">
            <div className="QuizEditTooltip__content__close" onClick={onClose}><CloseTooltip /></div>
            <div className="QuizEditTooltip__content__text">
                Include to Add <span className="QuizEditTooltip__content__text__bold">up to 10 questions</span> and select from various types. Customize your learning experience with LandDai's flexibility. 
            </div>
            <div className="QuizEditTooltip__content__text">
                Join now to shape your education your way!
            </div>
        
        </div>
    </div>
  );
}; 
