import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import CloseIcon from '@mui/icons-material/Close';
import './RemoveModal.scss';

type Props = {
  action: any,
  text: string,
  close: (arg: boolean) => void
};

export default function RemoveModal({
  action,
  text,
  close }: Props) {

  return (
    <div className="RemoveModal">
      <div className="RemoveModal__wrapper">
        <div
          className="RemoveModal__close"
          onClick={() => close(false)}
        >
          <CloseIcon
            sx={{
              color: '#5A6861'
            }}
          />
        </div>
        <p>{text}</p>
        <div className="RemoveModal__btns">
          <ConfirmButton
            text={"Remove"}
            action={action}
          />
          <div
            className="RemoveModal__btn"
            onClick={() => close(false)} 
          >
            No
          </div>
        </div>
      </div>
    </div>
  )
}
