import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as DeleteIcon } from '../../../images/sections-x.svg';
import { ReactComponent as CheckIcon } from '../../../images/check-icon.svg';
import './EssayInput.scss';

interface EssayInputProps {
    initialValue: string;
    onSave: (value: string) => void;
    editing: boolean;
    setEditing: () => void;
    onDelete?: () => void;
    extraClass?: string;
}

const EssayInput: React.FC<EssayInputProps> = ({ 
  initialValue, editing, setEditing, onSave, extraClass
}) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLTextAreaElement>(null); 

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      adjustTextareaHeight();
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
        }
      }, 0);
    }
  }, [editing]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  const handleSave = () => {  
    onSave(value);
    setEditing();
  };

  return (
    <div className={`EssayInput ${extraClass ? extraClass : ''}`}>
        <textarea
            ref={inputRef} 
            className="EssayInput__input"
            value={value} 
            onChange={(e) => {
            setValue(e.target.value);
            }}
        />
        <div className={`EssayInput__btns`}>
            { value &&  <CheckIcon  style={{marginTop: "-4px"}}  onClick={()=> handleSave()} />}
            <DeleteIcon onClick={()=> setEditing()} />
        </div>
    </div>
  );
}
export default EssayInput;
