import { ReactComponent as CheckboxChecked } from '../../../images/ion_checkbox.svg';
import { ReactComponent as CheckboxUnchecked } from '../../../images/carbon_checkbox.svg';
import './QuizCheckBoxInput.scss';

interface QuizCheckBoxInputProps {
  text: string;
  correct: boolean;
  setText: (text: string) => void;
  setCorrect: (correct: boolean) => void;
  customStyles?: React.CSSProperties;
}

export default function QuizCheckBoxInput({
  text,
  correct,
  setText,
  setCorrect,
  customStyles
}: QuizCheckBoxInputProps) {
  return (
    <div className="quiz-edit-checkbox" style={customStyles}>
        {
            correct ?
                <CheckboxChecked 
                    style={{cursor: "pointer"}}
                    onClick={() => setCorrect(!correct)}
                /> 
                : 
                <CheckboxUnchecked 
                    style={{cursor: "pointer"}}
                    onClick={() => setCorrect(!correct)}
                />
        }
       
      <input
        type="text"
        className="quiz-edit-checkbox__input"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
    </div>
  );
}
