import axios from 'axios';
import { Course } from '../redux/slices/coursesSlice';
 
const API = `${process.env.REACT_APP_API_URL}api`;

export const getUserCourses = (token: string | undefined, page: number) =>
  axios.get(`${API}/education/list`, {
    params: {
      page: `${page}`
    },
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then((res: any) => res.data);

  // export const saveCourse = (token: string | undefined, course: Course) =>
  // axios.post(
  //   `${API}/education/save`, // URL
  //   { // Data
  //     title: course.title,
  //     data: JSON.stringify(course),
  //     summarize: course.summarize,
  //     id: course?.id || null,
  //     educationContentId: course?.educationalContent?.id || null,
  //     educationalContentData: JSON.stringify(course?.educationalContent),
  //   }, 
  //   { // Config
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   }
  // )
  // .then((res: any) => res.data);

  export const saveCourse = (token: string | undefined, course: Course) => {
    // Create a shallow copy of the course object
    const courseCopy = { ...course };
  
    // Remove the 'data' property if it exists
    if (courseCopy.hasOwnProperty('data')) {
      delete courseCopy.data;
    }
  
    const courseData = {
      title: course.title,
      data: JSON.stringify(courseCopy),
      summarize: course.summarize,
      id: course?.id || null,
      educationContentId: course?.educationalContent?.id || null,
      educationalContentData: JSON.stringify(course?.educationalContent),
    };
  
    return axios.post(
      `${API}/education/save`, // URL
      courseData, 
      { // Config
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    .then((res: any) => res.data)
    .catch((error) => {
      console.error('Error saving course:', error);
      throw error;
    });
  };
  
  

  export const removeEducationalContent = (token: string | undefined, course: Course) =>
  axios.delete(
    `${API}/education/educational-content/${course?.educationalContent?.id}`, // Updated URL
    { // Config
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res: any) => res.data);

  export const removeSimulationId = (token: string | undefined, id: number | undefined) =>
  axios.delete(
    `${API}/simulation-room/${id}`, // Updated URL
    { // Config
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res: any) => res.data);

  export const removeSimulation = (token: string | undefined) =>
  axios.post(
    `${API}/simulation-room/remove`, // Updated URL
    {},
    { // Config
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res: any) => res.data);

  export const removeEducationVideoApi = (educationalContentId: number, token: string | undefined) =>
  //api/education/:education/delete-video
  axios.delete(
    `${API}/education/${educationalContentId}/delete-video`, 
    { // Config
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((response) => response.data);

  export const uploadImageApi = (file: FormData,token: string | undefined) =>
  axios.post(
    `${API}/user/upload-file`, // Updated URL
    { // Config
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((res: any) => res.data);

  export const removeCourseByIdApi = (token: string | undefined, id: number | undefined) =>
  axios.delete(
    `${API}/education/${id}`, // Updated URL
    { // Config
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
    .then((response) => response.data);
