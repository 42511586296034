import { useState } from 'react';
import { ReactComponent as AlertIcon  } from '../../../images/alert-triangle.svg';
import './CustomInput.scss'

interface CustomInputProps {
    type: 'email' | 'code';
    text: string;
    setText: (text: string) => void;
    error: string;
    customStyles?: React.CSSProperties;
    isMobile?: boolean;
}

const typeTexts = {
    email: {
      label: 'Email',
      required: 'Required',
      placeholder: 'Enter your email',
    },
    code: {
      label: 'Enter code',
      placeholder: 'Code',
      required: '',
    }
};


const mobileTypeTexts = {
    email: {
      label: '',
      required: '',
      placeholder: 'Email',
    },
    code: {
      label: '',
      placeholder: 'Code',
      required: '',
    }
};

export default function CustomInput ({type, text, setText, error, customStyles, isMobile} : CustomInputProps){
    const { label, required, placeholder } = isMobile ? mobileTypeTexts[type] : typeTexts[type] ;
  
    return (
   
        <div className="CustomInput" style={customStyles}>
             
            <div className="CustomInput__label-block">
                <div className="CustomInput__label">{label}</div>
                <div className="CustomInput__required">{required}</div>
            </div>

            <input
             type="text" 
             value={text} 
             placeholder={placeholder}
             className={'CustomInput__input' + (error ? ' CustomInput__input--error' : '') + (isMobile ? ' CustomInput--mobile' : '')}
             onChange={(e) => setText(e.target.value)}
             
             />
           
            <div className="CustomInput__error">
                {error && (
                    <div className="CustomInput__error-cont">
                        <div className="CustomInput__error-icon"><AlertIcon/></div>
                        <div className="CustomInput__error-text">{error}</div>
                    </div>
                )}
            </div>
        </div>
 
    )

}