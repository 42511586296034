import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton'; 
import './SharedProgressModal.scss';

type Props = {
  close: (arg: boolean) => void
};

export default function SharedProgressModal({
  
  close }: Props) {

  return (
    <div className="SharedProgressModal">
      <div className="SharedProgressModal__wrapper">
        <p>If you exit the course after making progress, your progress will not be saved, and you will have to start from the beginning. Please complete the course to save your progress.</p>
        <div className="SharedProgressModal__btns">
          <ConfirmButton
            text={'OK'}
            action={close}            
          />
        </div>
      </div>
    </div>
  )
}

