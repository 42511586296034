import React, { useState, useRef, useEffect } from 'react';
import SectionsButton from '../Buttons/SectionsButton/SectionsButton';
import SectionGenerateModal from '../Modals/SectionGenerateModal/SectionGenerateModal';
import ButtonTooltip from '../Tooltips/ButtonTooltip/ButtonTooltip';
import './TextAreaSection.scss'; 

interface TextAreaSectionProps { 
    loading: boolean; 
    imageRequired: boolean;
    sectionImage?: string| 'template' | null ;
    value: string;
    subIndex?: number;
    onChange: (value: string) => void;
    onImprove?: 
        (
            textToChange: string,
            instructions: string, 
            fulltext: string,
            subIndex?: number,
        ) => void;
    onGenerate?: (instructions: string, currentText: string) => void;
    onImageAdd?: (image: string) => void;
    setSectionReference?: ( // Optional function or null
        selectionStart: number,
        selectionEnd: number,
        subIndex?: number,
        ) => void;
}

const TextAreaSection: React.FC<TextAreaSectionProps> = ({
    loading, 
    imageRequired,
    sectionImage,
    value,
    subIndex,
    onChange,
    onImprove,
    onGenerate,
    onImageAdd,
    setSectionReference
  }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [isTextSelected, setIsTextSelected] = useState(false);
    const [isCaretPosition, setIsCaretPosition] = useState(false);
    const [ improveAvaliable, setImproveAvaliable] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const maxHeight = imageRequired ? 346 : null;
    const [showModal, setShowModal] = useState<'improve' | 'generate' | null>(null);
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionEnd, setSelectionEnd] = useState(0);

    useEffect(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;
        textarea.style.height = 'auto';
        const desiredHeight = maxHeight ?  
            textarea.scrollHeight > maxHeight ? maxHeight : textarea.scrollHeight 
            : 
            textarea.scrollHeight;
        textarea.style.height = `${desiredHeight}px`; 
    }, [value]); 

    const handleTextSelection = () => {
        const textarea = textareaRef.current;
        if (!textarea) return;
        const text = textarea.value;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = text.substring(start, end);
        setIsTextSelected(selectedText.length > 0);
        setImproveAvaliable(selectedText.length > 0);
        setIsCaretPosition(start === end);
        // Store the selection indices
        setSelectionStart(start);
        setSelectionEnd(end);

        setSectionReference?.(start, end, subIndex);
    };

    // Refactor later if necessary
    useEffect(() => {
       if(!loading) closeModal();
    }, [loading]);

      const generateContent = async () => {
        setShowModal('generate');
        // Determine positions for potential insertion
        let insertPositionStart = selectionStart;
        let insertPositionEnd = selectionEnd;
        if (!isCaretPosition && selectionStart === selectionEnd) {
            insertPositionStart = value.length;
            insertPositionEnd = value.length;
        } else if (!isCaretPosition && selectionStart !== selectionEnd){
            insertPositionStart = selectionEnd;
            insertPositionEnd = selectionEnd;
        } else if (isCaretPosition && selectionStart === selectionEnd){
            insertPositionStart = selectionStart;
            insertPositionEnd = selectionStart;
        } 
        setSectionReference?.(insertPositionStart, insertPositionEnd, subIndex);
        
      };

      
      const handleModalConfirm = (modalInput: string) => {
        if (showModal === 'improve') {
          onImprove?.(
            value.substring(selectionStart, selectionEnd),
            modalInput,
            value,
            subIndex
            );
        } else if (showModal === 'generate') {
          onGenerate?.(modalInput, value);
        } else if (showModal === 'image') {
          onImageAdd?.(modalInput);
        }
      };

      const addImagePlaceholder = () => {
         onImageAdd?.('template');
      }
      

    const closeModal = () => {
        setShowModal(null);
        setIsTextSelected(false);
        setSelectionStart(0);
        setSelectionEnd(0);
    };


  return (
    <div className={
        `TextAreaSection ${sectionImage ? 'TextAreaSection__with-image' : ''}
        ${!imageRequired || !sectionImage ? 'TextAreaSection__image-not-required' : ''}
        `}>
        
        { showModal && 
            <SectionGenerateModal
                header={
                    showModal === 'improve' ? 
                        'Improve the text with AI' 
                        :
                        'Generate more content with AI'
                }
                subHeader={
                    showModal === 'improve' ? 
                        'The text you highlighted will be changed according to the prompt you add here'
                        :
                        'AI will generate more content based on the previous context'
                }
                text={
                    showModal === 'improve' ? 
                        'Tell Landdai exactly what should be changed in the text or what it should be about'
                        :
                        'Tell Landdai what AI should focus on when generating more information'
                }
                loading={loading}
                action={handleModalConfirm}
                close={closeModal}
            />
        }
        <textarea
           value={value}
           ref={textareaRef}
           className='TextAreaSection__input'
           onChange={e => onChange(e.target.value)} 
           onSelect={handleTextSelection}
           onBlur={() => {
               setIsTextSelected(false);
               // TODO refactor later
                setTimeout(() => { 
                    setImproveAvaliable(false);
                }, 250);
                setTimeout(() => {
                    setIsCaretPosition(false); 
                }, 1000);
            }}
        />
        <div className='TextAreaSection__btn-container'>
            { showTooltip && 
                <ButtonTooltip
                    position={ (imageRequired  && !sectionImage ) ? 'second' : ''} 
                    onClose={()=> setShowTooltip(false)}  />
                    }
            {(imageRequired
             && !sectionImage
             ) && (
             <SectionsButton icon text='Add image' action={addImagePlaceholder} />
            )}
           
            {
                value 
                && <SectionsButton
                    disable={!improveAvaliable} 
                    onHover={() => !improveAvaliable && setShowTooltip(true)}
                    onLeave={() => !improveAvaliable && setShowTooltip(false)}
                    text='Improve with Ai'
                    action={() => { 
                    improveAvaliable && setShowModal('improve')
                    }}  
                    />
            }
            <SectionsButton  text='Generate more content' action={() => generateContent()} />

        </div>
    </div>
  );
};

export default TextAreaSection;
