import  { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../../../../utils/CookieHelper';
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import Mentoring from '../../../../../images/cursor-choose.svg'; 
import Simulation from '../../../../../images/male-case.svg';
import Story from '../../../../../images/wave.svg'; 
import { setCurrentPage, setCurrentTab} from '../../../../../redux/slices/coursesSlice';
import ModulesAiSimulationMentoring  from './ModulesAiSimulationMentoring/ModulesAiSimulationMentoring'; 
import { useModules } from '../../../../../hooks/useModules';
import WhiteButton from '../../../../Buttons/WhiteButton/WhiteButton';
import './ModulesAiSimulation.scss'; 

export default function ModulesAiSimulation() {
  const dispatch = useDispatch<any>();
  const { currentTab, loadingPage } = useSelector((state: RootState) => state.courses); 
  const token = getCookie('jwtToken');
  const [modalOpen, setModalOpen] = useState(false);
  const { enableStep } = useModules();
  // const [hideModal, setHideModal] = useState<boolean>(false);


  // const onCancel = () => {
  //   setHideModal(false); 
  // }
  // const onHide = () => {
  //   hideEnableNextStep('module-ai-simulation', 'module-quiz'); 
  //   setHideModal(false);
  //   dispatch(setCurrentPage('module-view'));
  // }
    
  const mainBlocks = [
    {
      title: 'Mentor Simulation',
      text: 'Choose Mentoring to try yourself as a mentor in the field of your knowledge base',
      icon: Mentoring,
      page: 'mentoring'
    },
    // {
    //   title: 'Student Simulation',
    //   text: 'Choose Student Simulation to try yourself in a simulation of a real conversation with potential student',
    //   icon: Simulation,
    //   page: 'simulation'
    // },
    // {
    //   title: 'Personalised Simulation',
    //   text: 'Choose Your story to share a personal experience with the AI coach and receive an advise on how to resolve the issues',
    //   icon: Story,
    //   page: 'story'
    // }
  ];

  useEffect(() => {
    enableStep('module-quiz'); 
  }, []);



  return (
    <>
     {
        modalOpen && (
          <CloseModal
            type={'save'}
            submitText='Yes'
            action={()=> { 
              dispatch(setCurrentTab('main'));
              setModalOpen(false)
            }}
            text={'Are you sure you want to exit'}
            closeNoSave={()=> setModalOpen(false)}
            close={()=> {
              setModalOpen(false)
            }}
            />
        )

      } 
      {loadingPage ? (
        <div className="ModulesAiSimulation">
          <div className="ModulesAiSimulation__container-loader">
            <CourseLoader 
              customText='Please, wait...'
            
              />
          </div>
        </div>
      ) : (
        <div className="ModulesAiSimulation">
          {/* {
            hideModal && (
              <CloseModal
                type={'hide'}
                submitText={'Yes'}
                action={onHide}
                text={'Would you like to hide selected tabs, making them no longer visible?'}
                closeNoSave={onCancel}
                close={()=> setHideModal(false)}
                />
            )
          }  */}
          <div className="ModulesAiSimulation__container">
            <div className="ModulesAiSimulation__container__left" onClick={() => {
                enableStep('module-quiz');
                currentTab === 'main' ? dispatch(setCurrentPage('module-view')) :  setModalOpen(true);
              }}>
              <ArrowLeft/>
            </div>
            <div className="ModulesAiSimulation__container__right">
              

              {
                (!currentTab || currentTab === 'main') && (
                  <>
                 
                    <div className="ModulesAiSimulation__container__right__main__header">         
                      {/* <div className="ModulesAiSimulation__container__right__main__header__title">
                       Welcome to AI Simulation Room!
                      </div> */}
                      <div className="ModulesAiSimulation__container__right__main__header__btns" >
                        {/* <WhiteButton
                              text="Hide"
                              className='WhiteButton--p15-24'
                              action={() => {
                                setHideModal(true);
                              }}
                              /> */}
                      </div>
                    </div>
                    {/* <div className="ModulesAiSimulation__container__right__main__sub-header">         
                      Choose the mode you want to try yourself in today
                    </div> */}

                    {/* <div className="ModulesAiSimulation__container__right__main__body"> 
                      {
                        mainBlocks.map((block, index) => (
                          <div className="ModulesAiSimulation__container__right__main__body__block" key={index} onClick={() => dispatch(setCurrentTab(block.page))}> 
                            <div className="ModulesAiSimulation__container__right__main__body__block__icon">
                              <img src={block.icon} alt={block.title}  />
                            </div>
                            <div className="ModulesAiSimulation__container__right__main__body__block__header">
                              {block.title}
                            </div>
                            <div className="ModulesAiSimulation__container__right__main__body__block__line"/>
                            <div className="ModulesAiSimulation__container__right__main__body__block__text">
                              {block.text}
                            </div>   
                          </div>
                        ))
                      }
                    </div> */}
                     <ModulesAiSimulationMentoring/>
                  </>
                )
              }

              {
                currentTab === 'mentoring' && (
                  <>
                    <ModulesAiSimulationMentoring/>
                  </>
                )
              }

              {/* {
                currentTab === 'simulation' && (
                  <>
                   <ModulesAiSimulationSimulation/>
                  </>
                )
              }

              {
                currentTab === 'story' && (
                  <>
                    <ModulesAiSimulationStory/>
                  </>
                )
              } */}

            </div>
            
          </div>

        </div>
        )
      }
    </>
  )
}
