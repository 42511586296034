import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import remarkGfm from 'remark-gfm'
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg';
import { ReactComponent as EditIdcon } from '../../../../../images/edit-essay.svg';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';
import TextAreaEssay from "../../../../../components/TextAreaEssay/TextAreaEssay"
import { setCurrentPage, updateEssayAnswers, setEducationLoading,  } from '../../../../../redux/slices/coursesSlice';
import Markdown from 'react-markdown';
import WhiteButton from '../../../../Buttons/WhiteButton/WhiteButton'; 
import { useSocket } from '../../../../../App';
import { useModules } from '../../../../../hooks/useModules';
import EssayInput from '../../../../Inputs/EssayInput/EssayInput';
import './ModulesEssay.scss';
interface EssayData {
  questions: string[];
  answers?: {
    question: string;
    answer: string;
  }[];
  result?: string;
}

export default function ModulesEssay() {
  const newSocket = useSocket();
  const messageBlockRef = useRef<HTMLDivElement>(null);
  const bodyBlockRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<any>();
  const { educationLoading } = useSelector((state: RootState) => state.courses);
  const { essay } = useSelector((state: RootState) => state?.courses?.activeCourse) || {};
  const answersSetByUser = useRef(false);
  const [finishModal, setFinishModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(essay?.answers?.filter(elem => elem.answer)?.length || 1 - 1);
  const getAnswer = (index: number): string => {
    if (!essay?.answers || index >= essay.answers.length) return '';
    return essay.answers[index]?.answer || '';
  }

  const { enableStep, hideEnableNextStep } = useModules();
  const [hideModal, setHideModal] = useState<boolean>(false);

  // TODO - Enable the next step
  // useEffect(() => { 
  //   enableStep('module-ai-simulation'); 
  // }, []);
  useEffect(() => { 
    enableStep('module-quiz'); 
  }, []);

  const onCancel = () => {
    setHideModal(false);
  }
  const onHide = () => { 
    hideEnableNextStep('module-essay', 'module-ai-simulation'); 
    setHideModal(false);
    dispatch(setCurrentPage('module-view'));
  }

  const [result, setResult] = useState<string | null>(essay?.result || null);

  const [answers, setAnswers] = useState<EssayData['answers']>([
    {
      question: essay?.questions?.[0] ?? '',
      answer: getAnswer(0) || ''
    },
    {
      question: essay?.questions?.[1] ?? '',
      answer: getAnswer(1) || ''
    },
    {
      question: essay?.questions?.[2] ?? '',
      answer: getAnswer(2) || ''
    },
    {
      question: essay?.questions?.[3] ?? '',
      answer: getAnswer(3) || ''
    },
    {
      question: essay?.questions?.[4] ?? '',
      answer: getAnswer(4) || ''
    },
  ]);

  const [intro, setIntro] = useState(!getAnswer(0));

  useEffect(() => {
    // Check if all answers are populated
    const allAnswersPopulated = answers?.every(ans => ans.answer && ans.answer.trim() !== '');

    dispatch(updateEssayAnswers({ answers }));
    // Check the ref value and the condition for all answers being populated
    if (answersSetByUser.current && allAnswersPopulated) {

      newSocket?.emit('education:essay-check', {
        answers: answers
      });
      dispatch(setEducationLoading(true));

    }

    newSocket?.on('education:essay-check:end', (data: any) => {

      dispatch(setEducationLoading(false)); // changed to false here as it seems more logical
      if (data && data.result) {
        setResult(data.result);
        const updatedAnswers = {
          answers: answers || [],
          result: data.result
        };
        dispatch(updateEssayAnswers(updatedAnswers as Partial<EssayData>));
      }

    });

    // Reset the ref after logging to avoid consecutive unnecessary logs
    answersSetByUser.current = false;

  }, [answers]);

  useEffect(() => {
    if (messageBlockRef.current) {
      messageBlockRef.current?.scrollTo({
        top: messageBlockRef.current.scrollHeight,
        behavior: 'smooth',
      });
      bodyBlockRef.current?.scrollTo({
        top: bodyBlockRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [answers, result]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (!inputValue.trim()) {
      setInputValue('');
      return;
    }
    if (!isEditMode) {
      const currentQuestion = essay?.questions[currentQuestionIndex];
      if (currentQuestion && answers) {
        answersSetByUser.current = true;
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestionIndex] = { question: currentQuestion, answer: inputValue };
        setAnswers(updatedAnswers);
        dispatch(updateEssayAnswers({ answers: updatedAnswers }));
        if (currentQuestionIndex < essay?.questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        setInputValue(''); // Clear the input after submission

      }
    }

  };


  const handleEdit = (index: number) => {
    if (answers) {
      setCurrentQuestionIndex(index);
      setIsEditMode(true);  // Set edit mode
    }
  };

  const handleUpdateAnswer = (value: string) => { 
    if (!answers || currentQuestionIndex >= answers.length) return;
    if (!value.trim()) {
      return;
    } else {
      const updatedAnswers = answers.map((elem, indx) => indx === currentQuestionIndex ? {...elem, answer: value} : elem);
      setAnswers(updatedAnswers);
      dispatch(updateEssayAnswers({answers: updatedAnswers}));
      setInputValue('');
      const lastWithoutAnswer = answers.findIndex(ans => !ans.answer);
      setCurrentQuestionIndex(lastWithoutAnswer);
      setIsEditMode(false);

    };

  };

  const handleFinishDisplay = (lastAnswer?: boolean) => {

    if (!lastAnswer) {
      return (
        <SaveButton
          text='Finish'
          styles={{ marginTop: '28px', alignSelf: 'center'}}
          action={() => handleFinishClick()}
        />
      );
    }  
    return (
      <SaveButton
        text='Finish'
        styles={{ marginTop: '28px', alignSelf: 'center'}}
        action={() => handleSubmit()}
      />
    );
    
  }

  const handleFinishClick = () => { 
    if (result) {
      dispatch(setCurrentPage('module-view'))
    } else{
      setFinishModal(true);
    }
  }

  const renderResult = () => {
    if (result) {
      return (
        <>
          <div className="ModulesEssay__container__right__body__questions-list__question ModulesEssay__container__right__body__questions-list__question__extra-margin">
            <div className="ModulesEssay__container__right__body__questions-list__role">
              AI
            </div>
            <Markdown remarkPlugins={[remarkGfm]}>{result}</Markdown>
          </div>
          <br />

        </>
      );
    }
  };

  const handleDisplay = (ans: { question: string; answer: string }, index: number, arr: any) => {
    // If the question has an answer, display both the question and answer.
    if (ans.question && ans.answer) {
      return (
        <div key={index + 'x'}>
          <div key={index} className="ModulesEssay__container__right__body__questions-list__question">
            <div key={index + 'a'} className="ModulesEssay__container__right__body__questions-list__role">
              AI
            </div>
            <span>Question {index + 1}: {ans.question}</span>
          </div>
          <div key={index + 'b'} className="ModulesEssay__container__right__body__questions-list__answer">
            <div key={index + 'c'} className="ModulesEssay__container__right__body__questions-list__role">
              You
            </div>
            {
              isEditMode && currentQuestionIndex === index ? (
                <EssayInput
                initialValue={ans.answer}
                editing={true}
                onSave={handleUpdateAnswer}
                setEditing={() => {
                  setCurrentQuestionIndex(answers?.findIndex(ans => !ans.answer) || 1 - 1);
                  setIsEditMode(false)
                }} 
              />
              ) : (
                  ans.answer
              )
            }
            {!isEditMode && <EditIdcon className='ModulesEssay__container__right__body__questions-list__answer__edit-icon' onClick={() => handleEdit(index)}></EditIdcon>}
             
            {/* {ans.answer} */}
            {/* {!answers?.every(ans => ans.answer && ans.answer.trim() !== '') && <EditIdcon className='ModulesEssay__container__right__body__questions-list__answer__edit-icon' onClick={() => handleEdit(index)}></EditIdcon>} */}
          </div>
        </div>
      );
    }

    // If the question doesn't have an answer and it's the first one without an answer, display just the question.
    else if (ans.question && (!ans.answer || ans.answer.trim() === '')) {
      // Check if previous question had an answer. If it did, display this unanswered question. 
      if (index === 0 || (arr[index - 1] && arr[index - 1].answer)) {
        return (
          <>
            {
              intro ? (
                <>
                
                  <div className="ModulesEssay__container__right__body__questions-list__question__start">
                    <SaveButton
                      text='Start'
                      action={() => setIntro(false)}
                    />
                  </div>
                </>
              )
                :
                (
                  <div key={index + 'e'} className="ModulesEssay__container__right__body__questions-list__question">
                    <span>Question {index + 1}: {ans.question}</span>
                  </div>
                )

            }

          </>

        );
      }
    }

    return null;
  };


  return (
    <>
      {educationLoading ? (
        <div className="ModulesEssay">
          <div className="ModulesEssay__container-loader">
            <CourseLoader
              customText='Analyzing your answers...'
            />
          </div>
        </div>
      ) : (
        <div className="ModulesEssay">
          {
            finishModal && (
              <CloseModal
                type={'finish'}
                submitText={'Yes'}
                action={() => dispatch(setCurrentPage('module-view'))}
                text={'Are you sure you want to finish?'}
                extraText={'It looks like there are still unanswered questions.'}
                closeNoSave={() => setFinishModal(false)}
                close={() => setFinishModal(false)}
                />
            )
          }
          {
            hideModal && (
              <CloseModal
                type={'hide'}
                submitText={'Yes'}
                action={onHide}
                text={'Would you like to hide selected tabs, making them no longer visible?'}
                closeNoSave={onCancel}
                close={() => setHideModal(false)}
              />
            )
          }
          <div ref={bodyBlockRef} className="ModulesEssay__container">
            <div className="ModulesEssay__container__left" onClick={() => {
              dispatch(setCurrentPage('module-view'))
            }}>
              <ArrowLeft />
            </div>
            <div className="ModulesEssay__container__right">
              <div className="ModulesEssay__container__right__header">
                <div className="ModulesEssay__container__right__header__title">

                </div>
                <div className="ModulesEssay__container__right__header__btns" >
                  <WhiteButton
                    text="Hide"
                    className='WhiteButton--p15-24'
                    action={() => {
                      setHideModal(true);
                    }}
                  />
                </div>
              </div>
              <div className="ModulesEssay__container__right__body">

                <div ref={messageBlockRef} className={`${!answers?.every(ans => ans.answer && ans.answer.trim() !== '') ? 'ModulesEssay__container__right__body__questions-list' : 'ModulesEssay__container__right__body__questions-list__full-vh'}  
              `}>
                 <div className="ModulesEssay__container__right__body__questions-list__question">
                    Answer the following open-ended questions the best you can and at the end I'll analyze your answers at then tell you what you’re doing well and what you could work on.
                  </div>
                  {answers?.map((ans, index, arr) => handleDisplay(ans, index, arr))}
                  {renderResult()}
                  {result && <>{ handleFinishDisplay()} <br/><br /></>}
                </div>

              </div>
              {(!answers?.every(ans => ans.answer && ans.answer.trim() !== '')
                && !intro)
                &&
                (
                  <TextAreaEssay
                    value={inputValue}
                    onChange={handleInputChange}
                    onSubmit={handleSubmit}
                  />
                  
                )
              }
              {(!intro && !result) && ( answers && currentQuestionIndex === answers.length - 1  && inputValue) && handleFinishDisplay(true)}
            </div>
          </div>
        </div>
      )}
    </>
  );
}