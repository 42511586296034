


import { useDispatch } from 'react-redux';   
import { setCurrentPage, resetState } from '../../../../../redux/slices/sharedSlice';
import sharedSuccess from '../../../../../images/report-success.png';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';

import './SharedSuccess.scss';

export default function SharedSuccess() {
  
  const dispatch = useDispatch<any>();
   
  return (
    <>
    
        <div className="SharedSuccess">
          
          <div className="SharedSuccess__container">
            <div className="SharedSuccess__container__left" >
              
            </div>
            <div className="SharedSuccess__container__right">
                <div className="SharedSuccess__container__right__content-block">
                        <div className="SharedSuccess__container__right__text">
                            Great news!
                        </div> 
                        <div className="SharedSuccess__container__right__subtext">
                            Your Assessment Report has been successfully sent to you email. Thank you!
                        </div> 
                        <img src={sharedSuccess} alt='' className="SharedSuccess__container__right__img"/>
                        <div className="SharedSuccess__container__right__btns">
                            <SaveButton
                                text="Next"
                                action={() => {
                                  dispatch(resetState())
                                  dispatch(setCurrentPage('shared-login'))
                                }}
                                />
                        </div>
                             
                </div>
            </div>

          </div>

        </div>
        
      
    </>
  )
}

