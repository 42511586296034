
import { useDispatch } from 'react-redux';  
import { ReactComponent as ArrowLeft } from '../../../../../images/arrow-left.svg'; 
import { setCurrentPage } from '../../../../../redux/slices/sharedSlice';
import shareUnavaliable from '../../../../../images/shareUnavaliable.png';

import './MobileSharedUnavaliable.scss';

export default function MobileSharedUnavaliable() {
  
  const dispatch = useDispatch<any>();
   
  return (
    <div className="MobileSharedUnavaliable">
        <div className="MobileSharedUnavaliable__left" onClick={() => dispatch(setCurrentPage('shared-login'))}>
            <ArrowLeft/>
        </div>
        <div className="MobileSharedUnavaliable__right">
            <div className="MobileSharedUnavaliable__right__text">
                Sorry, the course is unavailable!
            </div> 
        
            <img src={shareUnavaliable} alt='' className="MobileSharedUnavaliable__right__img"/>
        </div>

    </div>      
  )
}
