import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import shielt from '../../../images/shield-check.svg';
import lockTail from '../../../images/lockTail.svg';
import lock from '../../../images/lock.svg';
import { useDispatch, useSelector } from 'react-redux';
import { cleanPresentation, getAllTemplates } from '../../../redux/slices/slidesSlice';
import { getCookie } from '../../../utils/CookieHelper';
import { RootState } from '../../../redux/store';
import Loader from '../../Loader/Loader';
import { getUserInfo } from '../../../redux/slices/scriptsSlice';
import { checkCredits, getCredits } from '../../../api/scriptsApi';
import compas from '../../../images/compas.svg';
import './SlidesSection.scss';

export default function SlidesSection() {
  const [searchParams] = useSearchParams() || '';
  const sessionId = searchParams.get('session_id') || '';
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const token = getCookie('jwtToken');

  const [showTooltip, setShowTooltip] = useState<number | null>(null);

  const { tamplates, loading } = useSelector((state: RootState) => state.slides);

  useEffect(() => {
    dispatch(cleanPresentation());
    token && dispatch(getAllTemplates({
      body: {
        isScript: false
      },
      token
    }));
    token && dispatch(getUserInfo(token));
    const foo = async (tok: string) => { 
      await checkCredits(tok);
    };
    token && foo(token);
  }, [token]);

  useEffect(() => {
    const foo = async (id: string, tok: string) => { 
      await getCredits(id, tok);
    };
    (sessionId && token) && foo(sessionId, token);
  }, [sessionId, token]); 

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="SlidesSection">
          <h2 className="SlidesSection__title">Ai Presentation Templates</h2>
          <div className="SlidesSection__wrapper">
            <div className="SlidesSection__row">
              { 
              tamplates?.map((a: any, i: number) => (
                <div
                  key={a?.id}
                  className="SlidesSection__btn"
                  onClick={() => {
                    if (!a?.isLocked) {
                      searchParams.set('template', `${a?.id}`);

                      navigate(`/slide?${searchParams.toString()}`, { replace: true });
                    }
                  }}
                  onMouseEnter={() => setShowTooltip(a?.id)}
                  onMouseLeave={() => setShowTooltip(null)}
                >
                  {a?.isLocked && <div className="SlidesSection__lock-wrapper">
                      <img
                        className="SlidesSection__lock-img"
                        src={lock} 
                        alt="lock"
                      />
                      {showTooltip === a?.id && <div className="SlidesSection__lock-tooltip">
                        <img className="SlidesSection__lock-tooltip-tail" src={lockTail} alt="tail" />
                        <h3 className="SlidesSection__lock-tooltip-title">Upgrade for Pro Plan</h3>
                        <p className="SlidesSection__lock-tooltip-text">Access top-tier locked templates with our Pro Plan and create stunning content effortlessly</p>
                      </div>}
                    </div>}
                  <img
                    className="SlidesSection__icon"
                    src={a?.image ? a?.image[0]?.url : compas} 
                    alt="img"
                  />
                  <p>{a?.category}</p>
                </div>
              ))}
              <div
                className="SlidesSection__btn"
                onClick={() => {
                  navigate('/slidesArchive');
                }}
              >
                <img src={shielt} alt="img" />
                Your Saved <br /> Presentations
              </div>
            </div>
          </div>
          </div>
        )
      }
    </>
  )
}
