import React, { useRef, useEffect } from 'react';
import SmallLoader from '../Loader/SmallLoader/SmallLoader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import './TextAreaEssay.scss';
import send from '../../images/send.svg';

interface TextAreaEssayProps {
  small?: boolean;
  value: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
}

const TextAreaEssay: React.FC<TextAreaEssayProps> = ({ small, value, disabled, onChange, onSubmit }) => {

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !disabled) {

      event.preventDefault();
      onSubmit();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <div className="text-area-essay-container">
      <div className={`text-area-essay ${small ? 'small' : ''}`} >
        <textarea
          ref={textareaRef}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          className="text-area-essay__textarea"
        />
        <div className="text-area-essay__button-container">
          <div className={`${value && !disabled ? "text-area-essay__button" : "text-area-essay__button__disabled"}`} onClick={() => !disabled && onSubmit()}>
              <img src={send} alt="send" />
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default TextAreaEssay;
