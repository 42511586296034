import React from 'react'; 
import './Steps.scss';

interface StepOneProps {
  question?: string;
  options?: string[];
  answer?: number[]; // Assuming answer holds indexes of selected options
  setAnswer: (answer: number[]) => void; // Adjusted to accept an array of numbers
}

const StepOne = ({
  question,
  options,
  answer = [], // Default to an empty array if not provided
  setAnswer 
}: StepOneProps) => {
  const getLetter = (index: number) => {
    return String.fromCharCode(65 + index);
  };

  const handleOptionClick = (optionIndex: number) => {
    const isOptionSelected = answer.includes(optionIndex);
    if (isOptionSelected) {
      // If the option is already selected, remove it
      setAnswer(answer.filter(a => a !== optionIndex));
    } else {
      // Otherwise, add the option index to the selection
      setAnswer([...answer, optionIndex]);
    }
  };

  return (
    <div className="Step">
      <div className="Step__container">
        <div className="Step__container__header">
          <div className="Step__container__header__question">
            {question}
          </div>
          <div className="Step__container__header__instruction">
            Please choose the right options
          </div>
        </div>
        {options?.map((option, index) => (
          <div 
            key={index}
            className={`Step__container__body__one ${answer.includes(index) ? 'selected' : ''}`} 
            onClick={() => handleOptionClick(index)} // Pass index of the option
          >
            <div className="Step__container__body__one__option">
              <div className="Step__container__body__one__option__letter">
                {getLetter(index)}
              </div>
              <div className="Step__container__body__one__option__text">
                {option}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepOne;
