import React from 'react';
import './SaveButton.scss'

type Props = {
  action: (arg: any) => void,
  text: string,
  styles?: React.CSSProperties, 
  disabled?: boolean,
}

export default function SaveButton({action, text, disabled, styles}: Props) {
  return (
    <div
      className={`SaveButton ${disabled ? 'SaveButton--disabled' : ''}`}
      onClick={disabled ? undefined : action}
      style={styles}
    >
      {text}
    </div>
  )
}
