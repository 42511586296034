import React from 'react';
import './ConfirmButton.scss'

type Props = {
  action: (arg: any) => void,
  text: string,
  disable?: boolean,
  customStyles?: React.CSSProperties,
}

export default function ConfirmButton({action, text, disable, customStyles}: Props) {
  return (
    <div
      className={`ConfirmButton ${disable && 'ConfirmButton--disable'}`}
      onClick={!disable ? action : undefined}
      style={customStyles}
    >
      {text}
    </div>
  )
}
