import React, { useState} from 'react'
import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import CloseIcon from '@mui/icons-material/Close';
import './AddQuizQuestionModal.scss';

type Props = {
  action: any,
  text: string,
  closeNoSave: any,
  close: any
};

export default function AddQuizQuestionModal({
  action,
  text,
  closeNoSave,
  close }: Props) {
    const [questionType, setQuestionType] = useState<string>(""); 

  return (
    <div className="AddQuizQuestionModal">
      <div className="AddQuizQuestionModal__wrapper">
        <div
          className="CloseModal__close"
          onClick={close}
        >
            <CloseIcon
                sx={{
                color: '#5A6861'
                }}
            />
        </div>
        <p className="AddQuizQuestionModal__text">Add new question</p>
        <div className="AddQuizQuestionModal__btn-block">
            <div
                className={`AddQuizQuestionModal__btn ${questionType === "right_options" ? "active" : "" }`}
                onClick={() => {
                    setQuestionType("right_options"); 
                }}
            >
                Multiple Choice
            </div>
            <div
                className={`AddQuizQuestionModal__btn ${questionType === "true_false" ? "active" : "" }`}
                onClick={() => {
                    setQuestionType("true_false"); 
                }}
            >
                True or False
            </div>
            <div
                className={`AddQuizQuestionModal__btn ${questionType === "matching" ? "active" : "" }`}
                onClick={() => {
                    setQuestionType("matching"); 
                }}
            >
                Match The Word
            </div>
            <div
                className={`AddQuizQuestionModal__btn ${questionType === "fill_blank" ? "active" : "" }`}
                onClick={() => {
                    setQuestionType("fill_blank");
                }}
            >
                Fill In The Blanks
            </div>
           
        </div>
        <div className="CloseModal__btns">
          <ConfirmButton
            text={"OK"}
            action={() => {
                action(questionType);
                setQuestionType("");
                close();
            }}
            customStyles={{
                padding: '15px 26px',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
            }}
          />
          <div
            className="CloseModal__btn"
            onClick={closeNoSave} 
            style={{
                width: '105px',
                height: '48px',
                padding: '15px 24px',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  )
}
