import React, { createContext, useContext, useEffect, useState  } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { store, persistor, RootState } from './redux/store';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import PrivateRoutes from './utils/PrivateRoutes';
import LoginPage from './pages/LoginPage/LoginPage'; 
import Home from './pages/Home/Home';
import './App.scss';
import PayPlan from './pages/PayPlan/PayPlan';
import PayPlanLimit from './pages/PayPlanLimit/PayPlanLimit'; 
import { getCookie } from './utils/CookieHelper';
import { ToastContainer} from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import SharedSections from './components/Sections/SharedSections/SharedSections';
import 'react-toastify/dist/ReactToastify.css';
import WallMob from './pages/WallMob/WallMob';
import PayPlanMob from './pages/PayPlanMob/PayPlanMob';
import { isMobile } from 'react-device-detect';

const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => useContext(SocketContext);

const api_url = `${process.env.REACT_APP_API_URL}`;

const AppWraper = () => {
    
  const { user } = useSelector((state: RootState) => state.scripts);
  const planLimited = getCookie('planLimited') == '1' || user?.plan_limited === 1;
  const token = getCookie('jwtToken');
  const [newSocket, setNewSocket] = useState<Socket | null>(null);

  useEffect(() => {
    console.log('App', user?.id, token);
    if(!~window.location.pathname.indexOf('/shared') && !user?.id) {
      return;
    }
    const socket: Socket = io(api_url, {
      transports: ['websocket'],
      query: {
        id: user.id,
        token: token,
      },
      reconnection: true,         // Enable automatic reconnection
      reconnectionAttempts: Infinity, // Try to reconnect forever
      reconnectionDelay: 1000,    // Initial delay of reconnection in milliseconds
      reconnectionDelayMax: 5000, // Maximum delay of reconnection
      randomizationFactor: 0.5    // Randomization factor for the reconnection delay
   
    });

    if (socket) {
      socket.on('connect', () => {
        console.log('onconnected app', user?.id, token);
        setNewSocket(socket);
      })
    }

    return () => {
      if (socket) {
        socket.off('connect'); 
        socket.disconnect();
      }
    };
  }, [user?.id, token])

  return (
    <>
     <SocketContext.Provider value={newSocket}>
        <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<Home />} path="/*" />
              <Route element={<PayPlan />} path="/payment" />
              
              <Route element={<PayPlanLimit />} path="/pricing" />
              <Route element={<Navigate to={
                ((user?.quantity && user.isFreeTrialActive) || user?.isPlan) ? 
                "/courses" : isMobile ? "/payment-mob" :
                 planLimited ? "/pricing" : "/payment"} replace/>} path=""/>
            </Route>
            <Route element={<PayPlanMob />} path="/payment-mob" />
            <Route element={<LoginPage />} path="/login" />
            <Route element={<WallMob />} path='/wall'/>
            <Route element={<SharedSections />} path="/shared/:id/:id2" />
          </Routes>
        </Router>
        <ToastContainer limit={1}/>
      </SocketContext.Provider>
    </>
  )
}

function App() {


  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <div className="App">
           
            <AppWraper />
            
          </div>
      </PersistGate>
    </Provider>
  );
};

export default App;
