import ChatList from './ChatList/ChatList';
import ChatContainer from './ChatContainer/ChatContainer';
import { useDispatch } from 'react-redux';
import {  
  endLoading,
  endLoadingPage,
  setLoadingMessage
 } from '../../../redux/slices/trainerBotSlice';
import './ChatBotSection.scss';
 

export default function ChatBotSection() {
  const dispatch = useDispatch<any>(); 
  window.onbeforeunload = function() {
    dispatch(endLoading());
    dispatch(endLoadingPage()); 
    dispatch(setLoadingMessage(false))
  };

  return (
    <>
      <div className="ChatBotSection">
        <ChatContainer/>
        <ChatList />
      </div>
    </>
  )
}
