import axios from 'axios';

const API = `${process.env.REACT_APP_API_URL}api`;

export const sendOneDrive = (token: string, body: any) => axios.post(`${API}/presentation/export-onedrive`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
);

export const exportPdf = (token: string, body: any) => fetch(`${API}/presentation/export-pdf-from-onedrive`,
  {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }
)