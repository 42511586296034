import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../images/x.svg';
import Chip from '@mui/material/Chip'; 
import { ReactComponent as AddQuestionIcon } from '../../images/add-question.svg';
import QuizEditTooltip from '../Tooltips/QuizEdittooltip/QuizEditTooltip';
import './QuizChips.scss';

interface QuizChipsProps {
    activeQuestion: number | null;
    quizTypes: string[]; 
    handleQuizClick: (index: number) => void;
    handleDelete: (index: number) => void;
    handleQuestionAdd: () => void;
}

const QuizChips: React.FC<QuizChipsProps> = ({ activeQuestion, quizTypes, handleQuizClick, handleDelete, handleQuestionAdd }) => {
   
  const [showTooltip, setShowTooltip ] = useState<boolean>(true);
  const getName = (type: string) => {
    switch (type) {
      case "right_options":
        return 'Multiple Choice';
      case 'true_false':
        return 'True Or False';
      case "fill_blank":
        return "Fill In The Blanks";
      case 'matching':
        return 'Match The Word';
      default:
        return '';
    }
  }
  const renderChip = (type: string, index: number, secondRow: boolean = false) => (
    <Chip
      key={secondRow ? index + 5 : index}
      label={getName(type)}
      variant="outlined"
      className='quiz-chip'
      onClick={() => handleQuizClick(secondRow ? index + 5 : index)}
      onDelete={quizTypes.length > 1 ?  () => handleDelete(secondRow ? index + 5 : index) : undefined}
      color='primary'
      deleteIcon={quizTypes.length > 1 ?  <CloseIcon /> : undefined}
      style={{
        padding: "19px 7px",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        fontFamily: 'Larsseit',
        lineHeight: "normal",
        borderRadius: "40px",
        backgroundColor: activeQuestion === (secondRow ? index + 5 : index) ? "#2C74B3" : '#FCFCFC',
        color: activeQuestion === (secondRow ? index + 5 : index) ? "#FCFCFC" : '#00539C',
        border: 'none',
        boxShadow: "0px 0px 0px 0px rgba(176, 176, 176, 0.10), 0px 5px 12px 0px rgba(176, 176, 176, 0.10), 0px 22px 22px 0px rgba(176, 176, 176, 0.09), 0px 49px 29px 0px rgba(176, 176, 176, 0.05), 0px 87px 35px 0px rgba(176, 176, 176, 0.01), 0px 136px 38px 0px rgba(176, 176, 176, 0.00)"
      }}
    />
  );

  
    return (
        <div className="quiz-chips">
             <div className={quizTypes.length < 6 ? "quiz-chips__stack" : "quiz-chips__stack-double"}>
              {
                quizTypes.length < 6 ?
                  quizTypes.map((type, index) => renderChip(type, index))
                  :
                  <>
                    <div className="quiz-chips__stack-double__row">
                      {
                      quizTypes.slice(0, 5).map((type, index) => renderChip(type, index))
                      }
                    </div>
                    <div className="quiz-chips__stack-double__row">
                      {
                        quizTypes.slice(5).map((type, index) => renderChip(type, index, true))
                      }
                    </div>                
                  </>
              }
            </div>
            {
              quizTypes.length < 10
                &&
                <>
                  <AddQuestionIcon
                    style={{width: "48px", height: "48px"}}
                    onClick={handleQuestionAdd}
                  />
                { showTooltip && <QuizEditTooltip onClose={() => setShowTooltip(false)}/>}
                </>
            }  
        </div>
    );
};

export default QuizChips;

 