import React from 'react'; 
import { ReactComponent as SelectedRadio } from '../../../../../../images/bigRadioSelected.svg';
import { ReactComponent as UnselectedRadio } from '../../../../../../images/bigRadioUnselected.svg';
import './Steps.scss';

interface StepTwoProps {
  question?: string;
  answer: boolean | null;
  setAnswer: (answer: boolean | null) => void;
}

const StepTwo = ({
  question,
  answer,
  setAnswer 
}: StepTwoProps) => {
  const options = [true, false];

  const handleOptionClick = (option: boolean) => {
    if (answer === option) {
      // If the clicked option is already selected, set answer to null
      setAnswer(null);
    } else {
      // Otherwise, set the answer to the clicked option
      setAnswer(option);
    }
  };

  return (
    <div className="Step">
      <div className="Step__container">
        <div className="Step__container__header">
          <div className="Step__container__header__question">
            {question}
          </div>
          <div className="Step__container__header__instruction">
            Please choose true or false
          </div>
        </div>
        {options.map((option, index) => (
          <div 
            key={index}
            className={`Step__container__body__two ${answer === option ? 'selected' : ''}`} 
            onClick={() => handleOptionClick(option)}
          >
            <div className="Step__container__body__two__option">
              <div className="Step__container__body__two__option__letter">
                {answer === option ? <SelectedRadio /> : <UnselectedRadio />}
              </div>
              <div className="Step__container__body__two__option__text">
                {option ? 'True' : 'False'}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepTwo;
