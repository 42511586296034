import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../../../../utils/CookieHelper';
import { CurrentPageType } from '../../../../../redux/slices/coursesSlice';
import { RootState } from '../../../../../redux/store';
import {  removeCourseById, clearData, saveCourses, removeEducation } from '../../../../../redux/slices/coursesSlice';
import CourseLoader from '../../../../Loader/CourseLoader/CourseLoader';
import { setCurrentPage } from '../../../../../redux/slices/coursesSlice'; 
import { ReactComponent as Logo} from '../../../../../images/courses-logo.svg'; 
import { useModules } from '../../../../../hooks/useModules';
import WhiteButton from '../../../../Buttons/WhiteButton/WhiteButton';
import SaveButton from '../../../../Buttons/SaveButton/SaveButton';  
import { ReactComponent as ShareStar } from '../../../../../images/shared-start.svg';
import CloseModal from '../../../../Modals/CloseModal/CloseModal';
import './ModulesView.scss';
import SuccessSaveModal from '../../../../Modals/SuccessSaveModal/SuccessSaveModal';
import CoursesAlert from '../../../../Modals/CoursesAlert/CoursesAlert'; 

export default function ModulesView() {

  const dispatch = useDispatch<any>();
  const token = getCookie('jwtToken');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const { courseAnswers, activeCourse, loadingPage } = useSelector((state: RootState) => state.courses); 
  const [showMenuItemsEnabled, setShowMenuItemsEnabled] = useState<boolean>(activeCourse?.menu?.some(item => (item.hidden && item.page !== 'module-ai-simulation')) || false);
  const { showMenuItems } = useModules();
 
  useEffect(() => { 
    const hasHiddenItems = 
      activeCourse?.menu?.some(item => (item.hidden && item.page !== 'module-ai-simulation')) || false;
      
    setShowMenuItemsEnabled(hasHiddenItems);
  }, [activeCourse?.menu]);

  const onSave = async   () => {
    setModalOpen(false)
    setSuccessModal(true);
    await dispatch(saveCourses(token));
    dispatch(clearData());
    
    setTimeout(() => {
      setSuccessModal(false);
      dispatch(setCurrentPage('module-general'));
    }, 1000);
    
  }

  const onCancel = async () => {
    if(!activeCourse?.alreadySaved)
      dispatch(removeCourseById({token, id: activeCourse?.id,}));
      if(!activeCourse?.id){
        dispatch(removeEducation(token));
      }
      dispatch(clearData());
      dispatch(setCurrentPage('module-general'));;
  } 


  return (
    <>
      {loadingPage ? (
        <div className="ModulesView">
          <div className="ModulesView__container-loader">
            <CourseLoader />
          </div>
        </div>
        ) : (
          <div className="ModulesView">
              {
              successModal && (
                <SuccessSaveModal  />
              )
            } 
            {
              modalOpen && (
                <CloseModal
                  type={'save'}
                  action={onSave}
                  text={'Would you like to save your progress?'}
                  closeNoSave={onCancel}
                  close={()=> setModalOpen(false)}
                  />
              )
            } 
            {
              alertModal &&
              (
                <CoursesAlert
                  page='view'
                  close={() =>   setAlertModal(false)  }
                />
              )
            }
            <div className="ModulesView__container">

              <div className="ModulesView__container__left">

                <div className="ModulesView__container__left__menu">
                  {activeCourse?.menu?.map((item, index) => (
                    !item.hidden &&
                    <div 
                      key={index}
                      className={
                        item.disabled ?
                        `ModulesView__container__left__menu__item--disabled` :
                        `ModulesView__container__left__menu__item`
                      }
                      onClick={async () =>  {
                        if (!item.disabled) {
                          if(item.page !== 'module-report'){
                            dispatch(setCurrentPage(item.page as CurrentPageType ));
                          } else{
                            
                            if(courseAnswers.quiz && courseAnswers.evaluate){
                              if(!activeCourse?.id){
                                await dispatch(saveCourses(token));
                              }
                              dispatch(setCurrentPage('module-report'));
                            } else{
                              setAlertModal(true);
                            }

                          }
                          
                        }
                      }}
                    >
                    
                      <div 
                          className={
                            item.disabled ?
                            `ModulesView__container__left__menu__item--disabled__name` :
                            `ModulesView__container__left__menu__item__name`
                          }
                        >
                        {item.name}
                      </div>
                    </div>
                  ))}
                </div>

              </div>

              <div className="ModulesView__container__right">

                <div className="ModulesView__container__right__header">
                  <div className="ModulesView__container__right__header__cont">
                    <div className="ModulesView__container__right__header__close">
                    {(activeCourse?.menu?.length  && showMenuItemsEnabled) && <WhiteButton
                        text="Show hidden tabs"
                        className={`WhiteButton--p15-24 ${showMenuItemsEnabled ? "" : "WhiteButton__disabled" }`}
                        action={() => {
                          showMenuItems()
                        }}
                        />}

                      <SaveButton
                        text="Close"
                        action={() => {
                        setModalOpen(true)}
                        }
                        />
                  </div>
                  </div>
                </div>

                <div className="ModulesView__container__right__body">
                <div className="ModulesView__container__right__body__header-logo">
                  <Logo />
                  </div>
                  <div className="ModulesView__container__right__body__header">
                  Landdai has created a custom, interactive eLearning course
                  </div>
                  <div className="ModulesView__container__right__body__text">

                    <div className="ModulesView__container__right__body__text__block">
                      <div className="ModulesView__container__right__body__text__block__number">
                      1.
                      </div>
                      <div className="ModulesView__container__right__body__text__block__text-block">
                        <span className="ModulesView__container__right__body__text__block__text-block__span">
                        Option to take the course yourself OR edit its content:
                        </span>
                        Click the “Edit” button in the top right hand corner of each section page.
                      </div>
                    </div>

                    <div className="ModulesView__container__right__body__text__block">
                      <div className="ModulesView__container__right__body__text__block__number">
                      2.
                      </div>
                      <div className="ModulesView__container__right__body__text__block__text-block">
                        <span className="ModulesView__container__right__body__text__block__text-block__span">
                        Course begins with an evaluation to benchmark current knowledge:
                        </span>
                        Landdai guides through essential terms and educational content and
                         utilizes flashcards and open-ended questions to enhance understanding.
                      </div>
                    </div>
{/*                      
                    <div className="ModulesView__container__right__body__text__block">
                      <div className="ModulesView__container__right__body__text__block__number">
                      3.
                      </div>
                      <div className="ModulesView__container__right__body__text__block__text-block">
                        <span className="ModulesView__container__right__body__text__block__text-block__span">
                        Opportunity to practice learning through AI voice-based interaction:
                        </span>
                        Role-playing simulation provided for practice.
                      </div>
                    </div> */}

                    <div className="ModulesView__container__right__body__text__block">
                      <div className="ModulesView__container__right__body__text__block__number">
                      3.
                      </div>
                      <div className="ModulesView__container__right__body__text__block__text-block">
                        <span className="ModulesView__container__right__body__text__block__text-block__span">
                        Invitation to take a quiz: 
                        </span>
                        View growth and progress in the assessment report afterward.
                      </div>
                    </div>
 
                    
                  </div>
                  <div className="ModulesView__container__right__body__text__footer">
                  Begin by clicking on the evaluation tab.  { <ShareStar />}
                    </div>
  
                </div>

              </div>

            </div>

          </div>
          )
        }
    </>
  )
}
