import { useEffect } from 'react'
import { getCredits } from '../../api/scriptsApi';
import { useSearchParams } from 'react-router-dom';
import bigLogo from '../../images/LanndaiBigLogo.png';
import { getCookie } from '../../utils/CookieHelper';
import './WallMob.scss';

export default function WallMob() {

  const [searchParams] = useSearchParams() || '';
  const sessionId = searchParams.get('session_id') || '';
  const token = getCookie('jwtToken');

  useEffect(() => {
    const retrieveCredits = async (id: string, tok: string) => { 
      await getCredits(id, tok);
    };
    (sessionId && token) && retrieveCredits(sessionId, token);
  }, [sessionId, token]); 

  return (
    <div className={`WallMob`}>
        <img src={bigLogo} alt="logo" />
        <h1>Thank you!</h1>
        <p>Your account has been created.<br/>Please switch to your desktop to access<br/>the full functionality of LandDai.</p>
    </div>
  )
}
