import axios from 'axios';

const API = `${process.env.REACT_APP_API_URL}api`;

export const createScript = (body: any, token: string) => axios.post(`${API}/open-ai/generate-text-for-doc`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getScript = (id: string, token: string) => axios.get(`${API}/open-ai/get-doc?doc_id=${id}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const regenScript = (body: any, token: string) => axios.post(`${API}/open-ai/regenerate-text-for-doc`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const resaveScript = (body: any, token: string) => axios.patch(`${API}/doc/update-doc`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getScriptsList = (body: any, token: string) => axios.get(`${API}/doc/get-docs?take=${body.take || '10'}&skip=${body.skip || '10'}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const deleteScript = (body: any, token: string) => axios.delete(`${API}/doc/delete-doc?docId=${body.docId}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const sendStripe = (body: any, token: string, device: string) => axios.get(`${API}/stripe/get-checkout-url?priceId=${body?.priceId}&quantity=${body?.quantity}&email=${body?.email}&device=${device}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getCredits = (id: string, token: string) => axios.get(`${API}/stripe/get-session-info?session_id=${id}`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const getUser = (token: string) => axios.get(`${API}/auth/get-user-info`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const decreaseCredit = (token: string) => axios.post(`${API}/user/decrease-credit`,
{},
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);


export const checkCredits = (token: string) => axios.post(`${API}/stripe/check-user-credits`,
{},
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
)

export const getFreePlan = (token: string) => axios.get(`${API}/stripe/activate-trial`,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);